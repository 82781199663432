import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box, CircularProgress, Button, Typography, Grid, TextareaAutosize  } from '@material-ui/core';
// import { useSelector } from 'react-redux';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  UpcomingPayment, LoanSummary, PaymentBreakdown,
  RecentTransaction, DashboardCard, RightCard,
  QuickLinksCard, UnlockBenefitCard, UpcomingPaymentAlert, HippoCard, PLBanner, InsuranceBanner,
  RefinanceCard, TermsConditions, AlliedMortgageCard, GoMortgageCard, TransferInfoAlert, MaturityExtensionAlert, EscrowAnalysisAlert, CoveredBanner, SnackBar,
  PaidInFullPopup, PurgedDaysPopup, CoveredP2SBanner, FnmaBlindBanner
} from '../../components';
import Popup from "../PopUp";
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import { getDashboardData, getContent, hideThirdPartyPopupOnSinleLoan, resendActivationEmail, resendEmail, releaseAnnouncement,getPartialSurvey,getRefinanceBanner } from '../../actions';
import Cookies from 'js-cookie';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

import { encrypt, decrypt } from '../../helpers/encrypt';
import { maskLoan, getUserType, getAppSource,isAdmin, getPointPrivateLabel, getMortgageLabel, isAdminCheck, getLoanLabel, renderDefaultMsg, getFCLCompletedStatus, getFCLAccesDaysStatus, getChargedOffStatus, getChargedOffAccesDaysStatus, getPayOffStatus, getPayOffAccesDaysStatus, getServiceTransferStatus, getServiceAccessDaysStatus, getAccountLabel, changesReflectStatus, isHEI } from '../../helpers'
import { IncorrectTinSsnAfterLogin } from '../IncorrectTinSsnAfterLogin';
import  {ProfileUpdateAlert}  from '../ProfileUpdateAlert';
import { getThirdPartyData, insertActivityLog, updateMyFeedbackProtected, feedbackDisplay, getClientName, propertyRepairDisplay, clearPropertyRepair } from '../../actions'
import WarningIcon from '@material-ui/icons/Warning';
import { PromiseToPayAlert } from '../PromiseToPayAlert';
import { EscrowAlert } from '../EscrowAlert';
import {PayoffRequestSurvey} from '../PayoffRequest/PayoffRequestSurvey';
import {PayoffRequestSurveyQuestions} from '../PayoffRequest/PayoffRequestSurveyQuestions';
import { SNACKBAR_SUCCESS } from '../../helpers/constants';
import { DeliquentLoansAlert } from '../DeliquentLoansAlert';
import { CommunicationConsentPopup } from '../CommunicationConsentPopup';
import { FemaBlindPopup } from '../FemaBlindPopup';
import { DisasterClaimsPopup } from '../DisasterClaimsPopup';
import { NewThirdPartyRequest } from '../../components';

import { useLocation } from 'react-router-dom';
import maskGroup from '../../assets/Refinance/maskGroup.png';
import payoff from '../../assets/Point/payoff.png';
import vom from '../../assets/Point/vom.png';
import thirdParty from '../../assets/Point/thirdParty.png';
import clsx from "clsx";
import openIcon from '../../assets/openIcon.png';
import './styles.css';
import moment from 'moment';
import PhoneIcon from '@material-ui/icons/Phone';
import chat from '../../assets/chat.png';

export const SingleLoanDashboard = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const ipadMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileMediaQuery = useMediaQuery(theme.breakpoints.down('xs'));
  const privateLabelUrl = Cookies.get("privateLabelUrl")
  const escrowStatus = Cookies.get('escrow');
  const loanInfoData = useSelector(state => state?.loanInfoReducer?.purgeLoanInfo);
  const clientDetails = useSelector(state => state?.loanInfoReducer.bankDetail);
  const lendingContact = useSelector(state => state.theme);
  const [data, setData] = useState(null);
  const [lstThirdParty, setLstThirdParty] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showmonthlyincome, setshowmonthlyincome] = useState(false);
  const [loading, setLoading] = useState(false)
  const [banners, setBanners] = useState([]);
  const [releaseAnnouncements, setReleaseAnnouncements] = useState([])
  const [showAnnouncement, setShowAnnouncement] = useState(true)
  const selectedAccountData = useSelector(state => state.selectedAccountData);
  const thirdPartyPopupState = useSelector(state => state.thirdPartyPopupState);
  const status = useSelector(state => state.announcementReducer);
  const [transferAlertStatus, setTransferAlertStatus] = useState(false);
  const [paidInFull, setPaidInFull] = useState((loanInfoData && (loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'PayOff')) ? true : false);
  const [serviceReleased, setServiceReleased] = useState((loanInfoData && (loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'ServiceTransfer')) ? true : false);
  const [promisePopupStatus, setPromisePopup] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(Cookies.get('redirectUrl'));
  const [accessStatus, setAccessStatus] = useState(location.search.includes('?access=false'))
  const [displayQuoteBanner, setDisplayQuoteBanner] = useState([]);
  const [setUpAutoPay, setDisplaySetupAutoPay] = useState(false);
  const [maturityExtensionsInfo,setMaturityExtensionsInfo] = useState({})
  const [requestSurvey, setRequestSurvey] = useState(true);
  const [surveyStatus, setSurveyStatus] = useState(true);
  const [popupTexts, setPopupTexts] = useState({heading: 'Paid Off Survey - Mortgage Awareness and Feedback', title: 'In our quest to continually improve and better serve our customers, we would appreciate your insights by taking 2 minutes to answer a few questions for us.', show: false})
  const [requestSurveyQuestions,setRequestSurveyQuestions] = useState(false);
  const [partiallySubmitedData,setPartiallySubmitedData] = useState();
  const [eaStatus, setEAStatus] = useState(false);
  const [refinanceBanner, setRefinanceBanner] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMsg, setErrorMessage] = useState('');
  const [accountAccessInfo, setAccountAccessInfo] = useState(false);
  const [liquidationType, setLiquidationType] = useState();
  const [purgedDaysInfo, setPurgedDaysInfo] = useState(false);
  const [deliquentLoanPopupStatus, setDeliquentLoanPopupStatus] = useState(true);
  const [documentDesc, setDocumentDesc] = useState("");
  const [rating, setRating] = useState();
  const [ratings, setRatings] = useState([{text: 0, label: 'Extremely Unlikely'}, {text: 1, label: ''}, {text: 2, label: ''}, {text: 3, label: ''}, {text: 4, label: ''}, {text: 5, label: ''}, {text: 6, label: ''}, {text: 7, label: ''}, {text: 8, label: ''}, {text: 9, label: ''}, {text: 10, label: 'Extremely Likely'}])
  const [displaySurvey, setDisplaySurvey] = useState(false);
  const [surveyText, setSurveyText] = useState('');
  const [lendingSurvey, setLendingSurvey] = useState(false);
  const [displayThanku, setDisplayThanku] = useState(false);
  const [displayCommunicationConsentPopup, setDisplayCommunicationConsentPopup] = useState(false);
  const [fnmaBlindPopupStatus, setDisplayFnmaPopup] = useState(false);
  const [fnmaPopupLoanStatus, setFnmaPopupLoanStatus] = useState(false);
  const [fnmaBlindData, setFnmaBlindData] = useState([]);
  const [displayPropertyRepair, setDisplayPropertyRepair] = useState(false);
  const [disasterInfo, setDisasterInfo] = useState([]);
  const [remindMePropertyRepair, setRemindMePropertRepair] = useState(false);

  const handleRequestDescChange = (event) => {
    setDocumentDesc(event.target.value)
  }
  
  const getTextColor = (index) => {
    if ((rating < 7) && (rating >= index)) {
      return '#FFFFFF'
    } else if ((rating < 9) && (rating >= index)) {
     return '#FFFFFF'
    } else if ((rating < 11) && (rating >= index)) {
      return '#FFFFFF'
    }
  }

  const getBgColor = (index) => {
    if ((rating < 7) && (rating >= index)) {
      return '#E02020'
    } else if ((rating < 9) && (rating >= index)) {
     return '#F7B15C'
    } else if ((rating < 11) && (rating >= index)) {
      return '#198000'
    }
  }

  const getBorderColor = (index) => {
    if ((rating != undefined) && (index <= rating)) {
        return '1px solid #FFFFFF'
     } else if (index < 7) {
       return '1px solid #E02020'
     } else if (index < 9) {
       return '1px solid #F7B15C'
     } else {
       return '1px solid #198000'
     }
   };

  const handleLendingSurvey = async () => {
    setDisplaySurvey(false)
    setLendingSurvey(false)
    const data = {
      "loanNumber": Cookies.get("selectedAccount"),
      "userName": "",
      "feedbackRating": rating,
      "feedbackComment": documentDesc,
      "feedbackType": "LandingFeedback"
    }
    const result = await updateMyFeedbackProtected(data);
    setDisplayThanku(true)
    getClientShortName()
  }

  const sendActivityLog = async (type) => {
    const data = {
      "accountNumber": Cookies.get("selectedAccount"),
      "activityCode": "UAL206",
      "activityStatus": "Success",
      "action": "",
      "controller": "Feedback",
      "apiName": "Feedback",
      "activityRemark": type == 'skip' ? "MLW_Lending Survey-Borrower clicked on cross button to skip on home page" : type == 'know' ? "MLW_Lending Survey-Borrower clicked on “Know more” hyperlink in thank you popup" : "MLW_Lending Survey-Borrower clicked on call option on thank you popup",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }
  
  const fetchFeedbackDisplay = async () => {
    const result = await feedbackDisplay(Cookies.get("selectedAccount"), "LandingFeedback");
    if (result) {
      if (result?.responseData?.lstResponseStatus[0].statusCode == "0") {
        setSurveyText(result?.responseData?.responseData?.headerText)
        setDisplaySurvey(true)
      }
    }
  }

  const handleMortgageFeedback = (skip) => {
    setLendingSurvey(!lendingSurvey)
    if (skip && !isAdminCheck()) {
      sendActivityLog('skip')
    }
  }

  const getDisasterClaimStatus = async () => {
    const result = await dispatch(propertyRepairDisplay(props.accountNumber));
    if (result) {
      if (result?.responseData?.lstResponseStatus[0].statusCode == "0") {
        const status = result?.responseData?.responseData.filter((disaster) => disaster.isPopupDisplay == true)
        setDisplayPropertyRepair(status.length > 0 ? true : false)
        setDisasterInfo(result?.responseData?.responseData)
      }
    }
  }
 
  useEffect(() => {
    if ((loanInfoData !== null) && (loanInfoData !== 'null')) {
     if (loanInfoData && (loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'PayOff')) {
       setPaidInFull(true)
     } else {
      setPaidInFull(false)
     }
    }
  }, [loanInfoData]);
  
  useEffect(() => {
    if ((loanInfoData !== null) && (loanInfoData !== 'null')) {
     if (loanInfoData && (loanInfoData?.liquidationStatus) && (loanInfoData?.liquidationType == 'ServiceTransfer')) {
      setServiceReleased(true)
     } else {
      setServiceReleased(false)
     }
    }
  }, [loanInfoData]);

  useEffect(() => {
    getData(props.accountNumber);
    getSubOrdinateStatus()
    dispatch(getContent('PC011'));
  }, [props.accountNumber]);

  const handleRoute = (url) => {
    history.push({pathname: '/' + url});
  }
  //Request paid off functions
  const resumeSurvey=()=>{
    setRequestSurvey(true);
  }

  const handleSubmitSurvey=()=>{
    setRequestSurveyQuestions(true);
    setRequestSurvey(false);
  }

  const handleSubmitClose=()=>{
    setRequestSurvey(false);
  }
  const getPartiallySubmittedSurvey=async()=>{
    if (!isAdminCheck()) {
    const requestData = {
      "accountNumber": props.accountNumber ? props.accountNumber : '',
      "SurveyType": "PaidOff",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    const result = await getPartialSurvey(requestData);

    if (result) {
      const { lstResponseStatus, responseData } = result?.responseData;
      if (result && (lstResponseStatus[0]?.statusCode === "0" || lstResponseStatus[0]?.statusCode === 0)) {
        setPartiallySubmitedData(responseData);
        setRequestSurveyQuestions(true);
        
      } else {
        setErrorMessage(lstResponseStatus[0]?.statusDesc)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
  }
  }

  const handleCloseSurvey=(flag)=>{
    if(flag)
    setRequestSurveyQuestions(false);
    setSurveyStatus(flag);
  }

  //END Request paid off

  const getReleaseAnnouncements = async (escrow) => {
    const response = await dispatch(releaseAnnouncement())
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode === '0') { 
    var announcementData = response?.responseData?.responseData
    var index = announcementData.findIndex((item) => item.pageURL == "EscrowManage")
      if (index !== -1) {
        if (escrow) {
          setReleaseAnnouncements(announcementData)
        } else {
        announcementData.splice(index,1)
        setReleaseAnnouncements(announcementData)
        }
      } else {
        setReleaseAnnouncements(announcementData)
      }

     if (response?.responseData?.responseData?.length > 0) {
       Cookies.set('announcement', true)
       if (index !== -1) {
        if (escrow) {
          Cookies.set('releaseIds', JSON.stringify(response?.responseData?.responseData))
        } else {
          var releaseAnnouncementLists = response?.responseData?.responseData
          releaseAnnouncementLists.splice(index,1)
          Cookies.set('releaseIds', JSON.stringify(releaseAnnouncementLists))
        }
      } else {
        Cookies.set('releaseIds', JSON.stringify(response?.responseData?.responseData))
      }
     } else {
      Cookies.set('releaseIds', [])
      Cookies.set('announcement', false)
     }
    }
  }

  const getSubOrdinateStatus = async () => {
    const data = {
      "accountNumber": Cookies.get("selectedAccount"),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    const response = await getThirdPartyData(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
     
      Cookies.set('subOrdinate', response.isSubordinate);
    }
  }

  const hideMonthlyincome = async () => {
    setshowmonthlyincome(true)
  }

  const routePage = async (link) => {
    await hideThirdPartyPopUp();
    history.push(`/${link}`);
  }

  const hideThirdPartyPopUp = async () => {
    await dispatch(hideThirdPartyPopupOnSinleLoan());
    setShowPopup(false);
  }
  
  const handleNavigation = (link) => {
    history.push(`/${link}`);
  }

  const handePopupClose = (status) => {
    getData(props.accountNumber)
    if (status) {
      setOpenSnackBar(true)
    }
  }

  const toggleSnackBar = () => {
    setOpenSnackBar(!openSnackBar)
  }

  const getData = async (accountNumber) => {
    const allAccountsDetail = await Cookies.get("loanAccounts");
    let allAccounts = JSON.parse(allAccountsDetail);

    const filterAcc = allAccounts.filter((item) => item.accountNumber == decrypt(accountNumber));
    setEAStatus(filterAcc[0]?.eaPopup1Status)
    setFnmaPopupLoanStatus(filterAcc[0]?.fnmaBlindPopup)

    setRemindMePropertRepair(filterAcc[0]?.propertyRepairDisplayStatus)
    setLoading(true)
    history.push(`/Dashboard?src=${accountNumber}`);

    const data = {
      "accountNumber": accountNumber,
      "propertyState": selectedAccountData && selectedAccountData.propertyState ? selectedAccountData.propertyState : '', //new Added
      "investorCode": selectedAccountData && selectedAccountData.bsi_InvestorCode ? selectedAccountData.bsi_InvestorCode : '', //new Added
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    };

    const res = await dispatch(getDashboardData(data));
    if (res) {
      setData(res);
      setLoading(false)
      setBanners(res.bannerInfo)
      setPromisePopup(res?.isPromisePayPopUp)
      setSurveyStatus(res?.paidOffSurveyInfo?.isLink)
      setRequestSurvey(isAdminCheck() ? false :  res?.paidOffSurveyInfo?.isPopUp)
      if(Cookies.get('isMaturityExtenstionOpened')==='true'){
        setMaturityExtensionsInfo(res?.maturityExtensionsInfo);
      }
      //Remove admin check to display maturityExtensionMenu on header
      Cookies.set('maturityExtensionMenu',res?.maturityExtensionsInfo?.isMenu)
      if (res?.lstThirdPartyUnAuthorizedReqPopup.length !== 0) {
        setLstThirdParty(res?.lstThirdPartyUnAuthorizedReqPopup);
        setShowPopup(true);
      }
    
      Cookies.set("escrow", res?.displayEscrowMenu);
      localStorage.setItem('emailVer', res.isEmailVerified)
      localStorage.setItem('contactVer', res.isMobileVerified)
      Cookies.set("displayNaturalDisasterLink", res?.displayDisasterAssistance);
      Cookies.set("isAgencyLoan", res?.isAgencyLoan);
      getReleaseAnnouncements(res?.displayEscrowMenu)
      Cookies.set("isRefiStateEligible", res?.refiEligibleStateFlag);
      // Cookies.set("paidInFullFlag", res?.paidInFullFlag);
      // Cookies.set("serviceReleasedFlag", res?.serviceReleasedFlag);
      // setPaidInFull( res?.paidInFullFlag)
      // setServiceReleased( res?.serviceReleasedFlag)
      Cookies.set("promisePlan", res?.isPromisePayMenu)
      // setDisplayCommunicationConsentPopup(res?.isFCCPopup)

      if ((res.bannerInfo?.length > 0) && (res.bannerInfo !== null) && (res.bannerInfo !== 'null')) {
        props.handleCoveredInfo(res?.bannerInfo);
        const displayStatus = res.bannerInfo.filter((banner) => ((banner.bannerName == 'Refinance') && (banner.isDisplay == true)))
        setDisplayQuoteBanner(displayStatus)
        Cookies.set("refinanceFlag", displayStatus?.length > 0 ? true : false);
        const displaySetUpAutoPay = res.bannerInfo.filter((banner) => ((banner.bannerName?.toUpperCase() == 'AUTOPAY') && (banner.isDisplay == true)))
        setDisplaySetupAutoPay(displaySetUpAutoPay)
        if (displayStatus?.length > 0) {
          getRefinanceBanners()
        }
        const displayFnmaPopup = res.bannerInfo.filter((banner) => ((banner.bannerName?.toUpperCase() == 'FNMABLIND') && (banner.isDisplay == true)))
        setDisplayFnmaPopup(displayFnmaPopup?.length > 0 ? true : false)
        setFnmaBlindData(displayFnmaPopup)
      } else {
        Cookies.set("refinanceFlag", false);
      }
      getClientShortName()
    }
  }

  const getRefinanceBanners = async () => {
    const res = await getRefinanceBanner();
    setRefinanceBanner(res.responseData.responseData)    
  }

  const renderBanners = () => {
    if (banners?.length > 0) {
      const bannersArr = banners.sort((a, b) => a.dispayOrder - b.dispayOrder);

      return bannersArr?.map((banner) => {
        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'GOGREEN') {
          return (
            <DashboardCard
              key={banner.dispayOrder}
              banner={banner}
              accountNumber={props.accountNumber}
              email={emailID}
              phone={mobileNumber}
              isCommConcent={isCommConcent}
              isCommConcentMobile={isCommConcentMobile}
              isMobileVerified={isMobileVerified}
              isEmailVerified={isEmailVerified}
              isGoGreen={isGoGreen}
              IsSmsConsent={isSmsConsent}
              handePopupClose={handePopupClose}
            />
          )
        }
        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'AUTOPAY') {
          return (
            <RightCard
              key={banner.dispayOrder}
              banner={banner}
              accountNumber={props.accountNumber}
              email={emailID}
              phone={mobileNumber}
            />
          )
        }
        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'HIPPO') {
          return (
            <HippoCard
              key={banner.dispayOrder}
              banner={banner}
              accountNumber={props.accountNumber}
              email={emailID}
              phone={mobileNumber}
            />
          )
        }
        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'REFINANCE-ALLIDEM') {
          return (
            <AlliedMortgageCard
            key={banner.dispayOrder}
            banner={banner}
            accountNumber={props.accountNumber}
            email={emailID}
            phone={mobileNumber}
            />
          )
        }
        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'REFINANCE-GOODM') {
          return (
            <GoMortgageCard
            key={banner.dispayOrder}
            banner={banner}
            accountNumber={props.accountNumber}
            email={emailID}
            phone={mobileNumber}
            />
          )
        }
        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'REFINANCE') {
          return (
            <RefinanceCard
              key={banner.dispayOrder}
              banner={banner}
              accountNumber={props.accountNumber}
              email={emailID}
              phone={mobileNumber}
            />
          )
        }
        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'REFINANCE-GOODM') {
          return (
            <RefinanceCard
              key={banner.dispayOrder}
              banner={banner}
              accountNumber={props.accountNumber}
              email={emailID}
              phone={mobileNumber}
            />
          )
        }
        //refactor 
        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'COVID19') {
          return (
            <RightCard
              key={banner.dispayOrder}
              banner={banner}
              accountNumber={props.accountNumber}
              email={emailID}
              phone={mobileNumber}
            />
          )
        }



        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'PLBANNER') {
          return (
            <PLBanner
              key={banner.dispayOrder}
              banner={banner}
              accountNumber={props.accountNumber}
              email={emailID}
              phone={mobileNumber}
            />
          )
        }

        if (banner.isDisplay && banner.bannerName.toUpperCase() === 'INSURANCE') {
          return (
            <InsuranceBanner
              key={banner.dispayOrder}
              banner={banner}
            />
          )
        }

        if (banner.isDisplay && ((banner.bannerName.toUpperCase() === 'COVERED') || (banner.bannerName.toUpperCase() === 'MATIC'))) {
          return (
            <CoveredBanner
              key={banner.dispayOrder}
              banner={banner}
            />
          )
        }

        if (banner.isDisplay && (banner.bannerName.toUpperCase() === 'COVERED-P2S')) {
          return (
            <CoveredP2SBanner
              key={banner.dispayOrder}
              banner={banner}
            />
          )
        }

        if (banner.isDisplay && (banner.bannerName === 'FNMABlind')) {
          return (
            <FnmaBlindBanner
              banner={banner}
            />
          )
        }

      })
    }
    return null
  }

 const closePopup = () => {
   setLiquidationType()
   setTransferAlertStatus(false)
 }

  const closeEscrowPopup = () => {
    setAccessStatus(false)
    history.push('/Dashboard')
    Cookies.remove('redirectUrl')
    setRedirectUrl('')
  }
 
  const sendActivityData = async (refiCompany, button) => {
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "activityCode": "UAL202",
      "activityStatus": "Success",
      "action": refiCompany,
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": `User clicked on '${button?.bannercode}' banner button on MLW`,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }
  
  const route = (refiCompany, button ) => {
    sendActivityData(refiCompany, button)
    var a = document.createElement('a');
    a.target="_blank"
    a.href = button.buttonUrl;
    a.click()
  }

  const handlePaidInFull = (type) => {
    setLiquidationType(type)
    setAccountAccessInfo(true)
  }
 
  const closePaidInFullPopup  = () => {
    setLiquidationType()
    setAccountAccessInfo(false)
  }

  const handleChargedOffPurgedDays = (type) => {
    setLiquidationType(type)
    setPurgedDaysInfo(true)
  }

  const closePurgedDaysPopup  = () => {
    setLiquidationType()
    setPurgedDaysInfo(false)
  }

  const handleServiceTransfer = (type) => {
    setLiquidationType(type)
    setTransferAlertStatus(true)
  }
  
  const closeDeliquentLoanPopup = () => {
    setDeliquentLoanPopupStatus(false)
  }

  const handleKnowMore = () => {
    sendActivityLog('know')
    const url = 'https://bsifinancialrefinanceonline.mymortgage-online.com/';
    window.open(url, '_blank')
  }

  const handleThankuClose = () => {
    setDisplayThanku(false);
    setDisplaySurvey(false);
    setLendingSurvey(false);
  }

  const getClientShortName = async () => {

    const data = {
      "accountNumber" : Cookies.get("selectedAccount"),
      "userName": Cookies.get('userName'),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data));
    if (result) {
      const { lstResponseStatus, isLandingFeedbackDisplay, bankDetail } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        setDisplaySurvey(isLandingFeedbackDisplay);
        if (isLandingFeedbackDisplay) {
          fetchFeedbackDisplay()
        }
        if ((bankDetail?.clientShortName?.toLowerCase() == 'visio') || (bankDetail?.clientShortName?.toLowerCase() == 'rco')) {
            getDisasterClaimStatus()
          } else {
            dispatch(clearPropertyRepair())
          }
         }
      }
  }

  if (!data) {
    return <Box className={classes.noDataRoot}><CircularProgress /></Box>
  }
  
  const { isPaymentAllowed, isDueDateApproaching, totalAmountDue, paymentDueDate, lastPaymentDate, accountNumber,
    principalLoan, interestRate, escrowBalance, origionalLoanAmount, origionationDate, isRefinance,
    noteRate, paymentCycle, armCurrentRate, armNextPaymentChangeDate, armNextRateChangeDate,
    piEscrow, piInterest, piPrincipal, piTotalMonthlyPayment, monthlyAmountDue, emailID, mobileNumber, isCommConcent, isCommConcentMobile,
    isMobileVerified, isEmailVerified, isAlreadyScheduleACHPayment, isGoGreen, bankDetails, isDelinquentDays, daylinquestdays, isArm, paidInFullFlag
    , serviceReleasedFlag, newServicerName, refinanceURL, isSmsConsent, refiCompany, newServicerPhone, displayPaymentCycle, isPaymentAllowedOnDashboard, buydown,
    isDLQLMAPopup
  } = data;
  
  const getLoanSummaryStatus = () => {
     if (!isPaymentAllowedOnDashboard) {
      return false
     } else {
      return true
     }
  }

  const handleProceed = () => {
    history.push('/UserProfile/CommunicationPreferences')
  }

  const handleRemindMe  = () => {
    setDisplayCommunicationConsentPopup(false)
  }
    
  const handlePropertyRepairPopupSubmit = () => {
    history.push('/DisasterClaims')
  }

  if (loading) {
    return (
      <Box className={classes.loadingRoot}>
        <CircularProgress />
      </Box>
    )
  } 

  return (
    <>
    <Box container="true" className={classes.root}>
      { !getPointPrivateLabel() ?
        <h1 className={classes.title}>Loan Dashboard</h1> : 
        <h1 className={classes.title}>Contract Dashboard</h1>
      }

      { (loanInfoData && !changesReflectStatus(loanInfoData)) && <Box className={classes.infoBox}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
          {status && releaseAnnouncements?.length > 0 && releaseAnnouncements?.map((key) => (
            <Box display='flex' alignItems="center" style={{ marginBottom: '0.5rem' }}>
                <InfoIcon className={classes.infoIcon} />
                 <div  className={classes.infoText} dangerouslySetInnerHTML={{ __html: key.releaseMessage }} />
              </Box>
           ))}
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.infoIcon} />
              <Typography className={classes.infoText}>Changes made to your profile will reflect in 24 hours.</Typography>
            </Box>
            {(!data.isEmailVerified || !data.isMobileVerified) && <Box display='flex' alignItems="center" style={{ marginBottom: '0.5rem' }}>
              <InfoIcon style={{ fill: '#c90d0d' }} className={classes.infoIcon} />
              <Typography className={classes.infoText}>Please verify your {!data?.isEmailVerified && 'email'}{(!data.isEmailVerified && !data.isMobileVerified) && ' and'}{!data.isMobileVerified && ' mobile number'} by <a style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => history.push('/UserProfile/Profile')}>clicking here</a>!</Typography>
            </Box>}
          </Grid>
        </Grid>
      </Box> }

     {/* ChargedOff Ribbon */}
      { getChargedOffStatus(loanInfoData) && 
      <Box className={classes.transferBox}>
        <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
          <Grid item md={12} xs={12} lg={12}>
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.paidInFullIcon} />
              <Typography className={classes.informationText}>This {getMortgageLabel()?.toLowerCase()} {getLoanLabel()?.toLowerCase()} has been charged off. For more information <a className={classes.clickLink} onClick={() => handlePaidInFull('chargedOff')}> Click here</a></Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> }

      
      {  getChargedOffAccesDaysStatus(loanInfoData) &&
      <Box className={classes.transferBox}>
        <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
          <Grid item md={12} xs={12} lg={12}>
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.paidInFullIcon} />
              <Typography className={classes.informationText}><span className={classes.purgedDays}>{loanInfoData.remainingPurgedDays} days</span> left to access this {getAccountLabel()?.toLowerCase()} since it is charged off. For more information <a className={classes.clickLink} onClick={() => handleChargedOffPurgedDays('chargedOff')}> Click here</a></Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> }

     {/* FCLCompleted Ribbon */}

      { getFCLCompletedStatus(loanInfoData) &&
      <Box className={classes.transferBox}>
        <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
          <Grid item md={12} xs={12} lg={12}>
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.paidInFullIcon} />
              <Typography className={classes.informationText}>A foreclosure sale date has been held with respect to this property. For more information<a className={classes.clickLink} onClick={() => handlePaidInFull('foreclosure')}> Click here</a></Typography>
            </Box>
          </Grid>
    
        </Grid>
      </Box> }

      { getFCLAccesDaysStatus(loanInfoData) &&
      <Box className={classes.transferBox}>
        <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
          <Grid item md={12} xs={12} lg={12}>
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.paidInFullIcon} />
              <Typography className={classes.informationText}><span className={classes.purgedDays}>{loanInfoData.remainingPurgedDays} days</span> left to access this {getAccountLabel()?.toLowerCase()} since it is foreclosed. For more information <a className={classes.clickLink} onClick={() => handleChargedOffPurgedDays('foreclosure')}> Click here</a></Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> }
      
      {/* PaidInFull Ribbon */}

     { getPayOffStatus(loanInfoData) &&
      <Box className={classes.transferBox}>
        <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
          <Grid item md={8} xs={12} lg={8}>
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.paidInFullIcon} />
              <Typography className={classes.informationText}>Your {getLoanLabel()?.toLowerCase()} has been paid in full. For more information <a className={classes.clickLink} onClick={() => handlePaidInFull('paidInFull')}> Click here</a></Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={12} lg={4}>
            { surveyStatus &&
              <Typography className={classes.completeUrSurvey}>To complete your paid off survey <a className={isAdminCheck() ? classes.clickLinkDisable : classes.clickLink} onClick={  getPartiallySubmittedSurvey}> Click here</a></Typography>}
          </Grid>
        </Grid>
      </Box> }

      { getPayOffAccesDaysStatus(loanInfoData) &&
      <Box className={classes.transferBox}>
        <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
          <Grid item md={12} xs={12} lg={12}>
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.paidInFullIcon} />
              <Typography className={classes.informationText}><span className={classes.purgedDays}>{loanInfoData.remainingPurgedDays} days</span> left to access this {getAccountLabel()?.toLowerCase()} since it is paid in full. For more information <a className={classes.clickLink} onClick={() => handleChargedOffPurgedDays('paidInFull')}> Click here</a></Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> }

      {/* Service Transfer Ribbon */}

      { getServiceTransferStatus(loanInfoData) &&
          <Box className={classes.transferBox}>
            <Grid container direction="row" alignItems="center" style={{ paddingLeft: "10px" }}>
              <Grid item md={11} xs={11} lg={11}>
                <Box display='flex' alignItems="center" >
                  <InfoIcon className={classes.paidInFullIcon} />
                  <Typography className={classes.informationText}>Your {getLoanLabel()?.toLowerCase()} has been transferred to new servicer <span className={classes.servicerText}>{loanInfoData?.newServicerName}.</span> For more information <a className={classes.clickLink} onClick={() => handleServiceTransfer('serviceTransfer')}>Click here</a> </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
      }

     { getServiceAccessDaysStatus(loanInfoData) &&
      <Box className={classes.transferBox}>
        <Grid container direction="row" alignItems="center" style={{ paddingLeft: '5px' }}>
          <Grid item md={12} xs={12} lg={12}>
            <Box display='flex' alignItems="center">
              <InfoIcon className={classes.paidInFullIcon} />
              <Typography className={classes.informationText}><span className={classes.purgedDays}>{loanInfoData.remainingPurgedDays} days</span> left to access this {getAccountLabel()?.toLowerCase()} since it is service transferred. For more information <a className={classes.clickLink} onClick={() => handleServiceTransfer('purgedDays')}> Click here</a></Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> }

  { !getPointPrivateLabel() && <>
   { (displayQuoteBanner?.length != 0) && refinanceBanner &&
   <>


      { !mediaQuery &&
      <Box className={classes.quoteBox}>
        <Grid container  style={{ height: '64px', textAlign: 'center', alignItems: 'center' }}>
          <Grid item md={3} xs={3} lg={3}>
            <Box style={{display:'content', justifyItems: 'center' }}>
              <Typography className={`${classes.banner3heading} ${'ref'}`} dangerouslySetInnerHTML={{ __html: refinanceBanner?.headerText}} />
            </Box>
          </Grid>
          <Grid item md={5} xs={5} lg={5}>
            <Box>
               <div className={`${classes.banner2paragraph} ${'ref'}`} dangerouslySetInnerHTML={{ __html: refinanceBanner?.contentText }} />
            </Box>
          </Grid>
          <Grid item  md={2} xs={2} lg={2}>
            <Box className={classes.quickQuoteBtn}>
            <Button className={classes.inquireNow} 
                 variant='contained'
                 color='primary'
                 role="link"
                 onClick={() => route(refiCompany, refinanceBanner)}
                       >
                        {refinanceBanner?.buttonText}
                        <img src={openIcon} className={classes.openIcon}/>

                      </Button>
            </Box>
          </Grid>

          <Grid item  md={2} xs={2} lg={2}>
            <Box >
              <img src={refinanceBanner?.imagePath} style={{float: 'right', height: '64px'}}/>
            </Box>
          </Grid>
        </Grid>
      </Box> 
      }
     
     { ipadMediaQuery && !mobileMediaQuery && 
      <Box className={classes.quoteBox}>
        <Grid container direction="row" style={{ height: '140px' }}>
          <Grid item md={9} xs={9} sm={9}>
            <Box style={{display:'content', justifyItems: 'center' }}>
              <Typography className={`${classes.banner2heading} ${'ref'}`} dangerouslySetInnerHTML={{ __html: refinanceBanner?.headerText}} />
            </Box>
            <Box style={{paddingLeft: '30px'}}>
              <div className={`${classes.banner2paragraph} ${'ref'}`} dangerouslySetInnerHTML={{ __html: refinanceBanner?.contentText }} />
            </Box>
            <Box className={classes.quickQuoteBtn}>
              <Button className={classes.inquireNow} 
                  variant='contained'
                  color='primary'
                  role="link"
                  onClick={() => route(refiCompany, refinanceBanner)}>
                         {refinanceBanner?.buttonText}
                        <img src={openIcon} className={classes.openIcon}/>
                </Button>
            </Box>
          </Grid>
          <Grid item md={3} xs={3} sm={3}>
            <Box >
              <Box className={classes.homeImage}><img src={refinanceBanner?.imagePathMobile} style={{height: '140px'}} /></Box>
            </Box>
          </Grid>
        </Grid>
      </Box> 
   }
  
  { ipadMediaQuery && mobileMediaQuery && 
      <Box className={classes.quoteBox}>
        <Grid container direction="row" style={{ height: '108px' }}>
          <Grid item md={8} xs={8} sm={9}>
            <Box >
              <Typography className={`${classes.banner2heading} ${'ref'}`} dangerouslySetInnerHTML={{ __html: refinanceBanner?.headerText}} />
            </Box>
            <Box >
          
            </Box>
            <Box className={classes.quickQuoteBtn}>
              <Button className={classes.inquireNow} 
                  variant='contained'
                  color='primary'
                  role="link"
                  onClick={() => route(refiCompany, refinanceBanner)}>
                        {refinanceBanner?.buttonText}
                        <img src={openIcon} className={classes.openIcon}/>
                </Button>
            </Box>
          </Grid>
          <Grid item md={4} xs={4} sm={3}>
            <Box >
              <Box className={classes.homeImage}><img src={refinanceBanner?.imagePathMobile} style={{height: '108px', width: '127px'}}/></Box>
            </Box>
          </Grid>
        </Grid>
      </Box> 
   }
    </>
   }  

      <Box className={classes.wrapper}>
        <Box className={classes.leftPannel}>

          <UpcomingPayment
            monthlyAmountDue={monthlyAmountDue}
            totalAmountDue={isPaymentAllowed ? totalAmountDue : ""}
            paymentDueDate={isPaymentAllowed ? paymentDueDate : ""}
            lastPaymentDate={isPaymentAllowed ? lastPaymentDate : ""}
            accountNumber={isPaymentAllowed ? accountNumber : ""}
            isPaymentAllowed={isPaymentAllowed}
            isAlreadyScheduleACHPayment={isPaymentAllowed ? isAlreadyScheduleACHPayment : ''}
            bankDetails={isPaymentAllowed ? bankDetails : ''}
            isDueDateApproaching={isPaymentAllowed ? isDueDateApproaching : ''}
            isDelinquentDays={isDelinquentDays}
            daylinquestdays={daylinquestdays}
            piTotalMonthlyPayment={piTotalMonthlyPayment ? piTotalMonthlyPayment : ''}
            paidInFullFlag={paidInFull}
            serviceReleasedFlag={serviceReleased}
            newServicerName={loanInfoData?.newServicerName}
            setUpAutoPay={setUpAutoPay}
            buydown={buydown}
          />
          <LoanSummary
            principalLoan={getLoanSummaryStatus() ? principalLoan : ''}
            interestRate={getLoanSummaryStatus() ? interestRate : ""}
            escrowBalance={getLoanSummaryStatus() ? escrowBalance : ""}
            origionalLoanAmount={getLoanSummaryStatus() ? origionalLoanAmount : ""}
            origionationDate={getLoanSummaryStatus() ? origionationDate : ""}
            noteRate={getLoanSummaryStatus() ? noteRate : ""}
            paymentCycle={getLoanSummaryStatus() ? paymentCycle : ""}
            armCurrentRate={getLoanSummaryStatus() ? armCurrentRate : ""}
            armNextPaymentChangeDate={getLoanSummaryStatus() ? armNextPaymentChangeDate : ""}
            armNextRateChangeDate={getLoanSummaryStatus() ? armNextRateChangeDate : ""}
            accountNumber={accountNumber}
            isPaymentAllowed={isPaymentAllowed}
            isArm={isArm}
            isRefinance={isRefinance}
            refinanceURL={refinanceURL}
            hideMonthlyincome={() => hideMonthlyincome()}
            refiCompany={refiCompany}
            displayPaymentCycle={displayPaymentCycle}
            isPaymentAllowedOnDashboard={isPaymentAllowedOnDashboard}
          />
          {<PaymentBreakdown
            piEscrow={piEscrow}
            piInterest={piInterest}
            piPrincipal={piPrincipal}
            piTotalMonthlyPayment={piTotalMonthlyPayment}
            isPaymentAllowed={isPaymentAllowed}
            showmonthlyincome={showmonthlyincome}
          />}
          <RecentTransaction
            accountNumber={accountNumber}
          />
        </Box>
        <Box className={classes.rightPannel}>
          {renderBanners()}
          <QuickLinksCard />
          <UnlockBenefitCard
            email={emailID}
            phone={mobileNumber}
            accountNumber={accountNumber}
            isCommConcent={isCommConcent}
            isCommConcentMobile={isCommConcentMobile}
            isMobileVerified={isMobileVerified}
            isEmailVerified={isEmailVerified}
            isAlreadyScheduleACHPayment={isAlreadyScheduleACHPayment}
            isGoGreen={isGoGreen}
            IsSmsConsent={isSmsConsent}
            handePopupClose={handePopupClose}
          />
        </Box>
      </Box>
     
   
       {  Cookies.get('isUpcomingPayDueOpened')==='true' && isPaymentAllowed
        && isDueDateApproaching && !paidInFull && !serviceReleased &&
        <UpcomingPaymentAlert
          totalAmountDue={totalAmountDue}
          piTotalMonthlyPayment={piTotalMonthlyPayment ? piTotalMonthlyPayment : ''}
          paymentDueDate={paymentDueDate}
          isAlreadyScheduleACHPayment={isAlreadyScheduleACHPayment}
          bankDetails={bankDetails}
          lastPaymentDate={lastPaymentDate}
          setUpAutoPay={setUpAutoPay}
          buydown={buydown}
        />
      } 
    
       {/* Promise to pay popup */}
       {
        (Cookies.get("showPromisePopup") == "true") && data?.isPromisePayPopUp && 
           <PromiseToPayAlert promiseToPayAlertStatus={true} />
       }
       {/* {
         (Cookies.get("escrow") == "false") && redirectUrl?.includes('Escrow') &&
           <EscrowAlert escrowAlertStatus={true} closeAccessDeniedPopup={closeEscrowPopup}/>
       } */}
       {
          accessStatus &&
           <EscrowAlert escrowAlertStatus={true} closeAccessDeniedPopup={closeEscrowPopup}/>
       }
       {
          maturityExtensionsInfo?.isPopUp &&
           <MaturityExtensionAlert promiseToPayAlertStatus={true} accountNumber={props.accountNumber} principalLoan={principalLoan} maturityExtensionsInfo={maturityExtensionsInfo}/>
       }
       {
         (escrowStatus == 'true') && data && data.displayEscrowAnalysisPopUp && eaStatus &&
           <EscrowAnalysisAlert escrowAlert={true} page={'desktop'} accountNumber={props?.accountNumber}/>
       }
        {requestSurvey && <PayoffRequestSurvey open={requestSurvey} handleSubmit={handleSubmitSurvey} accountNumber={props.accountNumber} handleSubmitClose={handleSubmitClose} popupTexts={popupTexts} displayRefinance={displayQuoteBanner?.length > 0 ? true : false}></PayoffRequestSurvey>}
      {requestSurveyQuestions && <PayoffRequestSurveyQuestions accountNumber={props.accountNumber} open={requestSurveyQuestions} handleSubmitClose={handleSubmitClose} partiallySubmitedData={partiallySubmitedData} isPartial={surveyStatus} setPartialSurvey={handleCloseSurvey} displayRefinance={Cookies.get('refinanceFlag') == 'true' ? true : false} RequestType='PaidOff'></PayoffRequestSurveyQuestions>}
      {
        <SnackBar handleClose={toggleSnackBar}
         open={openSnackBar}
         severity={'success'}
         message={SNACKBAR_SUCCESS}
       />
      }
       </>
   }
    
   
    
    {
      <>
       
      { (showPopup && thirdPartyPopupState) &&
        <NewThirdPartyRequest lstThirdParty={lstThirdParty} hideThirdPartyPopUp={hideThirdPartyPopUp} showPopup={showPopup} routePage={routePage}/>
      }
        
      {data && data?.lstIsInValidSSNData.length > 0 ?
        <IncorrectTinSsnAfterLogin
          details={data.lstIsInValidSSNData}
        /> : null
      }
       { (Cookies.get("isSemiAnnuallyPopUpAppeared") == "true") && !isAdmin() && 
        <ProfileUpdateAlert
        accountNumber={props.accountNumber}
        open={true}
        /> 
      }
        
      {
        data && !data.isTermAccept && !Cookies.get('adminToken') ?
          <TermsConditions /> : null
       }
       {/* Transfer alert popup */}
       {
        transferAlertStatus && 
          <TransferInfoAlert closePopup={closePopup} transferAlertStatus={transferAlertStatus} liquidationType={liquidationType} selectedAccount={props?.accountNumber} loanInfoData={loanInfoData}/>
       }
        { accountAccessInfo && data &&
        <PaidInFullPopup closePaidInFullPopup={closePaidInFullPopup} open={accountAccessInfo} selectedAccount={props?.accountNumber} liquidationType={liquidationType} loanInfoData={loanInfoData}/>
       }
      { purgedDaysInfo && data &&
        <PurgedDaysPopup closePurgedDaysPopup={closePurgedDaysPopup} open={purgedDaysInfo} selectedAccount={props?.accountNumber} liquidationType={liquidationType} loanInfoData={loanInfoData}/>
      }
       {  (Cookies.get("showDeliqPopup") == "true") && deliquentLoanPopupStatus && isDLQLMAPopup && (moment(new Date('2024-07-01')) > moment(new Date())) &&
        <DeliquentLoansAlert deliquentLoansAlertStatus={deliquentLoanPopupStatus} closeDeliquentLoanPopup={closeDeliquentLoanPopup} accountNumber={props.accountNumber}/>
       }

      { displayCommunicationConsentPopup && data && !changesReflectStatus(loanInfoData) && (Cookies.get("consentPopup") == 'true') &&
        <CommunicationConsentPopup handleRemindMe={handleRemindMe} handleProceed={handleProceed}/>
      }
       {
         fnmaBlindPopupStatus && fnmaPopupLoanStatus && <FemaBlindPopup handleCancelIcon={() => setDisplayFnmaPopup(false)} accountNumber={props?.accountNumber}  data={fnmaBlindData}/>
       }
       { displayPropertyRepair && remindMePropertyRepair && !isHEI() &&
          <DisasterClaimsPopup disasterInfo={disasterInfo} accountNumber={props?.accountNumber} handlePropertyRepairPopupSubmit={handlePropertyRepairPopupSubmit} clientDetails={clientDetails}/>
       }
    
      </>
     }
     
      { getPointPrivateLabel() && <>
        <Box className={classes.cardContainer}>
          <Grid container direction="row" spacing={3}>
            <Grid item md={4} xs={12} lg={4}>
              <Box className={clsx(classes.card)}>
                <Box className={classes.cardBody}>
                <img className={classes.cardImg} src={payoff} alt='icon' />

                  <p className={classes.cardTitle}>Request Payoff</p>
                  <p className={classes.bodyText}>Click on below button to request your Payoff quote.</p>
                </Box>
                <Box className={classes.cardFooter}>
                  <Button disabled={paidInFull || serviceReleased} color='primary' variant='contained' className={classes.btn} onClick={() => handleNavigation('PayoffRequest')}>Request Payoff</Button>
                </Box>
              </Box>
             </Grid>

             <Grid item md={4} xs={12} lg={4}>
              <Box className={clsx(classes.card)}>
                <Box className={classes.cardBody2}>
                  <img className={classes.cardImg} src={vom} alt='icon' />
                  <p className={classes.cardTitle}>Verification of HEI </p>
                  <p className={classes.bodyText}>Click on below button to request your Verification of Home Equity Investment.</p>
                </Box>
                <Box className={classes.cardBtn}>
                  <Button disabled={paidInFull || serviceReleased} color='primary' variant='contained' className={classes.btn} onClick={() => handleNavigation('VOM')}>REQUEST VERIFICATION</Button>
                </Box>
              </Box>
             </Grid>

             <Grid item md={4} xs={12} lg={4}>
              <Box className={clsx(classes.card)}>
                <Box className={classes.cardBody}>
                  <img className={classes.cardImg} src={thirdParty} alt='icon' />
                  <p className={classes.cardTitle}>Manage Third-Party Request</p>
                  <p className={classes.bodyText}>Click on below button to manage your third-party requests.</p>
                </Box>
                <Box className={classes.cardFooter}>
                  <Button disabled={paidInFull || serviceReleased} color='primary' variant='contained' className={classes.btn} onClick={() => handleNavigation('ManageThirdPartyRequest')}>Manage Third-party</Button>
                </Box>
              </Box>
             </Grid>
             
            </Grid>
          </Box>
      </> }
    </Box>
    
   {(displaySurvey && !lendingSurvey) && <Box className={classes.feedbackBtn} onClick={() => handleMortgageFeedback(false)}>
      <Button color='primary' variant='contained' className={classes.surveyFeedback}><img src={chat} className={classes.chatIcon}/>Future Mortgage Feedback 
      </Button>
    </Box> }
   
   {lendingSurvey && <Box className={classes.rootSurvey}> 
      <IconButton className={classes.surveyCloseIcon} onClick={() => handleMortgageFeedback(true)}>
        <CancelIcon />
      </IconButton>
      <Box className={classes.surveyTitle}>
        <Typography className={classes.statusTitle}>BSI Lending Consideration Feedback</Typography>
      </Box>
      
      <Box className={classes.lendingQuestion}>
         <Box className={classes.surveyContainer}>
          <Box className={classes.lendingContainer}>
           <Typography className={classes.paragraph}>{surveyText}</Typography>
            {ratings.map((item, index) => <>
                <Box className={classes.rating} style={{border: getBorderColor(index), backgroundColor: getBgColor(index)}} key={index} onClick={() => setRating(item.text)}>
                    <span key={index} className={classes.ratingText} style={{color: getTextColor(index)}} >{item.text}</span>
                </Box>
              </>
            )}
            <span className={classes.sliderLabel}>
              <span className={classes.sliderText}>Extremely Unlikely</span>
              <span className={classes.sliderText}>Extremely Likely</span>
            </span>

           {(rating !== undefined) && <Box>
              <TextareaAutosize
                style={{ height: '52px' }}
                color="primary"
                variant="outlined"
                type="text"
                name="comments"
                maxLength="500"
                onChange={(event) => handleRequestDescChange(event)}
                value={documentDesc}
                placeholder='Tell us more about your rating (Optional)...'
                className={classes.textArea}
              >
              </TextareaAutosize>
            </Box> }
          </Box> 
        </Box>
      </Box>

      <Box className={classes.singleFooterItem}>
          <Button
            variant='contained'
            color='primary'
            className={classes.surveyBtn}
            disabled={(rating == undefined) || isAdminCheck()}
            onClick={handleLendingSurvey}
          >SUBMIT FEEDBACK</Button>
      </Box>
      <Typography className={classes.timerNote}>* Your feedback will remain anonymous. This is not an advertisement or solicitation.</Typography>

    </Box> }

    {displayThanku && <Box className={classes.rootThanku}> 
      <IconButton className={classes.surveyCloseIcon} onClick={handleThankuClose}>
        <CancelIcon />
      </IconButton>
      <Box className={classes.thankyouTitle}>
        <Typography className={classes.statusTitle}>Thank You</Typography>
      </Box>
      
      <Box className={classes.lendingQuestion}>
         <Box className={classes.surveyContainer}>
          <Box className={classes.lendingContainer}>
           <Typography className={classes.paragraph}>Your input is vital in helping us enhance our services. We appreciate your time and feedback.</Typography>
            <Box className={classes.contact}><span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={handleKnowMore}>Know more</span> about your new home loan needs or contact <PhoneIcon mr={2} className={classes.iconStyle} />
               <a href={"tel:" + lendingContact.lendingNumber} style={{textDecoration: 'none', cursor: 'pointer'}} onClick={() => sendActivityLog('call')}> <span className={`${classes.contactTime} ${classes.noUnderLine}`}>{lendingContact.lendingNumber}</span></a>
            </Box>
          </Box> 
        </Box>
      </Box>

    </Box> }
    </>
  )
}
