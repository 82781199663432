import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const PaymentRestrictionAlert = (props) => {
 
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showPaymentText, setPaymentText] = useState(false)

  const handleOkBtnClick = async () => {
    props.handleOkBtn()
  }

  return (
    <Dialog  PaperProps={{ classes: { root: showPaymentText ? classes.dialogStyleExpand: classes.dialogStyle }}} onClose={props.handleOkBtn} open={true} className={showPaymentText ? classes.dialogClassExpand : classes.dialogClass}>
      <Box className={ showPaymentText ? classes.expandPopup : classes.root}>
        
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Alert</Typography>
        </Box>
       
        <Box className={classes.cardBodyContainer}>
           <Typography className={classes.records}><b>Note:</b> Allowed date range exceeded. Please select a date on or before <b>{props.date}</b>. {!props.isPmtCalExcl && <span className={classes.link} onClick={() => setPaymentText(!showPaymentText)}>{showPaymentText ? 'View Less' : 'View More...'}</span>}</Typography>
           {showPaymentText && <Box>
             <Typography className={classes.records}>The maximum date to post a payment is extended from <b>10 to 45 calendar days</b> and also determined by several factors to ensure your payment complies with your loan's delinquency status and servicing guidelines:</Typography>
              <ul className={classes.records} style={{textAlign: 'left'}}>
                <li><b>Making Regular payment: </b>You can select a date on calendar not resulting into 60+ delinquency</li>
                <li><b>Making Total Outstanding Payment: </b> You can select a date on calendar not resulting into 90+ delinquency</li>
               </ul>
             <Typography className={classes.records}>These restrictions are in place to help you manage your loan effectively and avoid further delinquency. For more details, please contact <a href="./HelpLanding?contactus" target="_self" className={classes.link}>customer care</a>.</Typography>
            </Box> }
        </Box>

        <Box className={classes.cardFooter}>
          <Button
            variant='contained'
            color='primary'
            className={classes.btn}
            onClick={() => handleOkBtnClick()}
            >GOT IT</Button>
        </Box>
      </Box> 
    </Dialog>
  )
}
