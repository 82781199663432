import React from 'react';
import { useStyles } from './styles';
import { Box, Typography, Dialog, Grid, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getLoanLabel } from '../../helpers';
import { decrypt } from '../../helpers/encrypt';

export const NewThirdPartyRequest = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
  
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.hideThirdPartyPopUp} open={props?.showPopup}>
      <IconButton onClick={props.hideThirdPartyPopUp} className={classes.closeIcon}>
        <CancelIcon />
      </IconButton>

      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>New Third-Party Request</Typography>
        </Box>

        <Box className={classes.box}>
          <Typography className={classes.information}>You have received a new request from an unauthorized third-party.</Typography>
          <Typography className={classes.information}>Click on below button to approve or decline the request(s) received.</Typography>  
        </Box>
       
          {
            props.lstThirdParty.length && props.lstThirdParty.map((item) => {
              return (
                <Box className={classes.transferBox}>
                  <Box key={`${item.id}_${item.accountNumber}`} className={classes.allLoanDetails}>
                    <Grid container>
                    <Grid item md={6} xs={12} lg={6} style={{textAlign :"start"}}><span className={classes.informationText}>For {getLoanLabel()} Number: </span></Grid>
                    <Grid item md={6} xs={12} lg={6} style={{textAlign :"start"}}><span className={classes.servicerText}> {decrypt(item.accountNumber)}</span></Grid></Grid>
                    <Grid container>
                    <Grid item md={6} xs={12} lg={6} style={{textAlign :"start", paddingTop: 10, paddingBottom: 10}}><span className={classes.informationText}>Request for : </span></Grid>
                    <Grid item md={6} xs={12} lg={6} style={{textAlign :"start", paddingTop: 10, paddingBottom: 10}}><span className={classes.servicerText}> {item.requestType}</span></Grid></Grid>
                    <Grid container>
                    <Grid item md={6} xs={12} lg={6} style={{textAlign :"start"}}><span className={classes.informationText}>From: </span></Grid>
                    <Grid item md={6} xs={12} lg={6} style={{textAlign :"start"}}><span className={classes.servicerText}> {item.thirdPartyName}</span></Grid></Grid>
                  </Box>
                </Box>
              )
            })
          }
        
          <Box className={classes.singleFooterItem}>
            <Button
              variant='contained'
              color="primary"
              className={classes.btn}
              onClick={() => props.routePage('ManageThirdPartyRequest')}
            > Manage Request</Button>
          </Box>
      </Box> 
   </Dialog>
  )
}

