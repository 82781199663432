import React, { useEffect, useState, useRef } from 'react';
import { useStyles } from './styles';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import {
  CircularProgressbar
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../../helpers/encrypt';
import { getUserType, getAppSource } from '../../helpers';
import { downloadPayoffFile } from '../../actions';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import tick from '../../assets/PayoffRequest/tick.svg';
import warning from '../../assets/warningSign.png';
import moment from 'moment';

export const OnlinePayoff = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const increment = useRef(null);

  const [progress, setProgress] = useState(0);
  const [leftTime, setLeftTime] = useState(0);
  const [timerLeftTime, setTimerLeftTime] = useState();
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    handleTimer()
  }, []);

  const handleTimer = () => {
    let timer = 180
    increment.current = setInterval(() => {
      setProgress((prevProgress) => (prevProgress === 0 ? 100 : prevProgress - .5556));
      if (timer === 0) {
        props?.timeLeftStatus(false)
        setLeftTime("00:00")
        setTimerLeftTime(0)
        clearInterval(increment.current)
      } else {
        setLeftTime(timer--)
      }
    }, 1000);
    return () => clearInterval(increment.current);
  }

  const handleReset = () => {
    clearInterval(increment.current);
  }

  const CircularProgressWithLabel = (props) => {
    const showTimer = (rt) => {
      if (rt === 'Completed') {
        return rt
      }
      rt = parseInt(rt)
      let minutes = Math.floor(rt / 60);
      let seconds = rt % 60

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `0${minutes}:${seconds}`;
    }

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress size="8rem" variant="static" {...props} style={{ color: '#7EB1CC' }} />
        <Box
          className={classes.timerBox}
        >
          <p
            className={classes.timerText}>
            {showTimer(props.remaingTime)} 
          </p>
          <p className={`${classes.timerText} ${classes.timerSmallText}`}>mins</p>
        </Box>
      </Box>
    );
  }

  const downloadPDF = async () => {
    const data = {
      "accountNumber": props.accountNumber,
      "id": props.requestId,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": ""
    }

    const response = await downloadPayoffFile(data)

    if (response) {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${decrypt(props.accountNumber)}_PO`); 
      document.body.appendChild(link);
      link.click();
    } else {
      setErrorMessage(renderDefaultMsg('400'))
    }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props?.closePopup} open={true}>
      <Box className={classes.dailogRoot}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{props?.popupTitle?.includes('Payoff') ? 'Payoff Quote' : 'Reinstatement Quote'}</Typography>
        </Box>
        
        <Box container="true" className={classes.root}>
          <Box className={classes.wrapper}>
            { (errorMessage !== '') &&
               <p className={classes.errorMessage}>{errorMessage}</p>
            }
             { ((props?.requestStatus == 'InProgress') && (props?.onlineStatus) && (timerLeftTime == 0)) &&
              <>
                <Box className={classes.cardBody2}>
                  <Box className={classes.cardContainer}>
                    <Box className={classes.imageContainer}>
                      <img src={warning} alt="warning" className={classes.image}/>
                      <Typography className={classes.apologize}>We apologize for the inconvenience. Please try your request again as we ran into a system issue.</Typography>
                    </Box>
                    </Box> 
                </Box>
              </> }

            { ((props?.requestStatus == 'Completed') && (props?.onlineStatus)) &&
              <>
                <Box className={classes.cardBody2}>
                  <Box className={classes.cardContainer}>
                    <Box className={classes.imageContainer}>
                      <img src={tick} alt="tick" className={classes.image}/>
                      <Typography className={classes.paragraph}>Your request for {props?.popupTitle?.includes('Payoff') ? 'payoff quote' : 'reinstatement quote'} has been processed and is available for download.</Typography>
                    </Box>
                    </Box> 
                </Box>
                <p className={classes.textTitle}>To download the {props?.popupTitle?.includes('Payoff') ? 'payoff quote' : 'reinstatement quote'}, <span className={classes.downloadPdfLink} onClick={() => downloadPDF()}>click here</span>.</p>
              </> }

            { ((props?.requestStatus == 'InProgress') && (props?.onlineStatus) && (timerLeftTime !== 0)) &&
             <>
              <p className={classes.heading}>Your request for {props?.popupTitle?.includes('Payoff') ? 'payoff quote' : 'reinstatement quote'} is being processed.</p>
              <Box className={classes.timerContainer}>
                <CircularProgressWithLabel remaingTime={leftTime} value={progress} />
              </Box>
              <p className={classes.timerNote}><b>Note:</b> Please keep this dialog box open and avoid refreshing the page to view it online. </p>
            </> }

           { (props?.onlineStatus == false) &&
              <>
                <Typography className={classes.paragraph}>Your payoff request has been submitted on {moment(props?.createdDate).format( "MM/DD/YYYY")}, it may take 3-5 business days to generate. Once it's ready, we'll send it to you via a secure email.</Typography>
    
                <Typography className={classes.textTitle}>If you don't see an email from us with your quote, please check your spam folder.</Typography>
                <Typography className={classes.textTitle}>Need immediate assistance? <a href="./HelpLanding?contactus" target="_self" className={classes.contactUs}>Contact us</a>.</Typography>
              </> }

            { (((props?.requestStatus == 'InProgress') && (props?.onlineStatus) && (timerLeftTime == 0)) || ((props?.requestStatus == 'Completed') && (props?.onlineStatus)) || (props?.onlineStatus == false)) &&
            <Box className={classes.singleFooterItem}>
              <Button
                variant='contained'
                color="primary"
                className={classes.btn}
                onClick={props.closePopup}
              > CLOSE</Button>
            </Box>}
          
          </Box>
        </Box > 
      </Box>
   </Dialog> 
  )
}
