import React from 'react';
import { useStyles } from './styles';
import { Box } from '@material-ui/core';
import { getAppSource, getUserType } from '../../helpers'
import { insertActivityLog } from '../../actions'
import Cookies from 'js-cookie';

export const PLBanner = (props) => {

  const { buttonColor, buttonText, buttonURL, bannerImageUrl } = props.banner;

  const { accountNumber } = props

  const handleClick = async () => {
    const data = {
      "accountNumber": accountNumber,
      "activityCode": "UAL040",
      "activityStatus": "Success",
      "action": "GetAccountInfo",
      "controller": "Dashboard",
      "apiName": "GetAccountInfo",
      "activityRemark": "User clicked on Allied Mortgage Group insurance click here button.",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    await insertActivityLog(data);
  }

  const handleImgClick = () => {
    window.open(buttonURL, '_blank')
  }

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.body} >
        <img src={bannerImageUrl} className={classes.imageContainer}></img>
      </Box>
      <Box className={classes.footer}>
        {buttonURL && buttonURL.length ? <a href={buttonURL} onClick={handleClick} className={classes.btn} style={{ backgroundColor: buttonColor }} target="_blank">{buttonText}</a> : null}
      </Box>
    </Box>
  )
}
