
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 900,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    maxWidth: 1000,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 2em',
    display: 'flex',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 22px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      fontWeight: "600"
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  btn: {
    width: 220,
    height: '38px',
    maxHeight: '40px',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    margin: "10px",
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        fontSize: 12,
        height: '32px',
        margin: 0
    }
  },

  labelStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "400",
  },

  cardFooter: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: "#fff",
    padding: '2px 170px 0',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0px 0',
      justifyContent: 'space-evenly'
    },
  },

  footerItem: {
    marginBottom: 14,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0
    },
  },

  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500"
 },

 infoIcon: {
  paddingRight: "10px",
  fontSize: '30px',
  color: "#F59720"
},

cardContainer: {
  width: '100%',
  marginTop: 12
},

totalCardContainer: {
  width: '100%',
  textAlign: 'center'
},

container: {
  border: '1px solid #D9D9D9',
  backgroundColor: "#00000",
  borderRadius: 4,
  padding: "6px"
},

detail: {
  fontSize: '14px',
  color: "#121D22",
  fontWeight: '400'
},

paymentDetail: {
  fontSize: '14px',
  color: "#121D22",
  fontWeight: '700'
},

paymentTotal: {
  fontSize: '16px',
  color: "#4D4D4D",
  fontWeight: '600'
},

paymentTotalDetails: {
  fontSize: '16px',
  color: "#4D4D4D",
  fontWeight: '700'
},

mainContainer: {
  display: "flex",
  justifyContent: "center",
},

cardContent: {
  display: 'flex'
},

cardTitle: {
  color: "#000000",
  fontFamily: "Montserrat",
  fontSize: "12px",
  fontWeight: "600",
  marginBottom: '20px',
  letterSpacing: '0.15px',
  linHheight: '24px',
},

details: {
  textDecoration: "underline", 
  cursor: 'pointer',
  color: "#007FFF !important", 
  fontWeight: 700
},
propertyAdd: {
  maxWidth: '265px !important',
  wordBreak: 'break-all',
  textAlign: 'justify'
},

table: {
  width: '100%'
},

tableContainer: {
  margin: '16px 0px 16px 0px'
},

icon: {
  paddingTop: 4,
  fontSize: 26,
  color: "#007FFF"
},

flexBox: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  paddingBottom: 9,
  flexWrap: 'wrap',
},

payment: {
  color: "#585757",
  fontFamily: "Montserrat",
  fontSize: 20
},

total: {
  color: "#585757",
  fontFamily: "Montserrat",
  fontSize: 20,
  marginRight: 30
},

responseShare: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: "600"
},

imageContainer: {
  border: '1px solid #EDB36A',
  backgroundColor: "#FBF0E1",
  borderRadius: 4,
  padding: "6px"
},

confirmationPending: {
  border: '1px solid #E0202033',
  backgroundColor: "#E0202033",
  borderRadius: 4,
  padding: "2px",
  color: '#E02020',
  fontWeight: "600",
  fontSize: 14
},

confirmationReceived: {
  border: '1px solid #19810033',
  backgroundColor: "#19810033",
  borderRadius: 4,
  padding: "2px",
  color: '#198100',
  fontWeight: "600",
  fontSize: 14
},

cardIcon: {
  width: 30,
  height: 26,
  color: "#F7B15C"
},

disasterTable: {
  maxHeight: '280px',
  overflowY: 'auto'
},

date: {
  color: '#121D22',
  fontSize: 14,
  fontFamily: 'Montserrat',
  fontWeight: 500
}

}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#535D6D",
    color: "#ffffff",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    fontFamily: "Montserrat",
    borderRight: "1px solid #ffffff",
    verticalAlign: "top",
    padding: '10px',
  },

  body: {
    fontSize: 16,
    color: "#202020",
    letterSpacing: "0.36px",
    lineHeight: "28px",
    fontFamily: "Montserrat",
    padding: '10px 5px',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
