import React, { useState, useEffect } from 'react';
import { Box, Button, CssBaseline, CircularProgress } from '@material-ui/core';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';
import { encrypt, decrypt } from '../../helpers/encrypt';
import { getAppSource, getUserType, renderDefaultMsg, getAccountLabel, getPointPrivateLabel } from '../../helpers';
import { TransferAlert, AccountLockedAlert, PurgedLoanPopup } from '../../components';
import { accountLoginWithEmail, getClientName } from '../../actions';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

export const SignInAccounts =  React.memo((props) => {

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [currentAccounts, setCurrentAccounts] = useState([])
  const [otherAccounts, setOtherAccounts] = useState([])
  const [isTransfer, setTransferStatus] = useState(false);
  const [transferData, setTransferData] = useState({
    fromPrivateLabel: "", toPrivateLabelPhone: "",
    toPrivateLabelURL: "", toPrivateLabel: ""
  });
  const [openAccountLockedStatus, setAccountLockedStatus] = useState(false);
  const [resendMailMsg, setResendMailMsg] = useState(false);
  const [accountNumber, setAccountNumber] = useState(false);
  const [userName, setUserName] = useState('');
  const [openMultiUserIdAlert, setOpenMultiUserIdAlert] = useState(false);
  const [purgedLoanPopup, setPurgedLoanStatus] = useState(false);
  const [purgedLoanMsg, setPurgedLoanMsg] =  useState();

  useEffect(() => {
    const filterCurrentAccounts = props?.accounts.filter((item) => item.isTransfer == false)
    const filterOtherAccounts = props?.accounts.filter((item) => item.isTransfer == true)
    setCurrentAccounts(filterCurrentAccounts)
    setOtherAccounts(filterOtherAccounts)
    if (filterCurrentAccounts.length > 0) {
      setAccountNumber(decrypt(filterCurrentAccounts[0].accountNumber))
      setUserName(filterCurrentAccounts[0].userName)
    }
  }, []);
  
  const closePurgedLoanPopup = () => {
    setPurgedLoanMsg()
    setPurgedLoanStatus(false)
  }

  const setRadioButton = (accountNumber, user) => {
    setAccountNumber(accountNumber)
    setUserName(user)
    setErrorMsg('')
  }

  const goToSignIn = () => {
    Cookies.remove('privateToken')
    history.push('/SignIn');
  }
 
  const getClientShortName = async (forCeaseandPersist=false) => {
    const data = {
      "accountNumber" : null,
      "userName": userName,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data));
    if (result) {
      const { lstResponseStatus, bankDetail, featureToggle } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        Cookies.set('clientName', bankDetail.clientShortName)
        Cookies.set('isAgency', bankDetail?.agencyFlag == "N" ? true : false)
        Cookies.set('escrow', bankDetail?.displayEscrowMenu)
        Cookies.set('ceaseDesistFlag', bankDetail?.ceaseDesistFlag);
        // Cookies.set('isDocRequestAllowed', featureToggle?.isDocRequestAllowed);
        Cookies.set("payOffRequestSLA", bankDetail?.payOffRequestSLA);
        if (bankDetail?.displayEscrowMenu) {
          window.location.reload(false)
          return true
        } else {
          window.location.reload(false)
        }
      }
    }
  }

  const accountLogin = async () => {
    setLoading(true);
    const data = {
      "accountNumber": encrypt(accountNumber),
      "emailId": encrypt(props?.email),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName":  ""
    }

    const response = await accountLoginWithEmail(data, props?.token)
    var redirectUrl = Cookies.get('redirectUrl')
    setAccountLockedStatus(false)
      const { lstResponseStatus } = response?.responseData;
      if (lstResponseStatus[0].statusCode === "0") {
        const res = response?.responseData?.responseData?.token;
        if (!res) {
          setErrorMsg(renderDefaultMsg('400'));
          Cookies.remove('redirectUrl')
        }

        if (res && res.expiresIn) {
          Cookies.set("isSemiAnnuallyPopUpAppeared", res?.isSemiAnnuallyPopUpAppeared)
          Cookies.set("showPromisePopup", true)
          Cookies.set('isMaturityExtenstionOpened',true);
          Cookies.set('isUpcomingPayDueOpened',true);
          Cookies.set("showDeliqPopup", true)
          Cookies.set("consentPopup", true)

          Cookies.set("firstLastName", decrypt(res?.firstName) +' '+decrypt(res?.lastName))
          Cookies.set("userName", userName)
          if (!res.isVerified) {
            getClientShortName(true);
            Cookies.remove('redirectUrl')
            history.push('/UserProfile/Profile');
          } else if (res && redirectUrl?.includes('Communication')) {
            getClientShortName(true); 
            history.push('/UserProfile/CommunicationPreferences');
          } else if (res && redirectUrl?.includes('Identity')) {
            getClientShortName(true); 
            history.push('/IdentityTheft');
          } else if (res && redirectUrl?.includes('DisasterClaims')) { 
            getClientShortName(true);
            history.push('/DisasterClaims');
          } else if (res && redirectUrl?.includes('EscrowInfo')) { 
            Cookies.set('escrow', true)
            const escrowStatus = getClientShortName(true);
            if (escrowStatus) {
                history.push('/EscrowInfo');
            }
          } else if (res && redirectUrl?.includes('DocumentRequest')) { 
            getClientShortName(true)
            history.push(`/DocumentRequest?requestId=${Cookies.get('requestId')}`);
          } else {
            Cookies.remove('redirectUrl')
            if (res.hasMultiUser) {
              getClientShortName(true);
              setLoading(false);
              setOpenMultiUserIdAlert(true)
            } else {
              setLoading(false);
              getClientShortName(true);
              history.replace({ pathname: "/Dashboard" });
            }
          }
        }
        if (res && !res.expiresIn) {
          Cookies.set("userName", userName)
          if (res.accessToken.includes("[ResendLink]")) {
            setResendMailMsg(true)
          } else {
            setErrorMsg('');
            setResendMailMsg(false)
            if (res.accessToken.includes('locked') || res.accessToken.includes('maximum')) {
              setAccountLockedStatus(true)
            } else if (res.accessToken.includes('verification')) {
              setErrorMsg(res.accessToken)
            } else if (res.accessToken.includes('account that is no longer available')) {
              setErrorMsg('');
              setPurgedLoanMsg(res.accessToken)
              setPurgedLoanStatus(true)
            }
          }
          setLoading(false);
        }
       
    } else {
      setLoading(false);
      setErrorMsg(renderDefaultMsg('400'))
    }
  }

  const handleViewDetails = (data) => {
      setTransferStatus(getPointPrivateLabel() ? false : true)
      setTransferData(data)
  }
  
  const handleForgotPasswordCloseAlert = () => {
      setAccountLockedStatus(false)
  }

  const renderTableBody = () => {
    return currentAccounts?.map((row, index) => {
      return (
        <StyledTableRow key={`${index}`}>
          <StyledTableCell align="center">
            <RadioGroup row
                       value={accountNumber}
                       onClick={() => setRadioButton(decrypt(row.accountNumber), row.userName)}
                      >
                      <FormControlLabel
                        value={decrypt(row.accountNumber)}
                        name="primPhoneType"
                        checked={accountNumber === decrypt(row.accountNumber) ? true : false}
                        control={<Radio classes={{ root: classes.radioStyle }} size="small"/>}
                        classes={{
                          label: accountNumber === decrypt(row.accountNumber) ? classes.activeRadioLabel : classes.radioLabel
                        }}
                      />
            </RadioGroup>
          </StyledTableCell>
          <StyledTableCell align="left">{decrypt(row.accountNumber)}</StyledTableCell>
          <StyledTableCell align="left">{decrypt(row.userName)}</StyledTableCell>
          <StyledTableCell align="center" className={classes.propertyAdd}>{row.propertyAddress}</StyledTableCell>
        </StyledTableRow>)
    })
  }
 
  const renderOtherAccounts = () => {
   
    return otherAccounts.map((row, index) => {
      return (
        <StyledTableRow key={`${index}`}>
          <StyledTableCell align="left">{decrypt(row.accountNumber)}</StyledTableCell>
          <StyledTableCell align="left">{decrypt(row.userName)}</StyledTableCell>
          <StyledTableCell align="center" className={classes.propertyAdd}>{row.propertyAddress}</StyledTableCell>
          <StyledTableCell align="right" className={classes.details} onClick={() => handleViewDetails(row)}>{'View Details'}</StyledTableCell>
        </StyledTableRow>)
    })
  }
 
  const handlePopupClose = () => setTransferStatus(false);
 
  const handleCloseAlert = () => {
    setOpenMultiUserIdAlert(false)
  }

  return (
    <CssBaseline>
      <>
       <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }}  open={true}>
      <Box className={classes.root}>
        <Box className={classes.formContainer}>
          <p className={classes.title}>Sign In</p>
          {errorMsg !== '' && <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>}
          { currentAccounts.length > 0 &&
          <>
          <p className={classes.subTitle}>Select an {getAccountLabel()?.toLowerCase()} to proceed</p>
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <StyledTableCell align="left">{getAccountLabel()} Number</StyledTableCell>
                  <StyledTableCell align="center">Username</StyledTableCell>
                  <StyledTableCell align="center">Property Address</StyledTableCell>
                </TableRow>
               </TableHead>
              <TableBody>
                {renderTableBody()}
              </TableBody>
            </Table>
          </TableContainer>
          </>
          }

          { ((otherAccounts.length > 0 && currentAccounts.length > 0) || ((otherAccounts.length > 0 && currentAccounts.length == 0))) &&
           <>
            { (otherAccounts.length > 0 && currentAccounts.length > 0) &&
            <hr style={{ marginTop: '20px', marginBottom: '20px' }}/>
            }
            <p className={classes.subTitle}>The login website for the following {getAccountLabel()?.toLowerCase() + '(s)'} has been updated </p>
           </>
          }

          { (otherAccounts.length > 0) &&
           <>
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">{getAccountLabel()} Number</StyledTableCell>
                    <StyledTableCell align="center">Username</StyledTableCell>
                    <StyledTableCell align="center">Property Address</StyledTableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderOtherAccounts()}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          }

          <Box className={classes.signinContainer}>
          { (currentAccounts?.length == 0 && otherAccounts?.length > 0) ? <></> : <>
            {loading ?
              <Button disabled={true} type='submit' className={classes.otpBtn} size="large" fullWidth variant="contained">
                <CircularProgress color='primary' />
              </Button>
              :
              <Button type='submit' className={classes.otpBtn} size="large" fullWidth variant="contained" color="primary" onClick={() => accountLogin()} disabled={!accountNumber}>
              Continue
              </Button>
            } </>
          }
            <Button onClick={() => goToSignIn()} className={classes.usernameBtn} size="large" fullWidth variant='outlined' color="primary">
                Back to Sign In
            </Button>
         </Box>
        </Box> 
       </Box>
      </Dialog>
      {isTransfer &&
          <TransferAlert
            fromPrivateLabel={transferData.fromPrivateLabel}
            toPrivateLabel={transferData.toPrivateLabel}
            toPrivateLabelPhone={transferData.toPrivateLabelPhone}
            toPrivateLabelURL={transferData.toPrivateLabelURL}
            show={true}
            handleClose={handlePopupClose}
          />}
      {openMultiUserIdAlert &&
        <MultiAccountAlert
          userName={decrypt(userName)}
          open={openMultiUserIdAlert}
          handleClose={handleCloseAlert}
        />}
      {openAccountLockedStatus &&
          <AccountLockedAlert
            handleClose={handleForgotPasswordCloseAlert}
            accountLocked={''}
          />}
       {purgedLoanPopup &&
          <PurgedLoanPopup open={purgedLoanPopup} closePurgedLoanPopup={closePurgedLoanPopup} type={'signin'} purgedLoanMsg={purgedLoanMsg}
          />
        }
      </>
    </CssBaseline>
  )
})
