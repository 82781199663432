import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import tick from '../../../assets/PayoffRequest/tick.svg';
import { useStyles } from './styles';

export const SubmitDisasterClaim = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Response Submitted</Typography>
        </Box>
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
            <Box className={classes.cardContainer}>
              <Typography className={classes.heading}><img src={tick} /><span>Your response for property repair has been submitted successfully!</span></Typography>
              <Typography className={classes.responseReceived}>Based on your submitted response and some preliminary verification we will process the request for {props.loanNumber}.</Typography>
            </Box>
          </Box>
        </Box>
       
        <Box className={classes.cardFooter}>
          <Button
            variant='contained'
            color='primary'
            className={classes.btn}
            onClick={props.handleGotIt}
            >Got it</Button>
        </Box>
      </Box>
    </Dialog>
  )
}
