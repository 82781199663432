import { makeStyles } from '@material-ui/core/styles';
import signinIcon from '../../assets/SignIn/SignIn.svg';



export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#ddd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${signinIcon})`,
    backgroundPosition: "bottom left",
    backgroundRepeat: "no-repeat",
    backgroundSize: 'contain',
  },

  wrapper: {
    maxWidth: '58.8em',
    padding: '2em',
    backgroundColor: '#fff',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '10px'
    },
  },

  title: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '3.6em',
    fontWeight: "600",
    letterSpacing: "0.5px",
    marginBottom: 33,
    marginTop: 0,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.6em',
    },
  },

  inputContainer: {
    marginBottom: 20,
  },

  inputStyle: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
  },

  heading: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 18,
    letterSpacing: '0.4px',
    marginBottom: 20,
    marginTop: 0,
    textAlign: 'center',
    fontWeight: '600'
  },

  paragraph: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 18,
    letterSpacing: '0.4px',
    marginBottom: 20,
    marginTop: 0,
    textAlign: 'center',
    fontWeight: '600'
  },

  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 100,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
      marginBottom: 10,
    },
  },

  sendBtn: {
    fontWeight: '600',
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    display: 'block',
    minWidth: '140px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  resendBtnText: {
    color: "#535D6D",
    fontFamily: "Montserrat",
    fontSize: '0.9em',
    fontWeight: '600',
    textDecoration: 'underline',
    border: 'none',
    backgroundColor: '#fff',
    outline: 'none',
    cursor: "pointer",
  },

  expireText: {
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: 'bold',
    minWidth: 140,
  },

  timeText: {
    color: "#7EB1CC",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: 'bold',
    minWidth: 140,
  },

  timerContainer: {
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px auto',
  },

  circle: {
    width: '120px',
    height: '120px',
  },

  errorMsg: {
    fontSize: '1.4em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'Montserrat',
    color: "red",
  },

  timerBox: {
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  timerText: {
    color: '#7EB1CC',
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: '600',
    letterSpacing: '0',
    textAlign: 'center',
    lineHeight: '24px'
  },

  timerSmallText: {
    fontSize: '16px',
    margin: '0'
  },
 
  sendBtn: {
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: '600',
    marginBottom: 20,
    color: '#535D6D !important',
    border: 'solid 2px',
    borderColor: theme.palette.primary.main,
    boxShadow: "0px 4px 8px 0 rgba(0,0,0,0.14)",
    height: 50,
  },
   
  submitBtn: {
    marginBottom: 20,
    fontSize: 16,
    fontWeight: '600',
  },

  dailogRoot: {
    maxWidth: 550,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: '22px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px'
    }
  },

  singleFooterItem:{
    textAlign: 'center',
    padding: '0px 20px 20px'
  },
  
  btn: {
    width: 200,
    height: '40px',
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    margin: "10px",
    [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        height: '35px'
    }
  },

  downloadPdfLink: {
    color: "#007FFF",
    fontFamily: "Montserrat",
    fontSize: "18px",
    letterSpacing: "0.31px",
    lineHeight: "15px",
    fontWeight: "600",
    cursor: "pointer",
    textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px"
    },
  },

  errorMessage: {
    color: "#E30909",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    letterSpacing: "0.36px",
    padding: "0 5px"
  },
 
  cardBody2: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    // padding: '8px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    marginTop: 2
  },
  
  imageContainer: {
    borderRadius: 4,
    padding: "6px",
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      display: "block"
    },
  },

  image: {
    height: '40px',
    width: '40px'
  },

  textTitle: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 18,
    letterSpacing: '0.4px',
    marginBottom: 20,
    marginTop: 0,
    textAlign: 'center',
    fontWeight: '400'
  },

  contactUs: {
    color: "#007FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "400"
  },

  apologize: {
    color: "#E02020",
    fontFamily: "Montserrat",
    fontSize: 18,
    letterSpacing: '0.4px',
    marginBottom: 20,
    marginTop: 0,
    textAlign: 'center',
    fontWeight: '400'
  },

  timerNote: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 18,
    letterSpacing: '0.4px',
    marginBottom: 20,
    marginTop: 0,
    textAlign: 'center',
    fontWeight: '400'
  },

}));

