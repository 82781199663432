import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  dialogClass: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '500px',
      borderRadius: '16px'
    },
  },

  dialogClassExpand: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '700px',
      borderRadius: '16px'
    },
  },

  expandPopup: {
    width: 700,
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  dialogStyle: {
    width: 500,
    borderRadius: '12px',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  dialogStyleExpand: {
    width: 700,
    borderRadius: '12px',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardBodyContainer: {
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: '#4D4D4D',
    fontFamily: "Montserrat",
    fontSize: '18px',
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  btn: {
    width: 175,
    height: '40px',
    fontFamily: "Montserrat",
    fontSize: '16px',
    fontWeight: "600",
    margin: "10px",
    padding: '10px 10px',
    [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: '14px'
    }
  },
  
  cardFooter: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 80px 0px',
      width: '94%'
    },
  },

  records: {
    color: '#4D4D4D',
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 4,
    marginTop: 8,
    // textAlign: 'center'
  },

  link: {
    textDecoration: 'underline', 
    cursor: 'pointer', 
    fontWeight: '700',
    color: '#0079D0'
  }

}));
