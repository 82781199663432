import React from 'react';
import { Box, Button, Typography, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { decrypt } from '../../helpers/encrypt';
import { maskAccountNo, maskAccount, isAdmin } from '../../helpers';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';

const LightToolTip = withStyles((theme) => ({
  arrow: {
    color: '#235A86',
  },
  tooltip: {
    backgroundColor: '#235A86',
    color: '#fffff',
    fontFamily: "Montserrat",
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    padding: "16px",
    fontSize: 14,
    maxWidth: 350,
    fontWeight: 500,
    borderRadius: 8
  },
}))(Tooltip);

export const ReviewMultiPayment = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {loans, selectedBank, handleReviewPopupClose, handleReviewPopupSubmit} = props;
 
  const handleTotalAmount = (loan) => {
    let total = loan.totalPaymentAmount ? Number(loan.totalPaymentAmount) : loan.totalAmountDue;
    total = total + (loan.processingFee.isDisplay ? loan.processingFee.processingFee : 0) + loan.forbearanceApplicationFee;
    if (loan.additionalPrincipal) {
      total = total + parseFloat(loan.additionalPrincipal)
    }
    if (loan.additionalEscrow) {
      total = total + parseFloat(loan.additionalEscrow)
    }
    return total.toFixed(2);
  }

  const renderPaymentTotal = () => {
    let paymentTotal = 0;
    loans.filter(loan => loan.isSelected === true)
      .map((loan) => {
        if (loan.totalPaymentAmount) {
          paymentTotal = paymentTotal + Number(loan.totalPaymentAmount);
        } else {
          paymentTotal = paymentTotal + Number(loan.totalAmountDue);
        }
        if (loan.additionalPrincipal) {
          paymentTotal = paymentTotal + Number(loan.additionalPrincipal)
        }
        if (loan.additionalEscrow) {
          paymentTotal = paymentTotal + Number(loan.additionalEscrow)
        }
        if (loan.processingFee) {
          paymentTotal = paymentTotal + (loan.processingFee.isDisplay ? Number(loan.processingFee.processingFee) : 0)
        }
        if (loan.forbearanceApplicationFee) {
          paymentTotal = paymentTotal + Number(loan.forbearanceApplicationFee)
        }
      });
    return paymentTotal.toFixed(2);
  }

  const renderTableBody = () => {
    return loans.filter(item => item.isSelected === true)?.map((row, index) => {
      return (
        <StyledTableRow key={`${index}`}>
          <StyledTableCell align="center" style={{display: 'flex'}}>{maskAccount(decrypt(row.accountNumber))}   
              { (row.additionalPrincipal > (row.unpaidPrincipalBalance - 5000)) && <Box ><LightToolTip arrow
                title={'We see that the designated principal amount for this loan is within $5,000. If you intend to payoff, you can request payoff else continue payment.'} placement="right" >
                <InfoIcon className={classes.icon} />
              </LightToolTip></Box>}
          </StyledTableCell>
          <StyledTableCell align="center">${row.totalPaymentAmount ? Number(row.totalPaymentAmount) : row.totalAmountDue}</StyledTableCell>
          {/* {<StyledTableCell align="center">{row.processingFee.isDisplay ? `$ ${row.processingFee.processingFee}` : '-'}</StyledTableCell>} */}
          <StyledTableCell align="center">${Number(row.additionalPrincipal)}</StyledTableCell>
          <StyledTableCell align="center">${Number(row.additionalEscrow)}</StyledTableCell>
          <StyledTableCell align="center">{row.loanPaymentDueDate ? row.loanPaymentDueDate : moment(new Date()).format('L')}</StyledTableCell>
          <StyledTableCell align="center" >${handleTotalAmount(row)}</StyledTableCell>

        </StyledTableRow>)
    })
  }
 
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} open={true}>
     
      <Box className={classes.root}>
          
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Review & Make Payment</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
          {selectedBank?.bankAccountNumber && 
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             
             <Typography className={classes.paragraph}><span className={classes.detail}>Bank Account:</span> <span className={classes.paymentDetail}>{selectedBank?.nickName}, {maskAccountNo(decrypt(selectedBank?.bankAccountNumber))}</span></Typography>
              </Box>
            </Box> }

            <Box className={classes.tableContainer}>
              <TableContainer component={Paper} >
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center" >Loan Number</StyledTableCell>
                      <StyledTableCell align="center" >Payment Amount</StyledTableCell>
                      {/* <StyledTableCell align='center'> Process- ing Fee </StyledTableCell> */}
                      <StyledTableCell align="center" >Additional Principal Amount</StyledTableCell>
                      <StyledTableCell align="center" >Additional Escrow Amount</StyledTableCell>
                      <StyledTableCell align="center" >Draft Date</StyledTableCell>
                      <StyledTableCell align="center" >Total Amount</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                      {renderTableBody()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

          </Box>
          <Box className={classes.flexBox}>
              <h4 className={classes.total}>Payment Total: </h4>
              <h3 className={classes.payment}>${renderPaymentTotal()}</h3>
          </Box>
        </Box>
    
        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={handleReviewPopupClose}
            > EDIT</Button>
          </Box>
          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                disabled={Cookies.get('adminToken') || isAdmin()}
                onClick={handleReviewPopupSubmit}
              >MAKE PAYMENT</Button>
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}

