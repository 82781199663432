import axios from 'axios';
import Cookies from 'js-cookie';
import { axiosProtected, axiosPublic, baseUrl } from '../config';

export const submitPayoff = async (data) => {   
    try {
      const res = await axiosProtected.post('/Payoff/SubmitPayoff', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }

  export const submitVOM = async (data) => {    
    try {
      const res = await axiosProtected.post('/Payoff/SubmitVOM', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }
  export const getPayoffVOMDetails = async (data) => {       
    try {
      const res = await axiosProtected.post('/Payoff/GetPayoffVOMDetails', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }
  export const getPartialSurvey = async (data) => {       
    try {
      const res = await axiosProtected.post('/Survey/GetPartialSubmittedSurvey', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }
  
  export const getPayoffQuestionList = async (data) => {       
    try {
      const res = await axiosProtected.post('/Survey/GetSurveyQusetion', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }
  export const submitPayoffQuestion = async (data) => {       
    try {
      const res = await axiosProtected.post('/Survey/SubmitSurvey', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }
  

  export const resendEmailPayoff = async (data) => {       
    try {
      const res = await axiosProtected.post('/Payoff/ResendEmail', data);      
      return res.data
    } catch (e) {     
      return null
    }
  }

  export const readandConvertToPDF = async (data) => {      
    try {
      const res = await axiosProtected.post('/Payoff/ReadandConvertToPDF', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }

  export const downloadPayoffPDF = async (data) => {
    const token = await Cookies.get('privateToken')
    try {
      const res = await axiosProtected({
          url: `${baseUrl}/Payoff/DownloadPDF`,
          method: 'POST',
          responseType: 'blob', // important
          data: data,
          // headers: {
          //   Authorization: `Bearer ${token}`
          // }
        })
        return res
    } catch (e) {      
      return null
    }
  }

  export const getPayoffReasons = async (data) => {       
    try {
      const res = await axiosProtected.post('/Payoff/GetPayoffReasons', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }

  export const getPayoffDetails = async (data) => {       
    try {
      const res = await axiosPublic.post('/OT/GetPayoffVOMDetails', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }

  export const validateThirdPartyUrl = async (data) => {       
    try {
      const res = await axiosPublic.post('/OT/Validate3rdPartyURL', data);      
      return res.data
    } catch (e) {      
      return null
    }
  }

  export const downloadPayoffFile = async (data) => {
    try {
      const res = await axiosPublic({
          url: `${baseUrl}/OT/DownloadPDF`,
          method: 'POST',
          responseType: 'blob',
          data: data
        })
        return res
    } catch (e) {      
      return null
    }
  }

