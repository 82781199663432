import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import appStoreLogo from '../../assets/appStoreLogo.svg';
import playStoreLogo from '../../assets/playStoreIcon.svg';
import Cookies from 'js-cookie';
import equalHousingLender from '../../assets/equalHousingLender.svg';
import { DisasterAlert } from '../../components';
import { FeedBackComp, SurveyWizard } from '../../components';
import { getPointPrivateLabel, getBorrowerLabel, isAdminCheck, isHEI, changesReflectStatus } from '../../helpers';
import PhoneIcon from '@material-ui/icons/Phone';
import moment from 'moment';

export const Footer = (props) => {
 
  const classes = useStyles();
  const [login, setLogin] = React.useState(false);
  const [confirmAlert, setIsAgencyLoanAlert] = React.useState(false);
  const [showFeedBack, setShowFeedBack] = React.useState(true);
  const [showSurvey, setShowSurvey] = React.useState(true);
  const theme = useSelector(state => state.theme);
  const privateLabelUrl = Cookies.get("privateLabelUrl")
  var data = useSelector(state => state.accountInfoData);
  const nycRulesData = useSelector(state => state?.loanInfoReducer);
  const selectedAccountData = useSelector(state => state?.selectedAccountData);

  const history = useHistory();
  const hafBsiUrl = `https://bsifinancial.com/borrower_homeowner_assistance_fund.html`;
  const hafNonBsiUrl = `https://www.ncsha.org/homeowner-assistance-fund/`;
  const [naturalDisasterStatus, setNaturalDisasterStatus] = React.useState({
    display: false,
    isAgencyLoan: false
  });

  useEffect(() => {
    if (Cookies.get('privateToken')) {
      setTimeout(() => {
        setNaturalDisasterStatus(
          {
            display: Object.keys(data).length > 0  ? data.displayDisasterAssistance : Cookies.get("displayNaturalDisasterLink") == 'true' ? true : false,
            isAgencyLoan: Object.keys(data).length > 0 ? data.isAgencyLoan : Cookies.get("isAgencyLoan") == 'true' ? true : false
          }
        )
      }, 3000);
      setLogin(true)
     
    } else {
      setLogin(false)
    }
  }, [])

  const routeChange = async () => {
    if (Cookies.get('privateToken')) {
      history.push(`/ManageThirdPartyRequest`);
    } else {
      history.push(`/ThirdPartyRequest`);
    }
  }

  const handleNMLSLink = () => {
    const win = window.open('https://nmlsconsumeraccess.org/', "_blank");
    win.focus();
  }

  const handleAmsLink = () => {
    const win = window.open('https://www.aafmaa.com/mortgage/legal-licensing', "_blank");
    win.focus();
  }

  const handleFairNoticeLink = () => {
    const win = window.open('https://www.aafmaa.com/mortgage/fair-lending-notice', "_blank");
    win.focus();
  }

  const handleUrlFund = () => {
    const urlACTIVE = privateLabelUrl.toUpperCase() === "BSI" ? hafBsiUrl : hafNonBsiUrl;
    const win = window.open(urlACTIVE, "_blank");
    win.focus();
  }

  const handleClose = () => {
    setIsAgencyLoanAlert(false);
  }
 
  const routeToNaturalDisaster = () => {
    if (naturalDisasterStatus.isAgencyLoan) {
      history.push(`/DisasterAssistance`);
    } else {
       setIsAgencyLoanAlert(true)
    }
  }

  const hideFeedback = () => {
    props.displayFeedback(true)
    setShowFeedBack(false)
  }
  
  const hideSurvey = () => {
    setShowSurvey(false)
  }

  useEffect(() => {
    if (props?.show) {
      setShowFeedBack(true)
    }
  }, [props?.renderFooterComp])

  const getNycRuleStatus = () => {
    if ((selectedAccountData?.propertyState.toUpperCase() == "NY") && nycRulesData?.nycRules?.is6year && nycRulesData?.nycRules?.isNYCRules) {
      return true;
    } else if ((selectedAccountData?.propertyState.toUpperCase() !== "NY") && nycRulesData?.nycRules?.is6year && nycRulesData?.nycRules?.isNYCRules) {
      return true;
    } else if ((selectedAccountData?.propertyState.toUpperCase() == "NY") && nycRulesData?.nycRules?.is6year) {
      return true;
    } else {
      return false;
    }
  }

  const { copyRight, address, customerCareNumber, privateLabelLogoURLFooter, lendingNumber, customerCareTime, lendingNumberTime } = theme;

  return (
    <React.Fragment>
     
       {props.show && showFeedBack && <FeedBackComp status={true} hideFeedback={hideFeedback} text={props?.text}/>}
       {window.location.pathname.includes('Dashboard') && showSurvey && !isAdminCheck() && (privateLabelUrl.toLowerCase() === "bsi") && Cookies.get('privateToken') && (moment(new Date('2024-03-12')).add(1, 'days') > moment(new Date())) && <SurveyWizard status={true} hideSurvey={hideSurvey} text={'BSI Brand Refresh Survey'}/>}

      <Box className={classes.root} id="callUs">
        <Box className={classes.rootWrapper}>
          <Link className={classes.footerLogo} to="/"> <img className={classes.footerLogo} src={privateLabelLogoURLFooter} alt='logo' /></Link>

          <Box className={classes.flexBox}>
            <Box className={classes.addressContainer}>
              <p className={classes.textStyle} dangerouslySetInnerHTML={{ __html: address }}>
              </p>

              {privateLabelUrl.toLowerCase() === "aafmaa" ?
                <p><img src={equalHousingLender} className={classes.image} onClick={handleNMLSLink} /></p> : null
              }
                           
              { customerCareNumber &&
              <Box>
                <Box className={`${classes.helpAddress} ${classes.flex}`}>
                  <Box style={{display: 'flex', alignItems: 'center'}}>Customer Care: <PhoneIcon mr={2} className={classes.iconStyle} /> <a href={"tel:" +    customerCareNumber} style={{textDecoration: 'none', cursor: 'pointer'}}>
                    <span className={`${classes.contactTime} ${classes.noUnderLine}`} >
                    {customerCareNumber}</span> </a>
                  </Box> 
                </Box> 
                <Typography className={classes.visitTimeFont} mt={2} dangerouslySetInnerHTML={{ __html: customerCareTime }}>
                  {/* Weekdays: 8:00 am to 11:00 pm (EST)<br />
                  Sat: 8:00 am to 11:00 pm (EST) */}
                </Typography>
              </Box> }
            
              { lendingNumber &&
              <Box mt={4}>
                  <Box className={`${classes.helpAddress} ${classes.flex}`}>
                    <Box style={{display: 'flex', alignItems: 'center'}}>Buy / Refinance: <PhoneIcon mr={2} className={classes.iconStyle} />
                      <a href={"tel:" + lendingNumber} style={{textDecoration: 'none', cursor: 'pointer'}}> <span className={`${classes.contactTime} ${classes.noUnderLine}`} >
                      {lendingNumber}</span></a>
                    </Box>
                  </Box> 
                  <Typography className={classes.visitTimeFont} mt={2} dangerouslySetInnerHTML={{ __html: lendingNumberTime }}>
                    {/* Weekdays: 9:30 am to 6:30 pm (EST)<br /> */}
                  </Typography>
              </Box> }
            </Box>

            <Box className={classes.mainContainer} >
              {!login && <Box className={classes.linkContainer}>
                <p className={classes.linkHeading} >Quick Links</p>
                <Link className={classes.linkStyle} to='/AccountVerification'>Create Account</Link>
                <Link className={classes.linkStyle} to='/HelpLanding'>Help</Link>
                <Link className={classes.linkStyle} to='/SignIn'>Log In</Link>
              </Box>}
              {login && <Box className={classes.linkContainer}>
                <p className={classes.linkHeading} >Quick Links</p>
                <Link className={classes.linkStyle} to='/HelpLanding'>Help</Link>
              </Box>}
              <Box className={classes.linkContainer}>
                <p className={classes.linkHeading}>Resources</p>
                {login && naturalDisasterStatus.display &&
                <span className={classes.linkStyle} onClick={() => routeToNaturalDisaster()}>Natural Disaster Assistance</span>}
                {!getPointPrivateLabel() && <Link className={classes.linkStyle} to='/Covid19'>COVID-19</Link>}
                {!getPointPrivateLabel() &&
                <span className={classes.linkStyle} onClick={() => handleUrlFund()}>Homeowner Assistance Fund</span>}
                {!getPointPrivateLabel() &&
                <Link className={classes.linkStyle} to='/HelpDescription/FAQs'>FAQs</Link>}
                <Link className={classes.linkStyle} to='/NewlyBoarded'>New {getBorrowerLabel()}s</Link>
                <span className={classes.linkStyle} onClick={routeChange}>Third Party Authorization</span>
               
              </Box>
              <Box className={classes.linkContainer}>
                <p className={classes.linkHeading}>Company</p>
                <Link className={classes.linkStyle} to='/AboutUs'>About Us</Link>
                <Link className={classes.linkStyle} to='/ContactUs'>Contact Us</Link>
                {privateLabelUrl.toUpperCase() === "BSI" ? <span className={classes.linkStyle} onClick={handleNMLSLink}>NMLS Consumer Access</span> : null}
              </Box>

              <Box className={classes.linkContainer}>
                <p className={classes.linkHeading}>Legal</p>
                {privateLabelUrl.toUpperCase() === "AAFMAA" &&
                    <Link className={classes.linkStyle} onClick={handleAmsLink}>AMS Legal & Licensing</Link> }
                {/* <Link className={classes.linkStyle} to='/EmailTextPolicy'>Email and Text Policy</Link> */}

                {privateLabelUrl.toUpperCase() === "AAFMAA" &&    
                    <Link className={classes.linkStyle} onClick={handleFairNoticeLink}>Fair Lending Notice</Link> }
                
                
                <Link className={classes.linkStyle} to='/Privacy'>Privacy Statements</Link>
                <Link className={classes.linkStyle} to='/SecurityDisclaimer'>Security Disclaimer</Link>
                <Link className={classes.linkStyle} to='/Terms'>Terms {"&"} Conditions</Link>
                {!login && !isHEI() && <Link className={classes.linkStyle} to='/Nycrules'>NYC Rules on Language Services and Rights</Link>}
                {login && getNycRuleStatus() && !isHEI() && !changesReflectStatus(nycRulesData?.purgeLoanInfo) && <Link className={classes.linkStyle} to='/Nycrules'>NYC Rules on Language Services and Rights</Link>}
              </Box>
            </Box>
          </Box>
    
         { (props?.description && (props?.description.length > 0) && props.display) && <Box className={classes.description}>
            <div dangerouslySetInnerHTML={{ __html: props.description[0].bannerSubTitle }}></div>
          </Box>}

          <Box className={classes.footerFoot}>
            <Box>
              {/* <nav className={classes.footerNav}>
              <Link className={classes.navLink} to='/SecurityDisclaimer'>Security/Disclaimer</Link>
              <Link className={classes.navLink} to='/Privacy'>Privacy</Link>
              <Link className={classes.navLink} to='/HelpLanding'>Help</Link>
            </nav> */}
              <p className={classes.copyRightText}>{copyRight ? `©${copyRight}` : ''}</p>
            </Box>
            {/* {privateLabelUrl.toUpperCase() === "BSI" ? <Box className={classes.appLinkContainer}>
            <p className={classes.linkHeading}>Get the Mobile App</p>
            <Box className={classes.imageLinkContainer}>
              <a className={classes.appLink} href='https://itunes.apple.com/us/app/bsi-financial/id1334719592?ls=1&amp;mt=8' target="_blank">
                <img src={appStoreLogo} alt='appstore-icon' /></a>
              <a className={classes.appLink} href='https://play.google.com/store/apps/details?id=com.bsi.bsiloan' target="_blank">
                <img src={playStoreLogo} alt='playstore-icon' /></a>
            </Box>
          </Box> : null} */}
          </Box>
        </Box>
      </Box>
      {
        confirmAlert && <DisasterAlert
          open={confirmAlert}
          handleClose={handleClose}
        />
      }
    </React.Fragment>
  )
}
