import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogClass: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '790px',
      borderRadius: '16px'
    }
  },

  root: {
    position: 'relative',
    width: '100%',
    padding: '50px 80px'
  },

  statusSuccess: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "500",
    marginBottom: 30,
    textAlign: 'center',
    lineHeight: '20px',
    borderBottom: '1px solid #e0dcdc',
    paddingBottom: '20px',
  },

  thaanks: {
    marginTop: '40px'
  },

  statusFail: {
    color: "red",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 20,
    textAlign: 'center'
  },

  container: {
    backgroundColor: '#FFF',
  },

  imgContainer: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '50px'
  },

  displayFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  item: {
    minWidth: '40%',
    marginRight: 10,
  },

  text: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    letterSpacing: '0.53px',
    lineHeight: '20px',
    margin: '25px 0',
  },

  offline: {
    minWidth: '40%',
    margin: '25px 0'
  },

  boldText: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    marginLeft: 10,
  },

  btn: {
    display: 'block',
    margin: '0 auto',
    color: "#FFF",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
  },

  closeBtn: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    cursor: 'pointer'
  },

  paragraph: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 18,
    letterSpacing: '0.4px',
    marginBottom: 20,
    marginTop: 0,
    textAlign: 'center',
    fontWeight: '600'
  },

  textTitle: {
    color: "#4D4D4D",
    fontFamily: "Montserrat",
    fontSize: 18,
    letterSpacing: '0.4px',
    marginBottom: 20,
    marginTop: 0,
    textAlign: 'center',
    fontWeight: '400'
  },

  contactUs: {
    color: "#007FFF",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "400"
  },


}));