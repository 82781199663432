import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Grid, Button, Typography, RadioGroup, Radio, FormControlLabel, Tooltip, TextareaAutosize, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, styled } from '@material-ui/core/styles';
import { propertyRepairDisplay, uploadDisasterClaimDocuments, deleteDisasterClaimDocument, downloadDisasterClaimDocument } from '../../actions';
import { FileSizeAlert } from '../PayoffRequest/FileSizeAlert';
import { DeleteConfirmation } from '../PayoffRequest/DeleteConfirmationOnAccount/DeleteConfirmation';
import { renderDefaultMsg, scrollTop, isAdminCheck, getAppSource, getUserType } from '../../helpers';
import { SubmitDisasterClaim } from './SubmitDisasterClaim';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomBreadcrum from '../Breadcrum';
import image from '../../assets/image.png';
import { decrypt } from '../../helpers/encrypt';
import Cookies from 'js-cookie';
import moment from 'moment';
import './index.css';

const LightTooltip = withStyles((theme) => ({
    arrow: {
      color: '#235A86'
    },
    tooltip: {
      backgroundColor: '#235A86',
      color: '#fffff',
      fontFamily: "Montserrat",
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      padding: "16px",
      fontSize: 14,
      maxWidth: 400,
      fontWeight: 500,
      borderRadius: 8
    },
  }))(Tooltip);
  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  
export const DisasterClaims = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const mytheme = useSelector(state => state.theme);
  const propertyRepairReducer = useSelector(state => state.propertyRepairReducer);
  const loanInfo = useSelector(state => state?.loanInfoReducer?.bankDetail);
  const { primaryColorCode } = mytheme;

  const [propertyRepairs, setPropertyRepairs] = useState([]);
  const [errorMessage, setErrorMessage] = useState('')
  const [disasterClaimStatus, setDisasterClaimStatus] = useState('');
  const [responseReceivedStatus, setResponseReceivedStatus] = useState('');
  const [alertStatus, setAlertStatus] = useState(false);
  const [file, setFileName] = useState('');
  const [documentId, setDocumentId] = useState();
  const [index, setIndex] = useState();
  const [deleteStatus, setDeletePopup] = useState(false);
  const [submitClaimStatus, setSubmitClaimStatus] = useState(false);
  const [loanNumber, setLoanNumber] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDisasterClaimStatus()
  }, [props.accountNumber])

  const handleAlertClose = () => {
    setAlertStatus(false)
  }

  const handleGotIt = () => {
    setSubmitClaimStatus(false)
  }

  const getDisasterClaimStatus = async () => {
    const result = await dispatch(propertyRepairDisplay(props.accountNumber));
    if (result) {
      if (result?.responseData?.lstResponseStatus[0].statusCode == 0) {
        setLoading(false)
        if (result?.responseData?.responseData.length > 0) {
          const status = result?.responseData?.responseData.filter((disaster) => disaster.dateContactMade == null)
          setDisasterClaimStatus(status.length > 0 ? false : true)
          const updatedData = result?.responseData?.responseData.map((disaster) => disaster.dateContactMade == null ? {...disaster, status: true} : disaster);
          setPropertyRepairs(updatedData)
          setResponseReceivedStatus(false)
        } else if (result?.responseData?.responseData.length == 0) {
          setResponseReceivedStatus(true)
        }
      } else {
        setLoading(false)
      }
    } else {
      setErrorMessage(renderDefaultMsg('400'));
      setLoading(false)
    }
  }

  const handleRadioChange = (event, index) => {
    let newObj = [...propertyRepairs]  
    newObj[index]['repairStatus'] = event.target.value;
    setPropertyRepairs(newObj)
  };

  const handleChange = (event, index) => {
    let newObj = [...propertyRepairs]  
    newObj[index]['otherComment'] = event.target.value;
    setPropertyRepairs(newObj)
  }

  const calculateCommentLenth = (property) => {
    const length = property?.otherComment?.length
    if (isNaN(length)) {
      return 0
    } else {
      return length
    }
  }
 
  const submitDisasterClaim = async  (property, index) => {
    let newObj = [...propertyRepairs]  
    newObj[index]['loaderStatus'] = true;
    setPropertyRepairs(newObj);

    setErrorMessage('');
    const formData = new FormData();
    formData.append('accountNumber', property.loanNumber);
    formData.append('disasterName', property.disasterName);
    formData.append('disasterNumber', property.disasterNumber);
    formData.append('repairFile', null);
    formData.append('repairStatus', property.repairStatus);
    formData.append('disasterCount', property.disasterCount);
    formData.append('OtherComments', (property?.otherComment && (property.repairStatus == 'Other')) ? property?.otherComment : null);
    formData.append('apiScriptResponse', null);
    formData.append('isSubmit', true);
    formData.append('ClientShortName', loanInfo.clientShortName);
    formData.append('privateLabelUrl', Cookies.get("privateLabelUrl"));
    formData.append('browser', Cookies.get("browser"));
    formData.append('appSource', getAppSource());
    formData.append('userType', getUserType());
    formData.append('ipAddress', Cookies.get('publicIp'));

    const res = await uploadDisasterClaimDocuments(formData);
    if (res && res?.responseData?.lstResponseStatus && (res?.responseData.lstResponseStatus[0]?.statusCode == 0)) { 
      let newObj = [...propertyRepairs]  
      newObj[index]['repairStatus'] = property.repairStatus;
      newObj[index]['dateContactMade'] = res?.responseData?.responseData.submitedDate;
      newObj[index]['submitted'] = true;
      newObj[index]['loaderStatus'] = false;
      setPropertyRepairs(newObj)
      const status = propertyRepairReducer.every((disaster) => disaster.dateContactMade)
      setDisasterClaimStatus(status)
      setLoanNumber(decrypt(property.loanNumber))
      setSubmitClaimStatus(true)
    } else if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode !== 0) {
      let newObj = [...propertyRepairs]  
      newObj[index]['repairStatus'] = property.repairStatus;
      newObj[index]['dateContactMade'] = null;
      newObj[index]['submitted'] = false;
      newObj[index]['loaderStatus'] = false;
      setPropertyRepairs(newObj)
      setErrorMessage(renderDefaultMsg('400'))
      scrollTop()
    } else {
      let newObj = [...propertyRepairs]  
      newObj[index]['repairStatus'] = property.repairStatus;
      newObj[index]['dateContactMade'] = null;
      newObj[index]['submitted'] = false;
      newObj[index]['loaderStatus'] = false;
      setPropertyRepairs(newObj)
      setErrorMessage(renderDefaultMsg('400'));
    }
  };

  const handleFile = (event, property, index) => {
    var fileSize = event.target.files[0].size / 1024 / 1024
    if (fileSize > 5) {
      setFileName(event.target.files[0].name)
      setAlertStatus(true)
    } else {
      onFileUpload(event, property, index)
    }
  }

  const onFileUpload = async (event, property, index) => {
    setErrorMessage('');

    const formData = new FormData();
    formData.append('accountNumber', property.loanNumber);
    formData.append('disasterName', property.disasterName);
    formData.append('disasterNumber', property.disasterNumber);
    formData.append('repairFile', event.target.files[0]);
    formData.append('repairStatus', property.repairStatus);
    formData.append('disasterCount', property.disasterCount);
    formData.append('otherComments', null);
    formData.append('apiScriptResponse', null);
    formData.append('isSubmit', null);
    formData.append('clientShortName', loanInfo.clientShortName);
    formData.append('privateLabelUrl', Cookies.get("privateLabelUrl"));
    formData.append('browser', Cookies.get("browser"));
    formData.append('appSource', getAppSource());
    formData.append('userType', getUserType());
    formData.append('ipAddress', Cookies.get('publicIp'));

    const res = await uploadDisasterClaimDocuments(formData);
    if (res && res?.responseData?.lstResponseStatus && (res?.responseData.lstResponseStatus[0]?.statusCode == 0)) { 
      let newObj = [...propertyRepairs]  
      newObj[index]['docPath'] = res?.responseData?.responseData.docPath;
      newObj[index]['docLocalName'] = res?.responseData?.responseData.docLocalName;
      newObj[index]['id'] = res?.responseData?.responseData.id;
      setPropertyRepairs(newObj)
    } else if (res && res?.responseData?.lstResponseStatus && res?.responseData.lstResponseStatus[0]?.statusCode !== 0) {
      setErrorMessage(renderDefaultMsg('400'))
      scrollTop()
    } else {
      setErrorMessage(renderDefaultMsg('400'));
   }
  };

  const deleteDoc = (id, index, name) => {
    setDocumentId(id)
    setDeletePopup(true)
    setFileName(name)
    setIndex(index)
  }

  const handleClose = () => {
    setDeletePopup(false)
    setIndex()
    setDocumentId()
  }

  const handleOk = () => {
    deleteDocuments()
  }

  const deleteDocuments = async () => {
    setErrorMessage('')
    const response = await deleteDisasterClaimDocument(props.accountNumber, documentId)
    if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode == 0) {  
      setDeletePopup(false)
      let newObj = [...propertyRepairs]  
      newObj[index]['docPath'] = null;
      newObj[index]['docLocalName'] = null;
      setPropertyRepairs(newObj)
    }  else if (response && response?.responseData?.lstResponseStatus && response?.responseData.lstResponseStatus[0]?.statusCode !== 0) {
      setDeletePopup(false)
      setErrorMessage(renderDefaultMsg('400'))
      scrollTop()
    } else {
      setErrorMessage(renderDefaultMsg('400'));
   }
  } 

  const downloadDocuments = async (property) => {
    const response = await downloadDisasterClaimDocument(props.accountNumber, property.id)
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', property.docLocalName);
      document.body.appendChild(link);
      link.click();
    }
  } 

  const handleInputFile = (e) => {
    e.target.value = null;
  }

  const setStatusValue = (index, status) => {
    let newObj = [...propertyRepairs]  
    newObj[index]['status'] = !status
    setPropertyRepairs(newObj)
  }

  if (loading) {
    return (
      <Box className={classes.loadingRoot}>
        <CircularProgress />
      </Box>
    )
  } 
 
  return (
    <CssBaseline>
      <>
       <Box container="true" maxWidth='xl' className={classes.root}>      
        <Box className={classes.container}>
          <CustomBreadcrum backPage="Support" backPageLink="/HelpLanding" activePage="Disaster Claims" />
          <h1 className={classes.pageHeading} color="textPrimary" component='h4'>Property Repair Confirmation</h1>

          {!responseReceivedStatus && <Box className={classes.infoStrip}>
          {(errorMessage !== '') ? (<p className={classes.errorMsg}> {errorMessage}</p>) : null}

          { !disasterClaimStatus && <>
            <p className={classes.procedureToFill}>Confirm your property repair status</p>
          <p className={classes.readInst}>Please confirm the current repair status of your property. If repairs have been completed, upload any necessary documentation or photos as evidence. This will help us process your property inspection efficiently.</p>  
          </> } 
          {disasterClaimStatus && <>
            <p className={classes.thankYou}>Thank You!</p>
            <p className={classes.responseUpdated}>Your response(s) has been successfully updated in our record.</p>  
            </> }  
          <Box  style={{ paddingTop: '26px', paddingBottom: '60px'}}>
            
          <Box className={`${classes.accordian} ${'accordian'}`}>
            {propertyRepairs.map((property, index) => (

            <Accordion elevation={0} expanded={property?.status == true} onChange={() => setStatusValue(index, property?.status)}  className={classes.transactionText}>
              <AccordionSummary
                expandIcon={ <ExpandMoreIcon  className={classes.expand_icon}/>}
                aria-controls={`panelc-content`}
                id={`panelc-header`}
               
                style={{borderLeft: `10px solid ${primaryColorCode}`, borderBottom: '1px solid #B4B4B4'}}
                >
                <Box className={classes.confirmationStatus}>
                  <Grid container spacing={1} columns={{ xs: 12, sm: 12, md: 12 }} >
                    <Grid item xs={12} sm={12} md={9} >
                      <span className={classes.type}>{property.disasterName}</span> 
                      <span className={classes.loan}>({property.childParent?.trim()?.toLowerCase() == 'child' ? 'Property Identification Number' : 'Loan Number'} - <span className={classes.type}>{decrypt(property.loanNumber)}</span>)</span> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} >
                      <Box className={property.dateContactMade ? classes.confirmationReceived : classes.confirmationPending}>{property.dateContactMade ? 'Confirmation Received' : 'Confirmation Pending'}</Box>
                      </Grid>
                  </Grid>
                </Box>

              </AccordionSummary>
              <AccordionDetails>
                
              <Box className={classes.propertyAddressBox}>
                <Grid container spacing={1} justifyContent="space-around" columns={{ xs: 12, sm: 12, md: 12 }}>
                  <Grid item xs={12} sm={12} md={10} >
                    <Typography className={classes.propertyAddress}>Property Address</Typography> 
                    <Typography className={classes.type}>{property.propertyAddress}</Typography> 
                  </Grid>
                <Grid item xs={12} sm={12} md={2} >
                {/* {!property.dateContactMade &&  */}
                  {/* <Box className={classes.confirmationStatusContainer}><span>Disaster Date:</span> {moment(property.disasterDesignationDate).format("L")} </Box> */}
                    <Typography className={classes.propertyAddress}>Disaster Date</Typography> 
                    <Typography className={classes.type}> {moment(property.disasterDesignationDate).format("L")}</Typography>
                  </Grid>
                </Grid>
              </Box>  

              <Grid container spacing={1} justifyContent="space-around" columns={{ xs: 12, sm: 12, md: 12 }} >
                <Grid item xs={12} sm={12} md={12} >
                  { (!property.dateContactMade && !property?.submitted) &&
                    <Box className={classes.accDetails}>
                        <Box className={classes.marginFixed}>
                            <p className={classes.procedureToFill}>Has your property been repaired?</p>
                            <Box className={classes.radioBtn}>
                            <RadioGroup  aria-label="sell_property" name="sell_property" value={property?.repairStatus}
                                onChange={(e) => handleRadioChange(e, index)}>
                                <FormControlLabel value="Yes" 
                                control={<Radio />} label="Yes, repair has been completed" />
                                <FormControlLabel value="No"
                                control={<Radio />} label="No, repairs are in progress" />
                                <FormControlLabel value="Other"
                                control={<Radio />} label="Other" />
                            </RadioGroup>
                        </Box>
                        </Box>
                    </Box> 
                 }

                { ((property?.submitted) && ((property?.repairStatus == 'No') || (property?.repairStatus == 'Other'))) &&
                    <Box className={classes.accDetails}>
                        <Box className={classes.marginFixed}>
                            <p className={classes.procedureToFill}>Has your property been repaired?</p>
                            <Box className={classes.responseSubmit}>{(property?.repairStatus == 'No') ? 'No, repairs are in progress' : 'Other'}</Box>
                           { (property?.repairStatus == 'Other') && <Box className={classes.otherComment}>Please explain the reason for not planning to repair the property</Box>}
                           { (property?.repairStatus == 'Other') && <Box className={classes.responseSubmit}>{property.otherComment}</Box>}
                        </Box>
                    </Box> 
                }
                </Grid>
                {/* {!property.dateContactMade && <Grid item xs={12} sm={12} md={3} >
                 <Box className={classes.confirmationStatusContainer}><span>Disaster Date:</span> {moment(property.disasterDesignationDate).format("L")} </Box>
                </Grid>} */}
                </Grid>

                { property.dateContactMade &&
                    <Box>
                    <p className={classes.supportedDoc}>Repair status response has been received on <span className={classes.date}>{moment(property.dateContactMade).format("L")}</span>.</p>     
                    </Box>
                 }

                {((property?.repairStatus == "Yes") && !property.dateContactMade) &&
                <Box>
                <p className={classes.supportedDocTitle}>Upload Supporting Documents (Optional)</p>      
                <p className={classes.supportedDoc}>Please upload any supporting documents or photos to confirm the completion of repairs.</p> 
                <Box className={classes.fileUpload}>

                    <p className={classes.attachFile}>
                    Attach a file 
                    </p>
                    <Box>
                    { !property.docPath &&
                    <>
                    <input
                    accept=".pdf, image/png, image/jpg"
                    type="file"
                    name='selectedFile'
                    style={{display: 'none'}}
                    id="upload-button"
                    onClick={(e) => handleInputFile(e)}
                    onChange={(e) => handleFile(e, property, index)}
                        />
                    <Button component="label" variant="contained" className={classes.chooseFileButton} >
                        CHOOSE FILE
                        <VisuallyHiddenInput type="file" accept=".png, .jpg, .pdf" onClick={(e) => handleInputFile(e)}
                        onChange={(e) => handleFile(e, property, index)} />
                    </Button>
                    
                    </>
                    } 

                    { property.docPath &&
                        <Box className={classes.uploadedImageContainer}>
                            <Box className={classes.uploadedImage}>
                                <img className={classes.checkImg} src={image} alt='pdf' /> 
                                <Typography className={classes.imageText} onClick={() => downloadDocuments(property)}>{property.docLocalName}</Typography>
                                <LightTooltip
                                arrow  placement="top"
                                    title="Delete"
                                >
                                <DeleteIcon color="action" style={{cursor: 'pointer', color: '#4D4D4D'}} onClick={() => deleteDoc(property?.id, index, property.docLocalName)}></DeleteIcon>
                                </LightTooltip>
                            </Box>
                        </Box>
                        }
                    </Box>

                    { !property.docPath &&
                    <Box className={classes.chooseFile} >
                    (Max allowed file(PDF, JPG, PNG) size: 5 MB)
                    </Box> }
                </Box>
                </Box> }

                {((property?.repairStatus == "Other") && !property.dateContactMade && !property?.submitted) &&
                  <Box>
                    <p className={classes.supportedDoc}>Please explain the reason for not planning to repair the property.</p>   
                    <Box className={classes.textareaSection}>
                        <TextareaAutosize
                            color="primary"
                            variant="outlined"
                            type="text"
                            name="Provide your reason"
                            maxLength="250"
                            onChange={(event) => handleChange(event, index) }
                            value={property?.otherComment}
                            placeholder='Provide your reason'
                            className={classes.textArea}
                        >
                        </TextareaAutosize>                          
                    </Box>  
                    <Typography className={classes.textAreaValidation}>{calculateCommentLenth(property)}/250</Typography>
                  </Box>}

                  { ((!property.dateContactMade) && (!property?.submitted)) && 
                  <Box className={classes.saveBtnBox}>
                       { property?.loaderStatus ?
                          <Button disabled={true} type='submit' className={classes.saveBtn} variant="contained" color='primary'>
                            <CircularProgress color='primary' />
                          </Button>
                          :
                          <Button
                          className={classes.saveBtn}
                          variant="contained"
                          onClick={() => submitDisasterClaim(property, index)}
                          disabled={(((property.repairStatus == 'Other') && !property.otherComment) || isAdminCheck() || ((property?.submitted) && ((property?.repairStatus == 'No') || (property?.repairStatus == 'Other')))) ? true : false}
                          color="primary">
                          SUBMIT</Button> }
                 </Box> }
              </AccordionDetails>
              </Accordion> ))}
            </Box>
             <p className={classes.finding}>If you have any questions, you can also contact our customer support team by <a href="./HelpLanding?contactus" target="_self">clicking here</a>.</p>
            </Box>
         </Box> }

         {responseReceivedStatus && <Box className={classes.infoStrip}>
            <Box className={classes.responseAlreadyReceivedBox}>
                <p className={classes.responseAlreadyReceived}>Response already received.</p>
                <p className={classes.finding}>The page you are looking for might have been removed or is currently unavailable.</p>
                    <Button
                    className={classes.goToHomePage}
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('/Dashboard')}>
                    Go To Homepage</Button> 
                 </Box>
            </Box>}
        </Box>
      </Box>

      <FileSizeAlert
        open={alertStatus}
        handleAlertClose={handleAlertClose}
        file={file}
      />
       <DeleteConfirmation 
        open={deleteStatus}
        handleClose={handleClose}
        handleOk={handleOk}
        file={file}
        />
       <SubmitDisasterClaim
         open={submitClaimStatus}
         handleGotIt={handleGotIt}
         loanNumber={loanNumber}
         />
     </> 
    </CssBaseline>
  )
}
