import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => (
  {
    root: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      backgroundPosition: "bottom left",
      backgroundRepeat: "no-repeat",
      backgroundSize: 'contain',
    },
  
     pageHeading: {
        color: '#1A4664',
        fontFamily: 'Montserrat',
        fontSize: '22px',
        fontWeight: '700',
        letterSpacing: '0.18px',
        lineHeight: '24px',
        marginBottom: 25,
        marginTop: 24
    },
    infoStrip: {
      backgroundColor: '#EEE',
      width: '100%',
      padding: '20px 44px 20px 44px',
      border:'1px solid #B4B4B4',
      borderRadius : '10px'
    },
    
    infoBox: {
      backgroundColor: '#7EB1CC',
      margin: '40px',
      border:'1px solid #B4B4B4',
      borderRadius : '10px',
      padding: '15px'
    },
    
    icon: {
        margin: '20px 10px 20px 20px',
        fontSize: '30px',
        color: '#444444',
        height: '23px',
        width: '23px'
      },
    
    line: {
      border: '1px solid #E4E4E4'
    },

    infoText: {
      color: '#474747',
      fontFamily: "Montserrat",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: '400',
      padding: '5px 20px 20px 16px'
    },
    
    infoHeading: {
      color: '#626262',
      fontFamily: "Montserrat",
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: '700',
      padding: '26px 20px 5px 16px'
    },

    container: {
      width: '120em',
      minWidth: "33.3em",
      marginTop: '3.6em',
      marginBottom: '6.6em',
      [theme.breakpoints.down('sm')]: {
        margin: '42px 16px',
      },
    },
 
    infoIcon: {
      padding: "6px 6px",
      fontSize: '30px',
      color: theme.palette.secondary.contrastText,
    },
    
    procedureToFill: {
      color: '#1B1B1B',
      fontFamily: "Montserrat",
      fontSize: 18,
      lineHeight: "22px",
      fontWeight: '700'
    },

    responseSubmit: {
      color: '#1B1B1B',
      fontFamily: "Montserrat",
      fontSize: 18,
      lineHeight: "22px",
      fontWeight: '500'
    },

    otherComment: {
      color: '#1B1B1B',
      fontFamily: "Montserrat",
      fontSize: 16,
      lineHeight: "22px",
      fontWeight: '600',
      paddingTop: 10
    },

    responseUpdated: {
      color: '#121D22',
      fontFamily: "Montserrat",
      fontSize: 20,
      lineHeight: "22px",
      fontWeight: '600',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontWeight: '500',
        fontSize: 16
      },
    },

    thankYou: {
      color: '#1B1B1B',
      fontFamily: "Montserrat",
      fontSize: 22,
      lineHeight: "22px",
      fontWeight: '700',
      textAlign: 'center'
    },

    readInst: {
        color: '#121D22',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: '400'
    },

    instruction: {
        color: '#121D22',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "32px",
        fontWeight: '400'
    },

    downloadInst: {
        color: '#121D22',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "32px",
        fontWeight: '600'
    },

    linkStyle: {
        fontFamily: "Montserrat",
        fontSize: '1em',
        color: '#007FFF',
        fontWeight: '600',
        letterSpacing: '0.6px',
        textDecoration: 'underline',
        cursor: "pointer"
      },
    
      btn: {
        backgroundColor: '#1A4664',
        fontFamily: "Montserrat",
        fontSize: 14,
        fontWeight: '600',
        height: 32,
        width: 186,
        color: '#FFFFFF',
        width: 186,
        marginBottom: 10,
        marginTop: 10,
        '&:hover': {
            backgroundColor: '#1A4664'
          },
      },

      supportedDoc: {
        color: '#121D22',
        fontFamily: "Montserrat",
        fontSize: 18,
        lineHeight: "24px",
        fontWeight: '600'
      },

      supportedDocContainer: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        padding: '12px 20px 12px 38px',
        border:'1px solid #B4B4B4',
        borderRadius : '10px'
      },

      type: {
        color: '#4D4D4D',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: 18,
        minWidth: 164,
        textTransform: 'uppercase'
      },  

      confirmationStatusContainer: {
        border: '1px solid #235A86',
        backgroundColor: "#EFEFEF",
        borderRadius: 4,
        fontSize: 16,
        width: '240px',
        padding: "6px",
        color: '#121D22',
        fontWeight: "600"
      },

      confirmationReceived: {
        border: '1px solid #198100',
        backgroundColor: "#19810033",
        borderRadius: 4,
        fontSize: 14,
        padding: "6px",
        color: '#198100',
        fontWeight: "700",
        marginLeft: 56
      },

      confirmationPending: {
        border: '1px solid #E02020',
        backgroundColor: "#E0202033",
        borderRadius: 4,
        fontSize: 14,
        padding: "6px",
        color: '#E02020',
        fontWeight: "700",
        marginLeft: 56
      },

      disasterDate: {
        color: '#235A86'
      },

      radioLabel: {
        color: "rgba(0,0,0,0.74)",
        fontFamily: "Montserrat",
        fontSize: " 16px",
        fontWeight: "500",
      },
    
      marginFixed: {
        marginTop: 14
      },

      fileUpload:{
        display:'flex',
        alignItems:'center',
        marginTop:'10px',
        marginBottom:'15px',
        [theme.breakpoints.down('sm')]: {
          flexDirection:'column',
          alignItems:'normal',
          clear:'both',
          width: '90%'
        },
      },

      fileButton:{
        borderRadius: '4px',
        border: '1px solid #EDB36A',
        background: '#FFF',
        height: '32px'
      },

      fileBtnDisable:{
        borderRadius: '4px',
        background: '#B4B4B4',
        margin:'0px 15px',
        height: '32px'
      },

      chooseFile:{
        color: '#121D22',
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        marginRight:'15px',
        [theme.breakpoints.down('sm')]: {
          paddingTop: '5px'
        },
      },

      uploadedImageContainer:{
        paddingLeft:'20px',
        [theme.breakpoints.down('sm')]: {
          paddingRight:'0px',
        },
      },

      uploadedImage:{
        borderBottom: '3px solid #198100',
        color: '#202020',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '16px',
        paddingBottom: '4px',
        display: 'flex',
      },

      chooseFileButton:{
        borderRadius: '4px',
        border: '1px solid #EDB36A',
        background: '#FFF',
        height: '32px',
        margin: "0px 10px"
      },

      imageText: {
        width: '220px',
        color: '#007FFF',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '16px',
        textDecoration: 'underline',
        paddingLeft: 10,
        paddingTop: 2,
        wordWrap: 'break-word',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
          width:'235px'
        },
      },

      attachFile: {
        color: '#4D4D4D',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: '600'
      },

      supportedDoc: {
        color: '#4D4D4D',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: '500'
      },

      supportedDocTitle: {
        color: '#202020',
        fontFamily: "Montserrat",
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: '600'
      },

      textareaSection: {
        marginTop: 16
      },
      
      textAreaValidation: {
        color: '#202020',
        paddingLeft: '55%',
        [theme.breakpoints.down('sm')]: {
          float: 'left',
          paddingLeft: 0
        },
      },
      
      textArea: {
        color: "#202020",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        width: '60%',
        padding: "10px",
        height: '96px !important',
        border: 'none',
        backgroundColor: 'transparent',
        outline: 'none',
        borderRadius: '4px',
        border: '1px solid #9E9E9E',
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        },
        "&::placeholder": {    
          fontSize: '14px', 
          color: 'rgba(0, 0, 0, 0.54)',
          fontSize: '1rem',
          fontFamily: 'Montserrat',
          fontWeight: 400,
          lineHeight: 1
          }
      },

      saveBtnBox: {
        float: "right"
      },
    
      saveBtn: {
        width: 172,
        fontFamily: "Montserrat",
        fontSize: 16,
        marginLeft: 20,
        marginBottom: 20,
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "19px",
        textAlign: "center",
        height: '40px',
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          marginTop: 20
        },
      },

      date: {
        color: "#198000",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 700
      },

      finding: {
        fontSize: '18px',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        marginTop: '40px',
        color: '#000000'
      },

      responseAlreadyReceivedBox: {
        textAlign: 'center',
        marginTop: 120,
        marginBottom: 150
      },

      responseAlreadyReceived: {
        fontSize: '36px',
        textAlign: 'center',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        marginTop: '14px',
        color: '#1A4664',
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            fontSize: 30
        },
      },

      goToHomePage: {
        width: 205,
        fontFamily: "Montserrat",
        fontSize: 16,
        marginTop: 20,
        fontWeight: "600",
        letterSpacing: "0.53px",
        lineHeight: "19px",
        textAlign: "center",
        height: '40px',
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          fontSize: 14
        },
      },

      confirmationStatus: {
        // display: 'flex'
      },

      errorMsg: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        marginTop: 5,
        fontFamily: 'Montserrat',
        color: "red",
      },

      fileValidate:{
        color: '#121D22',
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
        marginRight:'15px',
        '&.info':{
          fontSize: '14px',
        },
        [theme.breakpoints.down('sm')]: {
          paddingTop: '5px'
        },
      },

      transactionText : {
        marginBottom: '8px'
      },

      loadingRoot: {
        width: '100%',
        height: '100vh',
        padding: '6.4em 6%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ccc',
      },

      loan: {
        color: '#4D4D4D',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        fontSize: 18,
        minWidth: 164,
        margin: '0px 20px'
      }, 

      propertyAddressBox: {
        borderBottom: '1px solid #f0f0f0',
        margin: '4px 0px',
        padding: '8px 0px'
      },

      propertyAddress: {
        color: '#4D4D4D',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: 18,
        minWidth: 164,
      }

  }
));

