import React, {useState} from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { validateThirdPartyUrl } from '../../../actions';
import { getAppSource, getUserType, renderDefaultMsg } from '../../../helpers';
import requestApproved from '../../../assets/requestApproved.png';
import requestDecline from '../../../assets/requestDecline.png';
import Cookies from 'js-cookie';

export const ValidateThirdParty = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [declineLoader, setDeclineLoader] = useState(false);

  const validateThirdParty = async (status) => {
    setMessage('');
    setError('');
    if (status) {
      setAcceptLoader(true)
    } else {
      setDeclineLoader(true)
    }

    const requestData = {
      "id": 0,
      "index": 0,
      "thirdPartytName": "",
      "thirdPartyEmail": "",
      "requestType": "",
      "isApproved": status,
      "isDelete": false,
      "expirationDate": "", 
      "relationWithBorrower": "",
      "otherRelationship": "",
      "accountNumber": props.loanNumber,
      "requestType": "payoff",
      "is3rdParty": true,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    }

    const result = await validateThirdPartyUrl(requestData);
    setDeclineLoader(false);
    setAcceptLoader(false)
    setMessage('');
    setError('');
    if (result) {
      const { lstResponseStatus } = result;
      if (result && (lstResponseStatus[0]?.statusCode === "0" || lstResponseStatus[0]?.statusCode === 0)) {
        setMessage(lstResponseStatus[0]?.statusDesc)
      } else {
        setError(lstResponseStatus[0]?.statusDesc)
      }
    } else {
      setError(renderDefaultMsg('400'))
    }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleClose} open={props.open}>
      {(message !== "") ?
      <Box className={classes.root}>
         <Box className={classes.cardBody}>
         {message.includes('approve') && <Typography className={classes.success}>Request Approved</Typography>}
         {message.includes('decline') && <Typography className={classes.error}>Request Declined</Typography>}
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
              {message.includes('approve') && <img src={requestApproved} alt="request approve"/>}
              {message.includes('decline') &&  <img src={requestDecline} alt="request decline"/>}
              <Typography className={classes.paragraph}>{message}</Typography>
             </Box>
            </Box> 
          </Box>
        </Box>
        <Box className={classes.singleFooterItem}>
          <Button
            variant='contained'
            color="primary"
            className={classes.btn}
            onClick={props.handleClose}
          > CLOSE</Button>
        </Box>
      </Box> :

      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>Third-Party Request</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          {error !== '' ? <p className={classes.errorMsg}>{error}</p> : null}
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             <Typography className={classes.paragraph}>{props.title}</Typography>
             </Box>
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={() => validateThirdParty(false)}
            > {!declineLoader ? 'Decline' : <CircularProgress color='primary' style={{ width: 25, height: 25 }} />}</Button>
          </Box>

          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={() => validateThirdParty(true)}
              >{!acceptLoader ? 'Approve' : <CircularProgress color='primary' style={{ width: 25, height: 25 }} />}</Button>
          </Box> 
        </Box>
       
      </Box> }
    </Dialog>
  )
}
