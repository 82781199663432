import { OutlinedInput, InputLabel } from '@material-ui/core';

import {
  withStyles,
  makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  oneTimePaymentContainer: {
    display: 'flex',
    alignItems: 'inherit',
    justifyContent: 'space-between',
  },
  bodySmallFont: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
  },
  radioBtnLabel: {
    color: '#0F355C',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.53px',
    lineHeight: '19px',
  },

  swtichBack: {
    color: '#535D6D;',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: '0.36px',
    lineHeight: '19px',
    textAlign: 'right',
    cursor: 'pointer',
    backgroundColor: 'none',
    textDecoration: 'underline',
    border: 'none',
    '&:hover': {
      border: 'none',
      textDecoration: 'underline',
      backgroundColor: 'none',
    }
  },

  radioBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: '1px solid #d9d9d9',
    padding: '18px 15px',
    color: '#7B8390',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& .MuiTypography-root.MuiFormControlLabel-label': {
      // color: 'rgba(0,0,0,0.87)',
      // fontWeight: '600',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      letterSpacing: '0.53px',
      lineHeight: '19px',
    },
    '& .Mui-checked + .MuiTypography-root.MuiFormControlLabel-label': {
      color: 'rgba(0,0,0,0.87)',
      fontWeight: '600',
    },
    '& .MuiRadio-root.Mui-checked': {
      color: '#535D6D'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '18px 0px'
    },
    '& .MuiFormControlLabel-root': {
      [theme.breakpoints.down('md')]: {
        width: '90%',
      },
    },
    '& .MuiBox-root': {
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
      },
    },
  },
  radioInfo: {
    color: '#535D6D'
  },

  paymentRadioInfo: {
    color: '#535D6D',
    fontSize: '20px'
  },

  addPayRadioInfo: {
    color: '#535D6D',
    fontSize: 24, marginRight: '6px'
  },

  radioDes: {
    paddingLeft: '32px !important',
  },

  alignSelftEnd: {
    marginTop: '10px !important',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-end',
    },
  },

  alignTextBox: {
    marginTop: '10px !important',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end'
    },
  },

  paymentDetailsBox: {
    background: '#eaeced',
    width: '100%',
    borderLeft: '1px solid #c6c6c6;',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      position: 'relative'
    },
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
    },
  },
  posRelative: {
    position: 'relative'
  },
  paymentTypeBox: {
    borderLeft: 'none'
  },
  paymentDetails: {
    padding: '23px 25px',
  },
  paymentHeading: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: '500',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    paddingBottom: '9px !important',
    borderBottom: '1px solid #c6c6c6',
  },
  otpSubHeading: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Montserrat',
    fontSize: 20,
    fontWeight: '400',
    letterSpacing: '0.44px',
    lineHeight: '24px',
    paddingBottom: '9px !important',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  paymentBreaks: {
    padding: '22px 30px 13px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '22px 0 13px 0',
    },
  },
  paymentBreakBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
    },
  },
  block: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  bodyFont: {
    color: 'rgba(0,0,0,0.74)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: '500',
    letterSpacing: '0.5px',
    lineHeight: '20px',
  },
  bodyTitle: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '20px',
    fontFamily: 'Montserrat',
  },

  paymentDetailsFont: {
    color: '#515252',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },

  paymentDetailsTotal: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    lineHeight: '24px',
  },

  paymentTotalBox: {
    borderTop: '2px solid #c6c6c6'
  },
  paymentTotal: {
    padding: "13px 30px 20px 30px",
    [theme.breakpoints.down('sm')]: {
      padding: "13px 0 20px 0",
    },
  },

  termNCon: {
    marginTop: 25,
    paddingBottom: 8,
    fontWeight: '500',
    borderBottom: '1px solid #c6c6c6',
    color: 'rgba(0,0,0,0.87)',
  },

  btns: {
    // display: 'flex',
    // justifyContent: 'space-between',
    paddingTop: 20,
    paddingBottom: 20,
    position: 'absolute',
    bottom: 0,
    width: '90%',
    display: 'grid',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& button': {
      width: '100%',
      fontFamily: 'Montserrat',
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '1.43px',
      lineHeight: '16px',
      [theme.breakpoints.down('sm')]: {
        margin: '5px 0'
      },
    }
  },

  cancelBtn: {
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    '&:hover': {
      border: "2px solid",
      borderColor: theme.palette.primary.main,
    }
  },

  additionalDetails: {
    padding: '32px 0 10px 0'
  },
  addAccLink: {
    padding: '10px 0 0'
  },
  pt0: {
    paddingTop: 0,
  },
  ptPhone0: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    },
  },
  addRemovePay: {
    display: 'flex',
    alignItems: 'center',
    color: '#535D6D;',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0.36px',
    lineHeight: '19px',
    textDecoration: 'underline',
    cursor: 'pointer',
    width: 'fit-content',
  },

  inputStyle: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600"
  },
  disabledAdditionalPrincipal: {
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "600",
    background: '#e5e3e3'
  },
  inputContainer: {
    marginBottom: '25px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  termNConitionBox: {
    boxSizing: "border-box",
    height: "162px",
    border: "1px solid rgba(156,156,156,0.5)",
    backgroundColor: "#FFFFFF",
    marginTop: "1em",
    paddingLeft: "1.6em",
    paddingRight: ".8em",
    paddingTop: "1.3em",
    marginBottom: '10px',
  },

  tncTextContainer: {
    color: "#202020",
    fontFamily: "Montserrat",
    fontSize: "16px",
    letterSpacing: "0.36px",
    lineHeight: "19px",
    height: "115px",
    overflow: "auto",
    paddingRight: "0",
  },

  printButton: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: '8em'
    },

  },

  printBtnIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: "600",
    cursor: 'pointer',
    color: '#535D6D',
    textDecoration: 'underline',
  },

  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    },
  },
  bankAccountError: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '0.36px',
    lineHeight: '19px',
    marginBottom: 6,
    marginTop: 20,
  },
  // successmsg popup
  successMsgBox: {
    textAlign: 'center',
  },
  popupHeading: {
    color: '#0F355C',
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: '29px',
    textAlign: 'center',
  },

  popupSubHeading: {
    color: '#0C0B0B',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '15px',
    textAlign: 'center',
  },
  amountDetailBox: {
    border: '1px solid #979797',
    opacity: '0.12',
    backgroundColor: '#25ABC7',
    padidng: 12,
  },
  popupCode: {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.53px',
    lineHeight: '19px',
    textAlign: 'center',
  },
  poupAmountDetails: {
    display: 'flex',

  },
  disabledBtn: {
    backgroundColor: '#94aecf !important'
  },
  // end
  apiErrorBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  apiError: {
    color: 'red',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.53px',
    lineHeight: '19px',
    textAlign: 'center',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh'
  },
  loader1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '350vh'
  },
  addedAccount: {
    padding: '32px 10px 0 10px',
    width: '70%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '32px 0 0 0',
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: '0.36px',
      lineHeight: '19px',
      backgroundColor: '#fff',
      paddingRight: 4,
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: 600,
      letterSpacing: '0.36px',
      lineHeight: '19px',
    },
  },

  errorMsg: {
    color: 'red',
    // textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px',
    position: 'absolute',
    bottom: '210px',
    right: 0,
    left: 0,
    margin: '0 auto',
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 0,
    },
  },

  errorMsg1: {
    color: 'black',
    // textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px',
    position: 'absolute',
    bottom: '90px',
    right: 0,
    left: 0,
    margin: '0 auto',
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 0,
    },
  },
  errorMsg2: {
    marginTop: 12,
    color: 'black',
    // textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '19px',
    letterSpacing: '0.36px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      bottom: 0,
    },
  },

  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 9,
    borderBottom: '1px solid #535D6D',
    '& .MuiTypography-body1': {
      paddingBottom: '0 !important',
      borderBottom: 'None',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  payingMoreLink: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: '600',
    letterSpacing: '0.36px',
    lineHeight: '19px',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
  },
  pauseAdditionalPayment: {
    color: '#535D6D',
    fontFamily: 'Montserrat',
    fontSize: 14,
    fontWeight: '600',
    letterSpacing: '0.36px',
    paddingRight: 10
  },
  warning: {
    display: 'flex',
    fontSize: '16px',
    '&>div': {
      color: '#535D6D',
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: '0.31px',
      lineHeight: '19px',
    },
    '& p': {
      color: 'red',
      margin: 0,
      fontSize: 14
    },
    '& h5': {
      fontWeight: '500',
    }
  },
  textField: {
    color: "#585757",
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontWeight: '600'
  },

  infoBox: {
    border: "solid 1px",
    borderColor: theme.palette.primary.main,
    borderRadius: 12,
    backgroundColor: "#fff9f3",
    padding: '12px 15px',
    margin: '25px 25px 37px 25px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      margin: '25px 25px 20px 25px',
    },
  },

  infoBoxText: {
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    color: 'rgba(0,0,0,0.74)',
    fontWeight: '400',
    margin: 0,
  },

  infoBoxBtn: {
    fontFamily: "Montserrat",
    fontSize: " 1.6em",
    color: '#25ABC7',
    fontWeight: 'bold',
    textAlign: 'right',
  },

  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  customTextInput: {
    maxWidth: 150
  },

  imageContainer: {
    display: "flex"
  },
  infoIcon: {
    paddingRight: "10px",
    fontSize: '30px',
    color: "#F59720"
  },
  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: "500",
    paddingTop: 5
 },
 spinner: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  flexDirection: 'column'
},
processing: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 30,
  fontWeight: "600",
  paddingTop: 10,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20
  },
},
refresh: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 22,
  fontWeight: "500",
  paddingTop: 10,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14
  },
},
processingFeeInfo: {
  color: '#535D6D',
  fontSize: '20px',
  marginRight: 168
},
additionalPayment: {
  display: 'flex'
},
fieldDisable: {
  color: "#235A86",
  fontFamily: "Montserrat",
  fontSize: 10,
  fontWeight: "700",
  marginBottom: 22,
  textDecoration: 'underline'
},
flexBoxAdditionalPayment: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .MuiTypography-body1': {
    paddingBottom: '0 !important',
    borderBottom: 'None',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
},
pauseAdditionalPaymentContainer: {
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 20,
  '& .MuiTypography-body1': {
    paddingBottom: '0 !important',
    borderBottom: 'None',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
},
noteContainer: {
  border: '1px solid #EDB36A',
  backgroundColor: "#FBF0E1",
  borderRadius: 4,
  padding: "6px",
  marginBottom: 22
},
noteParagraph: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 14,
  fontWeight: "500",
  marginBottom: 8,
  paddingTop: 4
},
snackBarSuccess: {
  '& .MuiSnackbarContent-root': {
    backgroundColor: "#1A4664",
    fontFamily: "Montserrat",
  }
},
disabledAdditionalPrincipal: {
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "600",
  background: '#e5e3e3'
},

radioLabelDisable: {
  '& .MuiTypography-root.MuiFormControlLabel-label': {
    color: 'rgba(0,0,0,0.35) !important',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.53px',
    lineHeight: '19px',
  },
},

radioLabelEnable: {
  '& .MuiTypography-root.MuiFormControlLabel-label': {
    color: 'rgba(0,0,0,0.87)',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.53px',
    lineHeight: '19px',
  },
},

regularPaymentDescEnable: {
  color: 'rgba(0,0,0,0.87)',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  letterSpacing: '0.25px',
  lineHeight: '16px',
  paddingLeft: '32px !important'
},

regularPaymentDescDisable: {
  color: 'rgba(0,0,0,0.35)',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  letterSpacing: '0.25px',
  lineHeight: '16px',
  paddingLeft: '32px !important'
},

disableBodyTitle: {
  color: 'rgba(0,0,0,0.35)',
  fontSize: '16px',
  fontWeight: '600',
  letterSpacing: '0.5px',
  lineHeight: '20px',
  fontFamily: 'Montserrat',
},


}));


export const CutomOutlinedInput = withStyles({
  root: {
    "&$focused $notchedOutline": {
      border: `2px solid #25abc7`
    }
  },
  focused: {},
  notchedOutline: {}
})(OutlinedInput);

export const CustomInputLabel = withStyles({
  root: {
    "&$focused": {
      color: "#25abc7",
    }
  },
  focused: {},
})(InputLabel);

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#25ABC7',
    fontSize: '40px'
  },
  tooltip: {
    color: "#fff",
    backgroundColor: '#25ABC7',
    padding: '20px 15px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.55px',
    lineHeight: '21px',
    width: '500px',
    maxWidth: '500px',
    borderRadius: '10px'
  },
}));