import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 12,
    backgroundColor: 'white',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)"
  },

  dialogStyle: {
    maxWidth: 714,
    borderRadius: '12px !important',
    maxHeight: "100%",
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '20px',
        margin: '50px 15px'
      },
  },

  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "400",
    textAlign: 'center',
    margin: 14,
  },

  cardBody: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '12px 2em',
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  cardContainer: {
    width: '100%',
    padding: '2px 2em',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    }
  },

  statusTitle: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 20,
    fontWeight: "700",
    padding: '3px 2px 2px 3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16
    }
  },

  statusBody: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Montserrat",
    fontSize: '2em',
    fontWeight: "bold",
  },

  paragraph: {
    color: "#121D22",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: "500",
    marginBottom: 8,
    paddingTop: 8,
    lineHeight: '24px'
 },

closeIcon: {
  position: 'absolute',
  right: 2,
  [theme.breakpoints.down('sm')]: {
   right: 10
  },
},

link: {
  color: '#007FFF',
  textDecoration: 'underline',
  cursor: 'pointer'
},

box: {
  padding: '1em',
  margin: '2em',
  textAlign: 'center'
},

transferBox: {
  backgroundColor: '#E5ECF0',
  borderRadius: 2,
  padding: '1em',
  margin: '2em 10em',
  borderRadius: '8px',
  border: '2px solid #D9D9D9',
  [theme.breakpoints.down('sm')]: {
    margin: '2em 4em'
 }
},

servicerText: {
  color: "#121D22",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "700",
  textTransform: 'uppercase'
},

informationText: {
  color: "#121D2",
  fontFamily: "Montserrat",
  fontSize: 15,
  fontWeight: "400",
  paddingLeft: 24,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0
 }
},

newServicer: {
  alignItems: 'center',
  backgroundColor: '#FFF2E1',
  borderRadius: 4,
  padding: 4,
  margin: "2em",
  borderRadius: '8px',
  border: '4px solid #EDB342'
},

information: {
  color: "#202020",
  fontFamily: "Montserrat",
  fontSize: 18,
  fontWeight: "500",
},

singleFooterItem:{
  textAlign: 'center',
  padding: '0px 20px 20px'
},

btn: {
  width: 204,
  height: '40px',
  fontFamily: "Montserrat",
  fontSize: 16,
  fontWeight: "600",
  margin: "10px",
  [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      height: '35px'
  }
},

}));
