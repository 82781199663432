import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import {
  Box, Grid, Typography, Button, CircularProgress, FormControl, InputLabel,
  MenuItem, Select, TextField
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { SnackBar } from '../../components';
import { validateEmail, renderDefaultMsg, scrollTop, getUserType, getAppSource, isAdmin, getBorrowerLabel, getMortgageLabel, getLoanLabel } from '../../helpers'
import { getThirdPartyData, thirdPartyAuthorizationPrivate, approveThirdPartyAccess } from '../../actions'
import ErrorIcon from '@material-ui/icons/Error';
import Cookies from 'js-cookie';
import { encrypt, decrypt } from '../../helpers/encrypt';
import CustomBreadcrum from '../Breadcrum';
import { getPointPrivateLabel } from '../../helpers';
import { RequestDeleteConfirmation } from './RequestDeleteConfirmation';
import infoIcon from '../../assets/RequestDocument/Info.png';

export const ThirdPartyRequestManage = React.memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedAccount = useSelector(state => state.selectedAccount);
  const mytheme = useSelector(state => state.theme);
  const { primaryColorCode, secondaryColorCode } = mytheme;
  const props = { primaryColorCode: primaryColorCode, secondaryColorCode: secondaryColorCode };
  const classes = useStyles(props);

  const [listUnAuthParty, setListUnAuthParty] = useState([]);
  const [listAuthParty, setListAuthParty] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successAddedMsg, setSuccessAddedMsg] = useState('');
  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});

  const [state, setState] = useState({
    requester: '', email: '', request: '', identity: '', expiryDate: moment(new Date()).add(30, 'days'), otherIdentity: ''
  });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [msg, setMsg] = useState("");
  const [hasError, setHasError] = useState(false);
  const [otherItentityBox, setOtherItentityBox] = useState(false);

  const [appExpiryDate, setAppExpiryDate] = useState(moment(new Date()).add(30, 'days'));
  const [subOrdinate, setSubOrdinateStatus] = useState(false);
  const [deleteStatus, setDeletePopup] = useState(false);
  const [requestType, setType] = useState('');
  const [requestId, setId] = useState('');
  const [requestIndex, setIndex] = useState('');

  useEffect(() => {
    if (selectedAccount) {
      getAllThirdPartyData();
      scrollTop();
    }
  }, [selectedAccount]);

  const getAllThirdPartyData = async (e) => {
    setDataLoading(true);
    const data = {
      "accountNumber":  Cookies.get("selectedAccount"),
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    const response = await getThirdPartyData(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setListAuthParty(response.lstAuthorizedThirdPaty);
      setListUnAuthParty(response.lstUnAuthorizedThirdPaty);
      setSubOrdinateStatus(response.isSubordinate);
      setDataLoading(false);
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setDataLoading(false);
    } else {
      setDataLoading(false);
    }
  }


  const handleFormTextChange = (prop) => (event) => {
    if (prop === 'email') {
      if (event.target.value.length <= 40) {
        setTouched({
          ...touched,
          [event.target.name]: true
        });
        setState({
          ...state,
          [event.target.name]: event.target.value
        })
        return;
      }
      return;
    } else if (prop === 'identity') {
      if (event.target.value === '99') {
        setOtherItentityBox(true);
        setState({
          ...state,
          [event.target.name]: event.target.value,
          ['otherIdentity']: ''
        })
      } else {
        setOtherItentityBox(false);
        setState({
          ...state,
          [event.target.name]: event.target.value
        })
      }
      return;
    }
    setTouched({
      ...touched,
      [event.target.name]: true
    });
    setState({
      ...state,
      [event.target.name]: event.target.value
    })
    return;
  }

  const changeExpiryDate = (date) => {
    setState({
      ...state,
      ['expiryDate']: date
    })
  }

  const changeApproveExpiryDate = index => async (date) => {
    const newListThirdParty = [...listUnAuthParty];
    newListThirdParty[index].expirationDate = date ? moment(date).format('L') : null;
    setListUnAuthParty(newListThirdParty);
  }

  const toggleViewMore = async (index, viewMore) => {
    const newListThirdParty = [...listAuthParty];
    newListThirdParty[index].viewMore = viewMore;
    setListAuthParty(newListThirdParty);
  }

  const handleBlur = event => {
    const { name, value } = event.target;
    setErrors({
      ...errors,
      ...(errors && { [name]: touched[name] && (name === 'email' ? validateEmail(value) : '') })
    });
  };

  const checkErrorObject = () => {
    const errArr = Object.values(errors).filter((item, i) => {
      return item !== null;
    })
    return errArr.length;
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    setSuccessAddedMsg('');
    setLoading(true);
    const data = {
      "accountNumber": selectedAccount,
      "thirdPartyName": state.requester,
      "thirdPartyEmailID": state.email !== '' ? encrypt(state.email) : '',
      "requestType": state.request,
      "relationWithBorrower": state.identity,
      "otherRelationship": state.identity === '99' ? state.otherIdentity : ' ',
      "expirationDate": state.expiryDate ? moment(state.expiryDate).format('L') : '',
      "type": "T",
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };
    const response = await thirdPartyAuthorizationPrivate(data);
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setLoading(false);
      setSuccessAddedMsg('Third-Party added successfully.');
      setState({
        requester: '', email: '', request: '', identity: '', expiryDate: moment(new Date()).add(30, 'days'), otherIdentity: ''
      });
      setOtherItentityBox(false);
      getAllThirdPartyData();
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setFormSubmit(false);
      setLoading(false);
      setErrorMsg(response.lstResponseStatus[0].statusDesc);
      setSuccessAddedMsg('');
    } else {
      setFormSubmit(false);
      setLoading(false);
      setErrorMsg(renderDefaultMsg('400'));
      setSuccessAddedMsg('');
    }
  }

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false)
  }

  const handleLoader = (index, loadingType) => {
    const newlistUnAuthParty = [...listUnAuthParty];
    newlistUnAuthParty[index].loading = loadingType;
    setListUnAuthParty(newlistUnAuthParty);
  }
  const handleDeclineLoader = (index, loadingType) => {
    const newlistUnAuthParty = [...listUnAuthParty];
    newlistUnAuthParty[index].declineLoading = loadingType;
    setListUnAuthParty(newlistUnAuthParty);
  }

  const handleDeleteLoader = (index, loadingType) => {
    const newlistAuthParty = [...listAuthParty];
    newlistAuthParty[index].loading = loadingType;
    setListAuthParty(newlistAuthParty);
  }

  const handleClose = () => {
    setDeletePopup(false)
    setType('')
    setId('')
    setIndex('')
  }

  const handleOk = () => {
    setDeletePopup(false)
    requestAuthHandler(requestIndex, requestId, requestType)
  }

  const handleDecline = (index, id, type) => {
    setType(type)
    setId(id)
    setIndex(index)
    setDeletePopup(true)
  }

  const requestAuthHandler = async (index, id, type) => {
    
    let dataList = '';
    if (type === 'approve') {
      handleLoader(index, true);
      dataList = listUnAuthParty;
    } else if (type === 'delete') {
      handleDeleteLoader(index, true);
      dataList = listAuthParty;
    } else {
      handleDeclineLoader(index, true);
      dataList = listUnAuthParty;
    }
    
    setHasError(false);
    setMsg('')
    const filteredList = dataList.length !== 0 && dataList.filter((list, i) => {
      return list.id === id;
    })
    const data = {
      "accountNumber": selectedAccount,
      "thirdPartytName": filteredList[0].thirdPartyName,
      "id": id,
      "index": dataList[index].index,
      "thirdPartyEmail": type === 'delete' ? filteredList[0].thirdPartyEmail : filteredList[0].thirdPartyEmailId,
      "requestType":  filteredList[0].documentType,
      "isApproved": type === 'approve' ? true : false,
      'isDelete': type === 'delete' ? true : false,
      "expirationDate": type === 'delete' ? filteredList[0].validThroughDate
        : ((filteredList[0].expirationDate === null) || (filteredList[0].expirationDate !== '')) ? ((filteredList[0].expirationDate === null) ? null : moment(filteredList[0].expirationDate).format('L')) : moment().add(30, 'days').format('L'),
      "relationWithBorrower": filteredList[0].relationWithBorrower ? filteredList[0].relationWithBorrower : '',
      "otherRelationship": filteredList[0].relationWithBorrower === '99' ? filteredList[0].otherRelationship : ' ',
      "goodThroughDate": filteredList[0]?.goodThroughDate,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName": "",
    };

    const response = await approveThirdPartyAccess(data);
    if (response) {
      type === 'approve' && handleLoader(index, false);
      type === 'delete' && handleDeleteLoader(index, false);
      (type !== 'delete' && type !== 'approve') && handleDeclineLoader(index, false);
    }
    if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode === '0') {
      setType('')
      setId('')
      setIndex('')
      setHasError(false);
      setMsg(response.lstResponseStatus[0].statusDesc);
      setOpenSnackBar(true);
      await getAllThirdPartyData();
    } else if (response && response.lstResponseStatus && response.lstResponseStatus[0].statusCode !== '0') {
      setType('')
      setId('')
      setIndex('')
      setHasError(true);
      setMsg(response.lstResponseStatus[0].statusDesc);
      setOpenSnackBar(true)
    } else {
      type === 'approve' && handleLoader(index, false);
      type === 'delete' && handleDeleteLoader(index, false);
      (type !== 'delete' && type !== 'approve') && handleDeclineLoader(index, false);
      setHasError(true);
      setMsg(renderDefaultMsg('400'));
      setOpenSnackBar(true)
      setType('')
      setId('')
      setIndex('')
    }
  }

  const bindRelationshipName = (code, otherText) => {
    switch (code) {
      case '01':
        return 'Attorney'
        break;
      case '02':
        return 'Counselor'
        break;
      case '03':
        return 'Friend'
        break;
      case '04':
        return 'Realtor / Closer'
        break;
      case '05':
        return 'Spouse'
        break;
      case '06':
        return 'Unknown'
        break;
      case '99':
        return `Other-${otherText}`
        break;
      default:
        return;
        break;
    }
  }

  const disableBtn = state.requester.length === 0 || state.email.length === 0 ||
    state.request.length === 0 || state.identity.length === 0 || (state.identity === '99' && state.otherIdentity.length === 0);

  if (dataLoading === true) {
    return (
      <Box className='loaderRoot'>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box className={classes.pageBg}>
      <Box className={classes.root} >
        <CustomBreadcrum backPage={getPointPrivateLabel() ? "Contract Details" : "Loan Details"} backPageLink="/LoanInfo" activePage="Manage Third-Party Requests" />
        <Box className={classes.topContainer} mx={0}>
          <Typography className={classes.pageHeading} color="textPrimary" component='h4'>Manage Third-Party Requests</Typography>
        </Box>

        {listUnAuthParty && listUnAuthParty.length !== 0 && <Box className={classes.mainContainer}>
          <Box className={classes.boxPadding}>
            <h2 className={classes.sectionTitle} style={{ marginTop: '0' }}>New Requests </h2>
            {
              listUnAuthParty && listUnAuthParty.length && listUnAuthParty.map((list, index) => {
                return (
                  <Box my={1} mx={2} className={classes.lightBox} key={index + '_' + list.thirdPartyEmailId}>
                    <Box className={classes.flexBox}>
                      <Box>
                        <p className={classes.newRequestText}>Third-Party Name</p>
                        <p className={classes.newRequestBoldText}>{list.thirdPartyName}</p>
                      </Box>
                      <Box>
                        <p className={classes.newRequestText}>Third-Party Email Address</p>
                        <p className={classes.newRequestBoldText}>{decrypt(list.thirdPartyEmailId)}</p>
                      </Box>
                      <Box>
                        <p className={classes.newRequestText}>Requesting For</p>
                        <p className={classes.newRequestBoldText}>{list.requestType}</p>
                      </Box>
                    </Box>
                    <Box className={classes.flexBox}>
                      <Box>
                        <p className={classes.newRequestText}>Relationship To Borrower</p>
                        <p className={classes.newRequestBoldText}>{bindRelationshipName(list.relationWithBorrower, list.otherRelationship)}</p>
                      </Box>
                    </Box>

                    <Box className={`${classes.flexBox} ${classes.expiryDateBox}`}>
                      <Box className={classes.dateWidth}>
                        <p className={classes.expireDateText}>Expiration Date
                        <span className={classes.optionalText}>(Optional)</span>
                        </p>
                        <div>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              fullWidth
                              color='primary'
                              autoOk={true}
                              minDate={new Date()}
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              id="date-picker-inline"
                              label="Expiration Date"
                              className={classes.textFieldBold}
                              value={(list.expirationDate || list.expirationDate === null) ? list.expirationDate : appExpiryDate}
                              onChange={changeApproveExpiryDate(index)}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              InputProps={{
                                classes: { root: classes.textField, input: classes.input },
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <p className={classes.helperText}>Leave this blank if you want the requester to have access forever.</p>

                      </Box>
                      <Box >
                        <div className={classes.btnContainer}>
                          <Button className={`${classes.buttonText} ${classes.borderBtn}`} mt={2}
                            disabled={isAdmin() || list.declineLoading ? true : false}
                            type="submit"
                            variant="outlined"
                            onClick={() => handleDecline(index, list.id, 'decline')}>
                            {list.declineLoading ? <CircularProgress color='primary' style={{ width: 25, height: 25 }} /> : 'Decline'}
                          </Button>
                          {!list.isApproved && <Button className={`${classes.buttonText} ${classes.addAccButton}`} mt={2}
                            disabled={(isAdmin() || list.loading || (list.expirationDate === 'Invalid date')
                              || (list.expirationDate && moment(list.expirationDate) < moment(new Date()))) ? true : false}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => requestAuthHandler(index, list.id, 'approve')}>
                            {list.loading ? <CircularProgress color='primary' style={{ width: 25, height: 25 }} /> : 'Approve'}
                          </Button>}
                        </div>
                      </Box>
                    </Box>
                  </Box>
                )
              })}
          </Box>
        </Box>}

        <Box className={classes.infoStrip}>
          <Box display='flex' alignItems="center">
            <InfoIcon className={classes.infoIcon} />
            <Typography className={classes.infoText}>It can take up to 24 hours for your request status to be updated.</Typography>
          </Box>
        </Box>

        <Box className={`${classes.mainContainer} ${classes.newListThirdPartyBg}`}>
          <Box my={1} mx={2} className={classes.formContainer}>
            <form className={classes.formRoot} noValidate autoComplete="off" onSubmit={handleFormSubmit}>
              <h3 className={classes.sectionTitle} style={{ marginTop: '0' }}>Add Third-Party</h3>
             {getPointPrivateLabel() && <h3 className={classes.title} style={{ marginTop: '5', marginBottom: '5' }}>By adding a third-party, you are authorizing BSI Financial to release information to the third-party user.</h3>}
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <TextField
                      id="outlined-requester-input"
                      label="Third-Party Requester/Company"
                      color="primary"
                      type="text"
                      value={state.requester}
                      name="requester"
                      variant="outlined"
                      fullWidth
                      onChange={handleFormTextChange('requester')}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.input }
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <TextField
                      id="outlined-email-input"
                      label="Third-Party Email Address"
                      color="primary"
                      type="text"
                      name="email"
                      value={state.email}
                      variant="outlined"
                      fullWidth
                      onChange={handleFormTextChange('email')}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.input },
                        maxLength: 20,
                      }}
                    />
                    {touched.email && <p className={classes.validationErr}>{errors.email}</p>}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                      <InputLabel id="borrower-type-select-outlined">Select Request</InputLabel>
                      <Select
                        labelId="request-outlined-label"
                        id="request-select-outlined"
                        color="primary"
                        name="request"
                        value={state.request}
                        label="Request"
                        className={classes.inputStyle}
                        onChange={handleFormTextChange('request')}
                        fullWidth
                      >
                         {!getPointPrivateLabel() && <MenuItem value="ACH Form" >ACH Form</MenuItem>}
                        <MenuItem value="Discuss Loan" >Discuss {getLoanLabel()}</MenuItem>
                        <MenuItem value="Full Authorization">Full Authorization</MenuItem>
                        {!getPointPrivateLabel() && <MenuItem value="Housing Councelor" >Housing Counselor</MenuItem>}
                        {!getPointPrivateLabel() && <MenuItem value="Loss Mitigation Package">Loss Mitigation Package</MenuItem>}
                        <MenuItem value="Payoff">{!getPointPrivateLabel() ? 'Payoff Quote' : 'Payoff'}</MenuItem>
                        {!getPointPrivateLabel() && <MenuItem value="Reinstatement Quote">Reinstatement Quote</MenuItem>}
                        <MenuItem value="Verification of Mortgage">Verification of {getMortgageLabel()}</MenuItem>
                        {subOrdinate && !getPointPrivateLabel() && <MenuItem value="subordination request">2nd Lien Subordination Request</MenuItem>}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
               
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }}>
                      <InputLabel id="borrower-type-select-outlined">Relationship To {getBorrowerLabel()}</InputLabel>
                      <Select
                        labelId="request-outlined-label"
                        id="request-select-outlined"
                        color="primary"
                        name="identity"
                        value={state.identity}
                        label={`Relationship To ${getBorrowerLabel()}`}
                        className={classes.inputStyle}
                        onChange={handleFormTextChange('identity')}
                        fullWidth
                      >
                        <MenuItem value="01" >Attorney</MenuItem>
                        <MenuItem value="02">Counselor</MenuItem>
                        <MenuItem value="03">Friend</MenuItem>
                        <MenuItem value="04">Realtor/Closer</MenuItem>
                        <MenuItem value="05">Spouse</MenuItem>
                        <MenuItem value="06">Unknown</MenuItem>
                        <MenuItem value="99">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        color='primary'
                        autoOk={true}
                        disableToolbar
                        variant="inline"
                        minDate={new Date()}
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        id="date-picker-inline"
                        label="Expiration Date"
                        value={state.expiryDate}
                        onChange={changeExpiryDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        InputProps={{
                          classes: { root: classes.textField, input: classes.input },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <p className={classes.helperText}>Leave this blank in case you want the requester to have access forever.</p>
                </Grid>
                {otherItentityBox && <Grid item xs={12} sm={6} md={6}>
                  <div className={classes.otherName}>
                    <TextField
                      id="outlined-requester-input"
                      label="Other Relationship Name"
                      color="primary"
                      type="text"
                      value={state.otherIdentity}
                      name="otherIdentity"
                      variant="outlined"
                      fullWidth
                      onChange={handleFormTextChange('otherIdentity')}
                      InputLabelProps={{
                        classes: { root: classes.textField }
                      }}
                      InputProps={{
                        classes: { root: classes.textField, input: classes.input },
                        maxLength: 100
                      }}
                    />
                  </div>
                </Grid>}
                <Grid item xs={12} sm={otherItentityBox ? 12 : 6} >
                  {errorMsg !== '' && <p className={classes.errorMsg}>{errorMsg}</p>}
                  {successAddedMsg !== '' && <p className={classes.successMsg}>{successAddedMsg}</p>}
                  <div className={classes.btnFlex}>
                    <Button className={`${classes.addAccButton}`} mt={2}
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isAdmin() || loading || disableBtn || checkErrorObject() !== 0 ? true : false} >
                      {!loading ? 'Add Requester' : <CircularProgress color='primary' style={{ width: 25, height: 25 }} />}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>

          </Box>
        </Box>

        {(listAuthParty && listAuthParty.length !== 0) && 
          <Box className={classes.listContainer}>
            <Box className={classes.flexBox} style={{ width: '100%', margin: '20px 0 0' }}>
              <Box className={`${classes.flex} ${classes.blockMobile}`}>
                <h2 className={`${classes.belowText} ${classes.listTitle}`}
                  style={{ marginTop: '0' }}>
                  Authorized Third-Party List</h2>
                <p className={classes.bobyText}>(Maximum 10 requesters can be added into the List)</p>
              </Box>
            <Box>
              <p className={classes.upperText}>Added {listAuthParty.length} out of 10</p>
            </Box>
          </Box>

        {(listAuthParty && listAuthParty.length !== 0) && listAuthParty.map((list, i) => {
          return (
            <Box className={`${classes.mainContainer} ${classes.listMargin}`} key={`${i}_${list.thirdPartyName}`}>
              <Box className={classes.listBoxPadding}>

                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4} md={4}>
                      <p className={classes.upperText}>Third-Party Name</p>
                       <Box >
                        
                        <p className={classes.belowText}>{list.thirdPartyName}</p>
                      
                        {!list.viewMore && <Button className={classes.viewMoreLink}
                            onClick={() => toggleViewMore(i, true)}
                            color="secondary">
                            View More Details
                        </Button>}
                      </Box>
                    
                    </Grid>
                  
                    <Grid item xs={12} sm={6} md={4}>
                      <p className={classes.upperText}>Expiration Date</p>
                      <p className={classes.belowText}>{list.validThroughDate ? list.validThroughDate : 'Not Applicable'}</p>

                      {(moment(new Date()).add(10, 'days').format('MM/DD/YYYY') === moment(list.validThroughDate).format('MM/DD/YYYY')) && <p className={`${classes.flex} ${classes.validationErr}`}>
                        <ErrorIcon style={{ fill: '#E81A1A', fontSize: '16px', marginRight: '3px' }} />
                        soon-to-expire 3rd-party authorization
                      </p>}
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <p className={classes.upperText}>Third-Party Email Address</p>
                        <p className={classes.belowText}>{list.thirdPartyEmail ? decrypt(list.thirdPartyEmail) : ''}</p>
                    </Grid>
                    </Grid>
                
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        
                      {list.viewMore &&
                      <>
                        <p className={classes.upperText}>Relation</p>
                                <p className={classes.belowText}>{(list.partyRelationshipCode == "01") && 'Attorney'}</p>
                                <p className={classes.belowText}>{(list.partyRelationshipCode == "02") && 'Counselor'}</p>
                                <p className={classes.belowText}>{(list.partyRelationshipCode == "03") && 'Friend'}</p>
                                <p className={classes.belowText}>{(list.partyRelationshipCode == "04") && 'Realtor/Closer'}</p>
                                <p className={classes.belowText}>{(list.partyRelationshipCode == "05") && 'Spouse'}</p>
                                <p className={classes.belowText}>{(list.partyRelationshipCode == "06") && 'Unknown'}</p>
                                <p className={classes.belowText}>{(list.partyRelationshipCode == "99") && 'Other'}</p>
                                {list.viewMore && <Button className={classes.viewMoreLink}
                                  onClick={() => toggleViewMore(i, false)}
                                  color="secondary">
                                  Hide Details
                              </Button>}
                        </>}

                      </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    {list.viewMore &&
                     <>
                        <p className={classes.upperText}>Requested For</p>
                        <p className={classes.belowText}>{list.requestedFor}</p>
                      </>}

                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <Button className={`${classes.deleteButton} ${classes.borderBtn}`} style={{marginTop: list.viewMore ? 56 : 2}}
                          disabled={isAdmin() || list.loading ? true : false}
                          type="submit"
                          variant="contained"
                          onClick={() => handleDecline(i, list.id, 'delete')}>
                          {list.loading ? <CircularProgress color='primary' style={{ width: 25, height: 25 }} /> : 'Delete'}
                    </Button>
                   
                  </Grid>
                  
                  </Grid>
                  {list?.isfromA360 &&
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={8}>
                    </Grid>
                   <Grid item xs={12} sm={6} md={4}>
                    <Box display='flex' alignItems="center">
                     <img src={infoIcon} style={{ height: '16px', color: '#4D4D4D' }} />
                     <Typography className={classes.note}>Note: The changes will be reflected within 24 hours after deletion.</Typography>
                    </Box>
                    </Grid>
                  </Grid> }
              </Box>

            </Box>)
        })}
        </Box>}

        {msg.length !== 0 && <SnackBar
          severity={hasError ? 'error' : "success"}
          message={msg} open={openSnackBar}
          handleClose={handleCloseSnackBar}
        />}

       <RequestDeleteConfirmation 
          open={deleteStatus}
          handleClose={handleClose}
          handleOk={handleOk}
          list={listAuthParty}
          index={requestIndex}
          title={requestType == 'decline' ? 'Are you sure you want to decline this third-party request? This action cannot be undone.' : 'Are you sure you want to delete this third-party request?'}
          type={requestType}
        />

      </Box>
    </Box >
  )
})