import React, { useState, useRef } from 'react';
import {
  Box, Button, IconButton,
  InputAdornment, FormControl,
  CssBaseline, CircularProgress, OutlinedInput, TextField, InputLabel, Grid
} from '@material-ui/core';
import { useStyles } from './styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link } from 'react-router-dom';
import { encrypt, decrypt } from '../../helpers/encrypt';
import { checkDigitOnly, getAppSource, getUserType, renderDefaultMsg, getBorrowerLabel, getPointPrivateLabel } from '../../helpers';
import { useHistory } from 'react-router-dom';
import { MultiAccountAlert, TransferAlert, AccountLockedAlert, SignInAccounts, PurgedLoanPopup } from '../../components';
import { validateEmailSsn, resendCode, validateOtp, getClientName } from '../../actions';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';


export const SignInWithEmail = () => {

  const increment = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [values, setValues] = React.useState({
    showPassword: false,
    email: '',
    borrowerSSN: '',
    otp: ''
  });

  const [openMultiUserIdAlert, setOpenMultiUserIdAlert] = useState(false);
  const [isTransfer, setTransferStatus] = useState(false);
  const [transferData, setTransferData] = useState({
    fromPrivateLabel: "", toPrivateLabelPhone: "",
    toPrivateLabelURL: "", toPrivateLabel: ""
  });
  const [openAccountLockedStatus, setAccountLockedStatus] = useState(false);
  const [otpStatus, showOtpField] = useState(false);
  const [leftTime, setLeftTime] = useState(0);
  const [openAccounts, setAccountsStatus] = useState(false);
  const [maxLimitReach, setMaxLimitReach] = useState(false);
  const [otpLimitExceed, setOtpLimitExceed] = useState(false);
  const [invalidCode, setInvalidCodeStatus] = useState(false);
  const [resendMailMsg, setResendMailMsg] = useState(false);
  const [userAccounts, setUserAccounts] = useState([]);
  const [wrongAttempts, setWrongAttempts] = useState(false);
  const [wrongAttemptsMsg, setWrongAttemptsMsg] = useState('');
  const [accountVerifyStatus, setAccountVerifyStatus] = useState(false);
  const [accountVerifyMsg, setAccountVerifyMsg] = useState('');
  const [accessToken, setAccessToken] = useState();
  const [purgedLoanPopup, setPurgedLoanStatus] = useState(false);
  const [purgedLoanMsg, setPurgedLoanMsg] =  useState();

  const closePurgedLoanPopup = () => {
    setPurgedLoanMsg()
    setPurgedLoanStatus(false)
  }

  const handleTimer = () => {
    let timer = 120
    increment.current = setInterval(() => {
      if (timer === 0) {
        setLeftTime("00:00")
        clearInterval(increment.current)
      } else {
        setLeftTime(timer--)
      }
    }, 1000);
    return () => clearInterval(increment.current);
  }

  const showTimer = (rt) => {
    if (rt === 'Completed') {
      return rt
    }
    rt = parseInt(rt)
    let minutes = Math.floor(rt / 60);
    let seconds = rt % 60

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `0${minutes}:${seconds}`;
  }

  const handleReset = () => {
    clearInterval(increment.current);
  }

  const handleCloseAlert = () => {
    setOpenMultiUserIdAlert(false)
  }

  const handleForgotPasswordCloseAlert = () => {
    Cookies.remove('privateToken')
    setValues({
      showPassword: false,
      email: '',
      borrowerSSN: '',
      otp: ''
    })
    setAccountLockedStatus(false)
    setLeftTime(0);
    setAccountsStatus(false)
    setUserAccounts([])
    showOtpField(false);
    setWrongAttempts(false);
  }

  const handleChange = (prop) => (event) => {
    if (prop === 'borrowerSSN') {
        if (!(/[^\w\s]/gi).test(event.target.value) && !(/\s/g).test(event.target.value)) {
          setValues({ ...values, [prop]: event.target.value.slice(0, 4) });
        }
      } else if (prop === 'email') {
        if (validateEmail(event.target.value?.trim())) {
          setErrorMsg('')
          setValues({ ...values, [prop]: event.target.value });
        } else {
          setErrorMsg('Please enter a valid email address.')
        }
      }
      setValues({ ...values, [prop]: event.target.value });
  };

  const handleOtpChange = (prop) => (event) => { 
     if (prop === 'otp') {
      setErrorMsg('');
      setInvalidCodeStatus(false)
      if (checkDigitOnly(event.target.value?.trim())) {
        setValues({ ...values, [prop]: event.target.value });
      }
    }
  }

  const validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(email)) {
      return true
    } else {
      return false
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBack = () => {
    setErrorMsg('')
    showOtpField(false)
    setValues({
      showPassword: false,
      email: '',
      borrowerSSN: '',
      otp: ''
    })
    setOtpLimitExceed(false)
    setMaxLimitReach(false)
  }
 
  const getClientShortName = async (forCeaseandPersist=false) => {
    const data = {
      "accountNumber" : null,
      "userName": userAccounts[0]?.userName,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get('publicIp'),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType()
    }

    const result = await dispatch(getClientName(data));
    if (result) {
      const { lstResponseStatus, bankDetail, featureToggle } = result
      if (result && (lstResponseStatus[0].statusCode === "0" || lstResponseStatus[0].statusCode === 0)) {
        Cookies.set('clientName', bankDetail.clientShortName)
        Cookies.set('isAgency', bankDetail?.agencyFlag == "N" ? true : false)
        Cookies.set('escrow', bankDetail?.displayEscrowMenu)
        Cookies.set('ceaseDesistFlag', bankDetail?.ceaseDesistFlag);
        // Cookies.set('isDocRequestAllowed', featureToggle?.isDocRequestAllowed);
        Cookies.set("payOffRequestSLA", bankDetail?.payOffRequestSLA);
        if (bankDetail?.displayEscrowMenu) {
          window.location.reload(false)
          return true
        } else {
          window.location.reload(false)
        }
      }
    }
  }
 

   // validate otp
   const validateOtpCode = async () => {
    setLoading(true);
    const data = {
      "otp": values.otp,
      "emailId": encrypt(values.email),
      "ssnLast4Digit": values.borrowerSSN,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName":  ""
    }

    const response = await validateOtp(data)
    var redirectUrl = Cookies.get('redirectUrl')
    setTransferStatus(false)
    setWrongAttempts(false)
    setAccountVerifyStatus(false)
    setAccountVerifyMsg('')

    Cookies.set("showPromisePopup", true)
    Cookies.set('isMaturityExtenstionOpened',true);
    Cookies.set('isUpcomingPayDueOpened',true);
    Cookies.set("showDeliqPopup", true)
    Cookies.set("consentPopup", true)

    if (response?.responseData?.lstResponseStatus.length > 0 && response?.responseData?.lstResponseStatus[0].statusCode === "0") {
      setLoading(false);
      const { lstResponseStatus } = response?.responseData;
      if (lstResponseStatus[0].statusCode === "0") {
        const res = response?.responseData?.responseData.token;
        const accounts = response?.responseData?.responseData.accounts;
        if (response?.responseData?.responseData?.isOTPValid && (res !== 'null') && (!res?.accessToken?.includes('locked')) && (!res?.accessToken?.includes('verification'))) {
          setAccessToken(res?.accessToken)
        }
        if (response?.responseData.responseData?.isOTPValid) {
        if (accounts?.length > 1) {
          setUserAccounts(accounts)
          setAccountsStatus(true)
        } else if (accounts?.length == 1 && !accounts[0].isTransfer) {
              
        if (res && res.expiresIn) {
          Cookies.set("firstLastName", decrypt(res?.firstName) +' '+decrypt(res?.lastName))
          Cookies.set("userName", accounts[0]?.userName)
          setUserAccounts(accounts)
          Cookies.set("privateToken", res?.accessToken);
          Cookies.set("isSemiAnnuallyPopUpAppeared", res?.isSemiAnnuallyPopUpAppeared)
          Cookies.set("accessTokenID", res?.accessTokenID);
          if (!res.isVerified) {
            getClientShortName(true);
            Cookies.remove('redirectUrl')
            history.push('/UserProfile/Profile');
          } else if (res && redirectUrl?.includes('Communication')) { 
            getClientShortName(true);
            history.push('/UserProfile/CommunicationPreferences');
          } else if (res && redirectUrl?.includes('DisasterClaims')) { 
            getClientShortName(true);
            history.push('/DisasterClaims');
          } else if (res && redirectUrl?.includes('Identity')) { 
            getClientShortName(true);
            history.push('/IdentityTheft');
          } else if (res && redirectUrl?.includes('EscrowInfo')) { 
            Cookies.set('escrow', true)
            const escrowStatus = getClientShortName(true);
            if (escrowStatus) {
                history.push('/EscrowInfo');
            }
          } else if (res && redirectUrl?.includes('DocumentRequest')) { 
            getClientShortName(true)
            history.push(`/DocumentRequest?requestId=${Cookies.get('requestId')}`);
          } else {
            Cookies.remove('redirectUrl')
            if (res.hasMultiUser) {
              getClientShortName(true);
              setLoading(false);
              setOpenMultiUserIdAlert(true)
            } else {
              setLoading(false);
              getClientShortName(true);
              history.replace({ pathname: "/Dashboard" });
            }
          }
        }
        if (res && !res.expiresIn) {
          Cookies.set("userName", accounts[0]?.userName)
          if (res.accessToken.includes("[ResendLink]")) {
            setResendMailMsg(true)
          } else {
            setErrorMsg('');
            setResendMailMsg(false)
            if (res.accessToken.includes('locked') || res.accessToken.includes('maximum')) {
              setAccountLockedStatus(true)
            } else if (res.accessToken.includes('verification')) {
              setAccountVerifyStatus(true)
              setAccountVerifyMsg(res.accessToken)
            } else if(res.accessToken.includes('account that is no longer available')) {
              setErrorMsg('');
              setPurgedLoanMsg(res.accessToken)
              setPurgedLoanStatus(true)
            }
          }
          setLoading(false);
        }
      
        } else if (accounts?.length == 1 && accounts[0]?.isTransfer) {
          setLoading(false);
          setTransferData(accounts[0])
          setTransferStatus(getPointPrivateLabel() ? false : true)
        }
       } else if (!response?.responseData.responseData?.isOTPValid && response?.responseData.responseData?.wrongOtpCount !== 5) {
        setLoading(false);
        setInvalidCodeStatus(true)
       } else if (!response?.responseData.responseData?.isOTPValid && response?.responseData.responseData?.wrongOtpCount == 5) {
          setLoading(false);
          setInvalidCodeStatus(false)
          setLeftTime('00:00')
          setWrongAttempts(true)
       } else {
          setLoading(false);
          setErrorMsg(lstResponseStatus[0].statusDesc)
       }
     }
    } else {
      setLoading(false);
      setErrorMsg(renderDefaultMsg('400'))
    }
   }

   // resend otp
   const reSendEmailOTP = async () => {
    setValues({
      showPassword: false,
      email: values.email,
      borrowerSSN: values.borrowerSSN,
      otp: ''
    })
    if (maxLimitReach) {
      setLeftTime('00:00')
      setOtpLimitExceed(true)
    } else {
      setOtpLimitExceed(false)
      handleTimer()
      const data = {
        "IsResend": true,
        "emailId": encrypt(values.email),
        "ssnLast4Digit": values.borrowerSSN,
        "privateLabelUrl": Cookies.get("privateLabelUrl"),
        "ipAddress": Cookies.get("publicIp"),
        "browser": Cookies.get("browser"),
        "appSource": getAppSource(),
        "userType": getUserType(),
        "userName":  ""
      }

      const response = await validateEmailSsn(data)
      if ((response?.responseData?.lstResponseStatus.length > 0) && (response?.responseData?.lstResponseStatus[0].statusCode === "0")) {
        const { lstResponseStatus } =  response?.responseData
        const { otpCount } = response?.responseData?.responseData
        const { isSuccess } = response?.responseData?.responseData
       
        if (lstResponseStatus[0].statusCode === "0") {
          setOtpLimitExceed(false)
          setInvalidCodeStatus(false)
          if ((otpCount == 5) && isSuccess) {
            setMaxLimitReach(true)
          }
        } else {
          setErrorMsg(lstResponseStatus[0].statusDesc)
        }
      } else {
        setErrorMsg(renderDefaultMsg('400'))
      }
    }
  }

  const validateSsnEmail = async () => {
    
    setLoading(true);
    const data = {
      "IsResend": false,
      "emailId": encrypt(values.email),
      "ssnLast4Digit": values.borrowerSSN,
      "privateLabelUrl": Cookies.get("privateLabelUrl"),
      "ipAddress": Cookies.get("publicIp"),
      "browser": Cookies.get("browser"),
      "appSource": getAppSource(),
      "userType": getUserType(),
      "userName":  ""
    }

    const response = await validateEmailSsn(data)
    if ((response?.responseData?.lstResponseStatus.length > 0) && (response?.responseData?.lstResponseStatus[0].statusCode === "0")) {
      setLoading(false);
      const { lstResponseStatus } =  response?.responseData
      const { otpCount } = response?.responseData?.responseData
      const { isSuccess } = response?.responseData?.responseData
      const { wrongOtpCount } = response?.responseData?.responseData
      const { message } = response?.responseData?.responseData
      setWrongAttempts(false)
      setWrongAttemptsMsg('')

      if ((lstResponseStatus[0].statusCode === "0") && isSuccess && (otpCount !== 5)) {
        setInvalidCodeStatus(false)
        showOtpField(true)
        setOtpLimitExceed(false)
        setMaxLimitReach(false)
        handleTimer()
      } else {
        if ((otpCount == 5) && isSuccess) {
          setMaxLimitReach(true)
          setErrorMsg('')
        } else if ((otpCount == 0) && !isSuccess) {
          showOtpField(false)
          setOtpLimitExceed(false)
          setWrongAttempts(true)
          setMaxLimitReach(false)
          setErrorMsg(message)
          setValues({
            showPassword: false,
            email: '',
            borrowerSSN: '',
            otp: ''
          })
        } else {
          showOtpField(true)
          setOtpLimitExceed(false)
          setErrorMsg('')
        }
      }
    } else {
      setValues({
        showPassword: false,
        email: '',
        borrowerSSN: '',
        otp: ''
      })
      setLoading(false);
      setErrorMsg(renderDefaultMsg('400'))
    }
  }

  const disableBtn = values.email?.length === 0 || values.ssn?.length === 0;
  const { borrowerSSN, email, showPassword, otp } = values;
 
  return (
    <CssBaseline>
      <Box container="true" maxWidth='xl' className={classes.root}>
        { !otpStatus ?
        <Box className={classes.formContainer}>
          <p className={classes.title}>Sign In</p>
          {errorMsg !== '' && <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>}
      
           <Box>
             <Box className={classes.inputContainer}>
              <FormControl variant="outlined" fullWidth >
              <InputLabel className={classes.labelStyle} htmlFor="outlined-email"> Enter Registered Email</InputLabel>
                <OutlinedInput
                  color="primary"
                  id="outlined-email"
                  type='text'
                  value={email}
                  labelWidth={200}
                  onChange={handleChange('email')}
                  inputProps={{
                    className: classes.inputStyle,
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelStyle
                    }
                  }}
                />
              </FormControl>
            </Box>
            <Box className={classes.inputContainer}>
              <FormControl variant="outlined" fullWidth >
              <InputLabel className={classes.labelStyle} htmlFor="outlined-adornment-ssn"> Primary {getBorrowerLabel()} SSN/EIN Last 4 Digits</InputLabel>
                <OutlinedInput
                  color="primary"
                  id="outlined-adornment-ssn"
                  type={showPassword ? 'text' : 'password'}
                  value={borrowerSSN}
                  onChange={handleChange('borrowerSSN')}
                  labelWidth={330}
                  autoComplete="off"
                  inputProps={{
                    className: classes.inputStyle,
                    maxLength: 4,
                    form: {
                      autoComplete: 'off',
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            {
              maxLimitReach && <p className={classes.errorMsg}>You have exceeded the maximum limit to receive a temporary login code. Please try again after 30 minutes or, alternatively, you may sign in with your username and password</p>
            }
           
            {loading ?
              <Button disabled={true} type='submit' className={classes.signInBtn} size="large" fullWidth variant="contained">
                <CircularProgress color='primary' />
              </Button>
              :
              <Button className={classes.signInBtn} size="large" fullWidth 
              variant="contained" color="primary" onClick={() => validateSsnEmail()} 
              disabled={disableBtn || errorMsg !== '' || (borrowerSSN?.trim()?.length !== 4)}>
               Continue
              </Button>
            }
            </Box>
          <Box className={classes.orContainer}>
            <Box className={classes.orWraper}><p className={classes.orText}>or</p></Box>
          </Box>
          <Button component={Link} to='/Signin' className={classes.usernameBtn} size="large" fullWidth   variant='outlined' color="primary">
            SIGN IN WITH USERNAME
          </Button>
        </Box> :
        <Box className={classes.formContainer}>
          <p className={classes.title}>Sign In</p>
          {errorMsg !== '' && <p className={classes.errorMsg}>{errorMsg !== '' ? errorMsg : ''}</p>}
          { (!otpLimitExceed && !invalidCode && !wrongAttempts && !accountVerifyStatus) &&
            <>
              <p className={classes.successMsg}>Your temporary login code has been sent to <b>{values.email}</b></p>
              <p className={classes.note}>No login code received? Make sure to check Spam/Junk folder</p>
            </>
          }
          { accountVerifyStatus && 
            <p className={classes.errorMsg}>{accountVerifyMsg}</p>
          }
         <Box>
          <Box className={classes.inputContainer}>
            <TextField
              color='primary'
              id="outlined-email"
              label="Temporary Login Code"
              variant="outlined"
              type="text"
              labelWidth={250}
              value={otp}
              onChange={handleOtpChange('otp')}
              fullWidth
              inputProps={{
                className: classes.inputStyle,
                maxLength: 6,
                form: {
                  autoComplete: 'off',
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.labelStyle
                }
              }}
            />
             <Grid item md={12} xs={12} lg={12}>
                    <Grid container>
                        <Grid item xs={5} md={6} className={classes.pr35}>
                          { leftTime === '00:00' && !invalidCode &&
                          <Box textAlign="left"><p className={classes.linkStyle}>Didn’t receive code?</p></Box> }
                          { invalidCode &&
                          <Box textAlign="left"><p className={classes.invalidCode}>Invalid Login Code</p></Box> }
                        </Grid>
                        <Grid item xs={7} md={6} className={classes.pl35}>
                          { leftTime === '00:00' ?
                          <Box textAlign="right" onClick={(otpLimitExceed || wrongAttempts || accountVerifyStatus) ? '' : () => reSendEmailOTP()}><p className={(otpLimitExceed || wrongAttempts || accountVerifyStatus) ? classes.disableResend :  classes.resendCode}>Resend Login Code</p></Box> : 
                          <Box textAlign="right"><p className={classes.linkStyle}>Time Remaining <span className={classes.timer}>{showTimer(leftTime)}</span></p></Box> 
                          }
                        </Grid>
                    </Grid>
             </Grid>
          </Box>
          {
            otpLimitExceed && <p className={classes.errorMsg}>You have exceeded the maximum limit to receive a temporary login code. Please try again after 30 minutes or, alternatively, you may sign in with your username and password</p>
          }
          {
            wrongAttempts && <p className={classes.errorMsg}>
            Too many failed temporary login code attempts. You may try again after 30 minutes or alternatively, you may sign in with your username and password 
            </p>
          }
          {loading ?
            <Button disabled={true} type='submit' className={classes.otpBtn} size="large" fullWidth variant="contained">
              <CircularProgress color='primary' />
            </Button>
            :
            <Button  className={classes.otpBtn} size="large" fullWidth variant="contained" color="primary" disabled={(otp?.trim()?.length !== 6) || otpLimitExceed || wrongAttempts || accountVerifyStatus} onClick={() => validateOtpCode()}>
             Continue
            </Button>
          }

        <Button className={classes.usernameBtn} size="large" fullWidth variant='outlined' color="primary"  onClick={() => handleBack()}>
             Back
        </Button>
        </Box>
      </Box>
      }
        {openMultiUserIdAlert &&
        <MultiAccountAlert
          userName={decrypt(userAccounts[0]?.userName)}
          open={openMultiUserIdAlert}
          handleClose={handleCloseAlert}
        />}
       {isTransfer &&
          <TransferAlert
            fromPrivateLabel={transferData.fromPrivateLabel}
            toPrivateLabel={transferData.toPrivateLabel}
            toPrivateLabelPhone={transferData.toPrivateLabelPhone}
            toPrivateLabelURL={transferData.toPrivateLabelURL}
            show={false}
          />}
        {openAccountLockedStatus &&
          <AccountLockedAlert
            handleClose={handleForgotPasswordCloseAlert}
            accountLocked={errorMsg}
          />
        }
        {
          openAccounts && <SignInAccounts accounts={userAccounts} email={values.email} token={accessToken}/>
        }
         {purgedLoanPopup &&
          <PurgedLoanPopup open={purgedLoanPopup} closePurgedLoanPopup={closePurgedLoanPopup} type={'signin'} purgedLoanMsg={purgedLoanMsg}
          />
        }
      </Box>
    </CssBaseline>
  )
}
