import React, { useState } from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { renderDefaultMsg} from '../../../helpers';
import { switchWarningCode } from '../../../actions';

export const PauseAdditionalPrincipalConfirmation = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState('');

  const handleYesClick = async () => {
   
    const response = await switchWarningCode(props.accountNumber, props.toggleStatus);
    if (response && response?.responseData?.lstResponseStatus && response?.responseData?.lstResponseStatus[0].statusCode == 0) {
      setError('');
      props.handleSubmit();
    } else if (response && response?.responseData?.lstResponseStatus && response?.responseData?.lstResponseStatus[0].statusCode !== '0') {
      setError(response?.responseData?.lstResponseStatus[0].statusDesc);
    } else {
      setError(renderDefaultMsg('400'));
    }
  }

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleCancelIcon} open={props.open}>
     
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{(props.warningCode == '3') ? 'Turn On' : 'Turn Off'} Additional Principal Confirmation</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
              {(error !==  '') &&
                <Typography className={classes.errText}>{errMsg}</Typography>
              }
             <p className={classes.paragraph}>Are you sure you want to {(props.warningCode == '3') ? 'enable' : 'disable'} additional principal payment amounts going forward?</p>
            </Box> 
          </Box>
        </Box>

        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={props.handleSubmitClose}
            > No</Button>
          </Box>

          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={handleYesClick}
              >Yes</Button>
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}
