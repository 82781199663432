import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 12,
    marginBottom: 28,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 1px 14px 7px rgba(0,0,0,0.05), 0 1px 3px 0 rgba(0,0,0,0.2)",
    position: 'relative'
  },

  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  imageContainer: {
    width: '100%',
    color: "rgba(0,0,0,0.74)",
    fontWeight: '400',
    fontFamily: "Montserrat",
    fontSize: '16px',
    wordBreak: "break-word",
    borderRadius: 12,
    '& p': {
      maxWidth: '290px',
      color: "rgba(0,0,0,0.74)",
      fontWeight: '400',
      fontFamily: "Montserrat",
      fontSize: '16px',
      wordBreak: "break-word",
    }
  },

  footer: {
    padding: '0em 45px 0em'
  },

  btn: {
    top: '90%',
    position: 'absolute',
    width: '75%',
    backgroundColor: "#19CF7C",
    color: "#00559F",
    fontFamily: "Montserrat",
    fontSize: '1.6em',
    fontWeight: "700",
    display: "block",
    textAlign: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    borderRadius: "40px",
    padding: "9px",
    '&:hover': {
      backgroundColor: "#19CF7C",
      textDecoration: "none",
    }
  },
}));
