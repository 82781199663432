import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useStyles, StyledTableCell, StyledTableRow } from './styles';
import { decrypt } from '../../helpers/encrypt';
import { saveDisasterPopupResponse } from '../../actions';
import { isAdminCheck } from '../../helpers';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InfoIcon from '@material-ui/icons/Info';
import Cookies from 'js-cookie';
import moment from 'moment';

export const DisasterClaimsPopup = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [remindMeBtnStatus, setRemindMeBtnStatus] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [childLoan, childLoanStatus] = React.useState(false);

  const { handlePropertyRepairPopupSubmit, clientDetails } = props;
  const mediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (props.disasterInfo) {
      const checkEveryChildLoan = props.disasterInfo.every((disaster) => disaster.childParent?.trim()?.toLowerCase() == 'child')
      childLoanStatus(checkEveryChildLoan)
      const femaDisasterDaysStatus = props.disasterInfo.filter((disaster) => !disaster.dateContactMade)
      const status = femaDisasterDaysStatus.every((disaster) => disaster.femaDisasterDays > 90)
      if (status) {
        setRemindMeBtnStatus(false)
      } else {
        setRemindMeBtnStatus(true)
      }
    } 
  }, [props])

  const renderTableBody = () => {
    return props.disasterInfo.map((row) => {
      return (
        <StyledTableRow>
          <StyledTableCell align="center">{decrypt(row.loanNumber)}</StyledTableCell>
          {!mediaQuery && <StyledTableCell align="center">{row.propertyAddress?.split(",")[0]}</StyledTableCell>}
          {!mediaQuery && <StyledTableCell align="center"><Typography>{row.disasterName}</Typography><Typography className={classes.date}>({moment(row.disasterDesignationDate).format("L")})</Typography></StyledTableCell>}
          <StyledTableCell align="center">{!row.dateContactMade ? <Box className={classes.confirmationPending}>Confirmation Pending</Box> : <Box className={classes.confirmationReceived}>Confirmation Received</Box> }</StyledTableCell>
        </StyledTableRow>)
    })
  }

  const handleBtnClick = async (type) => {
    const allAccountsDetail = await Cookies.get("loanAccounts");
    let allAccounts = JSON.parse(allAccountsDetail);
    const index = allAccounts.findIndex((item) => item.accountNumber == decrypt(props.accountNumber));
    const newData = [...allAccounts];
    newData[index].propertyRepairDisplayStatus = false;
    Cookies.set("loanAccounts", newData);
    const femaDisasterDaysStatus = props.disasterInfo.filter((disaster) => !disaster.dateContactMade)
    const status = femaDisasterDaysStatus.every((disaster) => disaster.femaDisasterDays > 90)
    if (status && !isAdminCheck()) {
      savePopupStatus();
    } 
    if (type == 'takeMe') {
      setOpen(false)
      handlePropertyRepairPopupSubmit();
    } else {
      setOpen(false)
    }
  }

  const savePopupStatus = async () => {
    const arr = [];
    props.disasterInfo.map((item) => arr.push({'accountNumber': item.loanNumber, 'disasterName': item.disasterName, 'disasterNumber': item.disasterNumber, 'disasterCount': item.disasterCount, 'clientShortName': clientDetails.clientShortName  }))
    const response = await saveDisasterPopupResponse(arr);
  }
 
  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle }}} open={open}>
     
      <Box className={classes.root}>
        <Box className={classes.cardBody}>
          <InfoIcon className={classes.cardIcon} /> 
          <Typography className={classes.statusTitle}>Important: Property Repair Confirmation</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
          <Box className={classes.statusContainer}>
           <Box className={classes.cardContainer}>
             <Typography className={classes.paragraph}>Our records indicate that your property may have sustained damage. Please confirm if the necessary repairs are completed.</Typography>
            </Box> 

            <Box className={classes.tableContainer}>
              <TableContainer component={Paper} className={classes.disasterTable}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">{childLoan ? 'Property ID' : 'Loan Number' }</StyledTableCell>
                       {!mediaQuery && <StyledTableCell align="center">Property Address</StyledTableCell>}
                       {!mediaQuery && <StyledTableCell align="center">Disaster Name</StyledTableCell>}
                      <StyledTableCell align="center">Repair Status</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                     {renderTableBody()}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box className={classes.cardContainer}>
             <Typography className={classes.responseShare}>Would you be willing to share your response to help us serve you more effectively?</Typography>
            </Box> 

            {remindMeBtnStatus && <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             <Typography className={classes.paragraph}><b>Note:</b> If you choose “Remind me later,” this message box will close, but you can still submit your response at any time by navigating to Support > Disaster Claims.</Typography>
              </Box>
            </Box> }
          </Box>
        </Box>
    
        <Box className={classes.cardFooter}>
         {remindMeBtnStatus && <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={() => handleBtnClick('remindMe')}
            > REMIND ME LATER</Button>
          </Box> }
          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={() => handleBtnClick('takeMe')}
              >YES, Take me there</Button>
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}
