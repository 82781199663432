import React from 'react';
import { useStyles } from './styles';
import { Box, Typography, Dialog, Grid, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const GoodThroughDateValidation = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
  
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.hidePopup} open={true}>
      <IconButton onClick={props.hidePopup} className={classes.closeIcon}>
        <CancelIcon />
      </IconButton>

      <Box className={classes.root}>
        
        <Box className={classes.box}>
          <Typography className={classes.information}>{props.dateErrorMsg}</Typography>
        </Box>

          <Box className={classes.singleFooterItem}>
            <Button
              variant='contained'
              color="primary"
              className={classes.btn}
              onClick={() => props.hidePopup()}
            > CLOSE</Button>
          </Box>
      </Box> 
   </Dialog>
  )
}

