import React, {useState, useEffect} from 'react';
import { useStyles } from './styles';
import { Box, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import infoIcon from '../../../assets/RequestDocument/Info.png';

export const RequestDeleteConfirmation = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [status, setStatus] = useState(false)
 
  useEffect(() => {
    if (props?.list.length > 0) {
    setStatus(props.list[props.index]?.isfromA360)
    }
  })

  return (
    <Dialog fullScreen={fullScreen} PaperProps={{ classes: { root: classes.dialogStyle } }} onClose={props.handleClose} open={props.open}>
      <IconButton onClick={props.handleClose} className={classes.closeIcon}>
        <CancelIcon />
      </IconButton>
      <Box className={classes.root}>
          
        <Box className={classes.cardBody}>
          <Typography className={classes.statusTitle}>{(props.type == 'decline') ? 'Decline' : 'Delete'} Third-Party Request</Typography>
        </Box>
        
        <Box className={classes.cardBody2}>
           <Box className={classes.cardContainer}>
            <Box className={classes.imageContainer}>
             <Typography className={classes.paragraph}>{props.title}</Typography>
            </Box> 
          </Box>
          {status && <Box display='flex' alignItems="center">
                     <img src={infoIcon} style={{ height: '16px', color: '#4D4D4D' }} />
                     <Typography className={classes.note}>Note: The changes will be reflected within 24 hours after deletion.</Typography>
                    </Box>}
        </Box>

        <Box className={classes.cardFooter}>
         <Box className={classes.footerItem}>
            <Button
              variant='outlined'
              className={classes.btn}
              onClick={props.handleClose}
            > Cancel</Button>
          </Box>

          <Box className={classes.footerItem}>
              <Button
                variant='contained'
                color='primary'
                className={classes.btn}
                onClick={props.handleOk}
              >{(props.type == 'decline') ? 'DECLINE' : 'DELETE'}</Button>
          </Box> 
        </Box>
       
      </Box> 
    </Dialog>
  )
}
